.navbar {
  color: #060b26;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
}

.activenav {
  border-bottom: 2px solid #ff344d;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.titles {
  /* color: #fff; */
  margin-left: 10px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #c4b5c0;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  /* background-color: #060b26; */
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

a.buttonwp {
  color: black;
}

.nav-link {
  display: flex !important;
  align-items: center;
}

.lotteryEngine {
  font-size: 17px;
  line-height: 1;
  display: inline;
  white-space: wrap !important;
  font-weight: 500;
}

.hovernav {
  white-space: nowrap !important;
}

.Logo {
  display: flex !important;
}

.accNAdd {
  white-space: nowrap !important;
}

.navbar .dropdown .btn-primary {
  background: transparent;
  width: unset;
  border: 0px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0px;
}

.navbar .dropdown .btn-primary:hover {
  background: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.7);
}

.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown-toggle:focus,
.navbar .btn-primary:focus {
  box-shadow: none !important;
}

.navbar svg {
  fill: none;
  stroke-width: 2px;
  width: 18px;
  height: 100%;
}

.navbar .dropdown-menu-right {
  border-radius: 10px;
  border-top-right-radius: 0px;
  box-shadow: 0 12px 32px rgba(26, 26, 26, 0.2);
  border: 0px;
}

.navbar .lotteryDp .dropdown-menu {
  padding: 0px;
}

.navbar .lotteryDp .dropdown-item.active {
  background-color: #eef3ff;
  color: #2c5de5;
}

.navbar .lotteryDp .dropdown-item:hover {
  background-color: #eef3ff;
  color: #2c5de5;
}

.navbar .lotteryDp .menu-option {
  position: relative;
}

.navbar .lotteryDp .menu-option .sub-menu-list {
  position: absolute;
  top: 0px;
  left: 173px;
}

.navbar .showMore {
  text-align: center;
  color: #ea2e49;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .navbar .lotteryDp {
    width: 100%;
  }

  .navbar .lotteryDp .dropdown-menu {
    margin-top: 6px !important;
    border: none;
    background-color: transparent;
  }

  .navbar .lotteryDp .btn-primary {
    height: 22px;
  }
}

@media screen and (max-width: 1048px) {
  .AccountAdress {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .nav-link {
    display: block !important;
    align-items: center;
  }

  .Logo {
    display: flex !important;
  }
}

@media screen and (max-width: 576px) {
  .nav-link {
    display: block !important;
    margin-bottom: 15px !important;
    align-items: center;
  }

  .navbar {
    margin-top: 0px;
  }

  .Logo {
    display: flex !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 1400px) {
  .lotteryEngine {
    display: block;
  }
}

@media screen and (max-width: 630px) {
  .lotteryEngine {
    display: block;
    font-size: 10px;
    white-space: wrap !important;
  }

  .mybtn1 {
    width: 90px;
    padding: 5px 12px !important;
  }
}

@media screen and (max-width: 454px) {
  .lotteryEngine {
    width: 170px;
    margin-top: -4px;
  }

  .autobetLg {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 400px) {
  .lotteryEngine {
    width: 130px;
  }
}

@media screen and (max-width: 1199px) {
  .accNAdd {
    display: none !important;
  }
}

span {
  margin-left: 16px;
}
