#news{
    min-height: calc(100vh - 105px);
}

#news .news-section{
    padding-top: 20px;
}

#news .news-section .news_list{
    border-radius: 10px;
    background-color: white;
    padding: 16px;
    /* border: 2px solid #b74848; */
    box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
}

#news .news-section .news_list .news_title{
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    word-break: break-word;
}

#news .news-section .news_list .title_width{
    width: calc(100% - 25px)
}

#news .news-section .news_list .news_content{
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#news .news_time {
    font-size: 14px;
    line-height: 18px;
}

#news .news-section .news_list .details-section{
    position: relative;
}

#news .news-section .news_list .share-icon{
    position: absolute;
    right: 0px;
    top: 0px;
    height: 20px;
    z-index: 2;
    cursor: pointer;
}

#news .news-section .news_list .newsImage .newsImg{
    max-height: 350px;
    width: 100%;
    object-fit: contain;
}