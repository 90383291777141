.Missile .no-to-select {
    margin-bottom: 24.35px;
}

.Missile .button-row button {
    width: 47.14px;
    height: 47.14px;
    border-radius: 50%;
    border: 0px;
    background: #F0F0F0;
    margin: 13.65px;
    font-weight: 500;
    font-size: 20.7413px;
    line-height: 24px;
    color: #000000;
}

.Missile .button-row button:disabled{
    cursor: not-allowed;
}

.Missile .button-row {
    display: flex;
    justify-content: center;
}

.Missile .no-to-select .delete,
.Missile .no-to-select .enter {
    width: 72px;
    height: 41px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.Missile .no-to-select .delete {
    background: #C11902;
}

.Missile .no-to-select .enter {
    background: #34C102;
}

.Missile .partners-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.Missile .partnerlogoMissile {
    width: auto;
    height: 60px;
    object-fit: contain;
    max-width: 120px;
}

.Missile .card {
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
    padding: 26px 19px 40px;
    max-width: 530px;
    margin: auto;
    position: relative;
    z-index: 2;
}

.Missile .card-body {
    padding: 0px;
}

.Missile .card-body .amount-section {
    margin-bottom: 14px;
}

.Missile .card-body .card-header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #444444;
}

.Missile .date-section span {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #696969;
}

.Missile .btn.disabled,
.Missile .btn:disabled {
    cursor: not-allowed !important;
}


.Missile .rocket-type-name {
    text-align: left;
}

.Missile .rocket-type-name span {
    font-weight: 500;
    font-size: 20px;
    color: #dc3545;
    text-transform: uppercase;
}

.Missile .missile-icon {
    width: 200px;
    margin-bottom: 20px;
}

.Missile .outer-container {
    padding: 67px 0px;
    position: relative;
    min-height: 100%;
    overflow: hidden;
    margin-top: 8px;
}

.Missile .bgcolor {
    background: linear-gradient(90deg, #8D140E 0%, #56248D 78.29%);
}

.Missile .bgImage1 {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.Missile .logo-section {
    width: 120px;
}

.Missile .no-to-select .inputField {
    width: 196px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: 0px auto 11.36px;
}

.Missile .draw-date {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #433A3A;
    margin-bottom: 15px;
}

.Missile .dateformobile {
    margin-bottom: 15px;
}

.Missile .missile-img {
    height: 100%;
}

.Missile .missile-img .hypersonic {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    top: 85px;
    z-index: 2;
    height: 100%;
    max-height: 750px;
}

.Missile .missile-img .ballastic {
    position: absolute;
    width: calc(100% - 30%);
    right: 0px;
    bottom: 0px;
    z-index: 1;
    height: 100%;
    max-height: 680px;
}

.Missile .missile-img .cruis {
    position: absolute;
    width: calc(100% - 30%);
    right: 0px;
    bottom: 0px;
    z-index: 1;
    height: 100%;
    max-height: 550px;
}

.Missile .missile-img .initiation {
    position: absolute;
    width: calc(100% - 50%);
    right: 0px;
    bottom: 67px;
    z-index: 1;
    height: 100%;
    max-height: 467px;
}

.Missile .missile-row {
    position: relative;
}

.Missile .missile-type span {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #444444;
}

.Missile .missile-type1 span {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.Missile .missile-col {
    position: unset;
}

.Missile .dp-class {
    z-index: 3;
    text-align: right;
}

.Missile .error-textt {
    color: red;
    font-size: 14px;
    margin-top: 4px;
}

.Missile .error-texttt {
    color: #696969;
    font-size: 14px;
    margin-top: 4px;
}

@media screen and (max-width: 1200px) {
    .Missile .outer-container {
        margin-top: 32px;
    }
}

@media screen and (max-width:991px) {
    .Missile .missile-img .hypersonic {
        position: unset;
    }

    .Missile .missile-img .ballastic,
    .Missile .missile-img .initiation,
    .Missile .missile-img .cruis {
        width: 100%;
        position: unset;
    }

    .Missile .missile-type,
    .Missile .missile-type1 {
        max-width: 530px;
        margin: 0px auto;
    }

    .Missile .card {
        border-top: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

    }
}

@media screen and (max-width: 576px) {
    .Missile .outer-container {
        margin-top: 18px;
    }
}

@media screen and (max-width: 440px) {
    .Missile .card-body .card-header-text {
        font-size: 32px;
        line-height: 38px;
    }

    .Missile .button-row button {
        width: 40px;
        height: 40px;
        font-size: 18px;
        margin: 10px;
    }

    .Missile .no-to-select .delete,
    .Missile .no-to-select .enter {
        font-size: 18px;
    }

    .Missile .no-to-select {
        margin-bottom: 15px;
    }
}