.lottery-card .partnerlogo{
    width: auto;
    height: 50px;
    object-fit: contain;
    max-width: 120px;
}

.lottery-card .lottery-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 25px;
    background: #ee4545;
    border-radius: 0.25rem 0px;
}

.lottery-card .lottery-tag span{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
}

@media screen and (max-width: 630px){

.lottery-card .lotterycardbtn{
width: unset;
}
}