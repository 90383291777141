.list-giftcard{
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    background-color: #ffffff;
    border-radius: 10px;
    height: 100%;
    border: 0px;
}

.list-giftcard .card-img-block {
    overflow: hidden;
    text-align: center;
    padding: 15px 10px 0px;
}

.list-giftcard .card-img-block .img-fluid {
    height: 100px;
    object-fit: contain;
    width: 100%;
    max-width: 220px;
}

.list-giftcard .card-body {
    padding: 16px;
    text-align: left;
}

.list-giftcard .shareicon{
    width: 19.5px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
}

.list-giftcard .card-desc{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.list-giftcard .giftcard-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 25px;
    background: #ee4545;
    border-radius: 0.25rem 0px;
    text-align: center;
}

.list-giftcard .giftcard-tag span{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
}

.list-giftcard .transfer-button {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}