.MineLottery .partnerlogo{
height: 35px;
max-width: 150px;
position: absolute;
top: 10px;
right: 10px;
}

.MineLottery .card-inner{
    position: relative;
    background-color: #d1d1d1;
    box-shadow: 0 0 16px 1px#777777;
    border: 0px;
}

.MineLottery .number-list li{
    border-radius: 50%;
    background-color: #a3a3a3;
    color: #383a37;
    border: 0px;
}

.MineLottery .number-list li.active {
    color: #FFFFFF;
    background-color: #d8020b;
    box-shadow: 0 2px 5px 0 #b0010f
}

.MineLottery .text-title{
    color: #383a37;
}

.MineLottery .number-list li.disabled{
    color: #FFFFFF;
    background-color: #f85f53;
    box-shadow: 0 2px 5px 0 #b0010f;
    cursor: not-allowed;
    opacity: 0.65;
}

.MineLottery  .btn-primary:disabled{
    cursor: not-allowed !important;
}

.MineLottery .mine-numbers{
    font-size: 14px;
    margin: 0px;
    color: #383a37;
    line-height: 32px;
}

.MineLottery .number-list li.active .mine-numbers, .MineLottery .number-list li.disabled .mine-numbers{
    color: #FFFFFF;
}

@media screen and (max-width:440px) {
    .MineLottery .partnerlogo{
        position: unset;
        margin-bottom: 8px;
    } 
}