.NewsList .add-btn-section {
    text-align: right;
    margin-bottom: 30px;
}

.NewsList .card-col {
    margin-bottom: 30px;
}

.NewsList .table thead tr {
    background-color: #c9c9c9;
}

.NewsList .table thead th:first-child {
    border-top-left-radius: 5px;
    overflow: hidden;
}

.NewsList .table thead th:last-child {
    border-top-right-radius: 5px;
    overflow: hidden;
}

.NewsList .table tbody tr:nth-child(even) {
    background-color: #edeef0;
}

.NewsList .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
    overflow: hidden;
}

.NewsList .table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.NewsList .table tbody tr .text-ellipse{
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.NewsList .table thead .table-id {
    width: 50px;
}

.NewsList .table thead .action-btn {
    width: 80px;
}

.NewsList .table .action-icon {
    cursor: pointer;
}

.NewsList .table th {
    padding: 12px;
}

.NewsList .table .custom-switch .custom-control-label{
    cursor: pointer;
}

.rtf-wrapper{
    border: 1px solid #ced4da;
    border-radius: 12px;
}

.rtf-wrapper .rdw-editor-toolbar{
    border: none;
    border-bottom: 1px solid #ced4da;
    padding: 0px;
    margin: 6px 5px;
}

.rtf-wrapper .rtf-editor{
    padding: 0px 0.75rem;
}

.brandLogo .previewImage {
    height: 100px;
}

.brandLogo {
    display: flex;
    flex-wrap: wrap;
}

.brandLogo label {
    width: 100%;
}

.popupHeading {
    font-weight: 500;
    font-size: 20px;
    color: rgb(68, 68, 68)
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.custom-control-label::before,
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #ddd !important;
}

.brand-logo h5 {
    font-size: 16px;
}

.brand-logo h4 {
    font-size: 16px;
}

.brand-logo .img-thumbnail {
    margin-top: 5px;
    width: 200px !important;
    height: 200px !important;
}

.brand-logo .logo-label {
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--themebold);
}

.cross-icon {
    cursor: pointer;
}

.dateInput .react-datetime-picker {
    width: 100%;
}

.partner-pop {
    margin: 0px -16px -14px;
}

#news .DraftEditor-editorContainer .rdw-link-decorator-wrapper a:hover{
    color: #007bff;
    text-decoration: none;
}

#news .DraftEditor-editorContainer .rdw-link-decorator-wrapper .rdw-link-decorator-icon {
    display: none;
}

#news .DraftEditor-editorContainer .rdw-link-decorator-wrapper a span{
    color: #007bff;
}

.newsPopup .modal-dialog{
 max-width: 650px;
}

@media screen and (max-width:700px) {
    .NewsList .card-section {
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 667px) {
    .newsPopup .modal-dialog{
        margin: 28px 8px;
       } 
}