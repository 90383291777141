.lotterydropdown
  .Dropdown_dropdown__3J5R4
  .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl:hover,
.Dropdown_dropdown__3J5R4
  .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl.Dropdown_active__u0jjG {
  color: unset;
}

.lotterydropdown
  .Dropdown_dropdown__3J5R4
  .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl, .lotterydropdown .Dropdown_dropdown__3Oivs .Dropdown_button__3oxDE.Dropdown_button-tertiary__2z-R8 {
  color: unset;
  padding: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.lotterydropdown .Item_item__1h4jC, .lotterydropdown .Item_item__1ExBN{
    justify-content: space-between;
}

.lotterydropdown .Submenu_submenu__33Fxt .showMore, .lotterydropdown .Submenu_submenu__2BZif .showMore {
  color: #ea2e49;
  font-weight: 600;
  justify-content: center;
}

.lotterydropdown .Dropdown_dropdown__3J5R4 .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl:focus, .lotterydropdown .Dropdown_dropdown__3Oivs .Dropdown_button__3oxDE.Dropdown_button-tertiary__2z-R8:focus {
    box-shadow: none;
}

.navbar .lotterydropdown svg {
    fill: rgba(0,0,0,0.8);
    height: 16px;
}

.lotterydropdown .Submenu_submenu__33Fxt > ul, .lotterydropdown .Submenu_submenu__2BZif > ul, .lotterydropdown .Dropdown_dropdown__3Oivs .Dropdown_menu__1UmPH ul{
  margin: 5px 0px;
}

.lotterydropdown a{
  color: unset;
}

.lotterydropdown a:hover{
  color: unset;
  text-decoration: none;
}