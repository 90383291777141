.banner-section {
  position: relative;
  /* overflow: hidden; */
  /* border-bottom: 2px solid #ededf5; */
}
.numbercircle {
  border: 1px solid #ff344d;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 10px;
}
.banner-content-area {
  padding-top: 60px;
  padding-bottom: 40px;
}
.banner-content{
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner-content .title {
  font-size: 32px;
}
.banner-content p {
  /* max-width: 430px; */
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.banner-content a {
  margin-top: 25px;
}
.banner-elements-part {
  position: absolute;
  right: 7%;
  bottom: 110px;
  width: 750px;
  height: 500px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;
  left: 45%;
}

.bg {
  position: absolute;
  right: 7%;
  bottom: 110px;
  width: 750px;
  height: 500px;
  /* background-position: center;
  background-size: contain;
  background-repeat: no-repeat; */
  top: 10%;
  left: 45%;
}

.banner-elements-part .element-one,
.banner-elements-part .element-two,
.banner-elements-part .element-three,
.banner-elements-part .element-four,
.banner-elements-part .element-five,
.banner-elements-part .element-six,
.banner-elements-part .element-seven,
.banner-elements-part .element-eight,
.banner-elements-part .element-nine {
  position: absolute;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  opacity: 1;
}
.banner-elements-part .element-one {
  transition-delay: 0.7s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-two {
  transition-delay: 1s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-three {
  transition-delay: 1s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-four {
  transition-delay: 1.3s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-five {
  transition-delay: 0.3s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-six {
  transition-delay: 1.2s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-seven {
  transition-delay: 1.7s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-eight {
  transition-delay: 2s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-nine {
  transition-delay: 1.2s;
  transition-duration: 0.5s;
}

.banner-elements-part .element-one {
  top: 50%;
  left: 49%;
  z-index: 2;
}
.banner-elements-part .element-two {
  top: 52%;
  left: 54%;
}
.banner-elements-part .element-three {
  top: 58%;
  left: 32%;
}
.banner-elements-part .element-four {
  top: 63%;
  left: 25%;
  z-index: 2;
}
.banner-elements-part .element-five {
  top: 40%;
  left: 35%;
  z-index: 1;
}
.banner-elements-part .element-six {
  top: 55%;
  left: 80%;
  z-index: 2;
}
.banner-elements-part .element-seven {
  top: 78%;
  left: 43%;
  z-index: 2;
}
.banner-elements-part .element-eight {
  top: 35%;
  left: 4%;
}
.banner-elements-part .element-nine {
  top: 10%;
  left: 31%;
  z-index: 2;
}

.banner-elements-part.active .element-one {
  top: 62%;
  left: 49%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-two {
  top: 68%;
  left: 54%;
  opacity: 1;
}
.banner-elements-part.active .element-three {
  top: 46%;
  left: 32%;
  opacity: 1;
}
.banner-elements-part.active .element-four {
  top: 63%;
  left: 37%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-five {
  top: 31%;
  left: 35%;
  z-index: 1;
  opacity: 1;
}
.banner-elements-part.active .element-six {
  top: 55%;
  left: 68%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-seven {
  top: 66%;
  left: 43%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-eight {
  top: 35%;
  left: 14%;
  opacity: 1;
}
.banner-elements-part.active .element-nine {
  top: 26%;
  left: 31%;
  z-index: 2;
  opacity: 1;
}

.bannerbg {
  background-image: url("../Images/bann.png");
}

.marq {
  background: linear-gradient(179.93deg, #A74AC7 -13.4%, rgba(167, 74, 199, 0.51) 113.4%);
  opacity: 0.6;
}

.marq .feature-msg {
  color: white;
  opacity: 1;
}

.marq .feature-msg a{
  color: white;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .banner-elements-part {
    right: 0;
  }
}
@media (max-width: 1550px) {
  .banner-elements-part {
    right: 7%;
  }
}
@media (max-width: 1400px) {
  .banner-elements-part {
    right: -6%;
  }
}

@media (max-width: 1199px) {
  .banner-content-area {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .banner-elements-part div[class*="element"] img {
    max-width: 70%;
  }
  .banner-elements-part.active .element-five {
    top: 35%;
    left: 45%;
  }
  .banner-elements-part.active .element-six {
    top: 52%;
    left: 69%;
  }
  .banner-elements-part.active .element-nine {
    top: 33%;
    left: 43%;
  }
  .banner-elements-part.active .element-three {
    top: 46%;
    left: 42%;
  }
  .banner-elements-part.active .element-one {
    top: 56%;
    left: 56%;
  }
  .banner-elements-part.active .element-four {
    top: 58%;
    left: 49%;
  }
  .banner-elements-part.active .element-seven {
    top: 61%;
    left: 53%;
  }
  .banner-elements-part.active .element-two {
    top: 62%;
    left: 59%;
  }
  .banner-elements-part.active .element-eight {
    top: 43%;
    left: 31%;
  }
}
@media (max-width: 600px) {
  .banner-content-area {
    padding-bottom: 40px;
  }
}

.cubeimage {
  z-index: -1;
  /* right: 120px; */
  height: 427px;
  width: 80%;
  top: 75px;
  /* position: absolute; */
}
@media screen and (max-width: 1200px ) and (min-width: 768px) {
  .banner-content{
    max-width: 440px;
  }
}
@media (max-width: 768px) {
  .cubeimage {
    z-index: -1;
    /* right: 120px; */
    height: 427px;
    width: 80%;
    top: 62px;
    /* position: absolute; */
    opacity: 0.3;
  }
  .banner-content{
    min-height: unset;
  }
  .marq .rfm-overlay::before, .rfm-overlay::after{
    width: 40px;
  }
}
