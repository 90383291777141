.profileImg {
  position: relative;

  transition: transform 0.2s;
  width: 100px !important;
  border: 1px solid;
  border-color: rgb(124, 121, 121);
}
.profileImg:hover {
  transform: scale(1.1);
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.dats span {
  margin-left: 0;
  font-size: 13px;
}
.data-input span {
  font-size: 13px;
}
.customSpace {
  margin-left: 10px;
}
.disabledbtn {
  background: #878787;
  color: #fff;
}
.nav-text a:hover {
  background-color: #c9c9c9 !important;
  text-decoration: none !important;
}

.modal-footer {
  justify-content: center !important;
}

.Homepage,
.Login,
.Register .Mytickets .Winner .Help {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.App {
  color: black;
  text-align: center;
}

.wheelarea.rollspin .sc-gsTCUz.bhdLno .sc-dlfnbm.started-spinning {
  animation: 4s linear infinite forwards running continueSpin;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@media (max-width: 1199px) {
  .navbar-collapse {
    flex-basis: 100%;
    background: #f8f9fa !important;
    position: relative !important;
    z-index: 10000 !important;
    flex-grow: 1;
    align-items: center;
    padding: 20px !important;
  }
}
@media (min-width: 991px) {
  .navbar {
    padding: 0.5rem 3rem !important;
  }
  .hovernav:hover {
    /* transform: scale(1.1); */
  }
}
.App-link {
  color: #61dafb;
}
.nav-link {
  display: block;
  margin: 0px 5px !important;
  padding: 0.4rem 0.2rem !important;
}
.hovernav:hover {
  border-bottom: 2px solid #ff344d;
}
p.underlinelink {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media (max-width: 991px ) and (min-width: 768px) {
  .navbar-nav{
    align-items: center;
  }
} */
