.gift-card .lottery-card{
    text-align: left;
    padding:  0px;
    cursor: pointer;
}

.gift-card .lottery-card .card-body{
    padding:  20px;
}

.gift-card .lottery-card .cardlogo{
    height: 100%;
    width: 100%;
    max-height: 175px;
    object-fit: contain;
    max-width: unset;
    padding-top: 20px;
}

/* .gift-card .lottery-card .autobetlogo{
    height: 100%;
    width: 100%;
    max-height: 175px;
    object-fit: contain;
    max-width: unset;
    padding-top: 20px;
} */

.gift-card .giftcard-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 25px;
    background: #ee4545;
    border-radius: 0.25rem 0px;
    text-align: center;
}

.gift-card .giftcard-tag span{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
}

.gift-card .gas-text{
    font-size: 16px;
}

.gift-card .buy-btn{
    margin-top: 12px;
    width: max-content;
    min-width: 100px;
}

.gift-card .amount-text{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px ;
}

.gift-card .price {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px ;
}

.gift-card .expiry {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 6px;
}

.gift-card .desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    min-height: 44px;
}

.gift-card .address {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}