.PartnersList .add-btn-section {
    text-align: right;
    margin-bottom: 30px;
}

.PartnersList .card-col {
    margin-bottom: 30px;
}

.PartnersList .list-card {
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    background-color: #ffffff;
    border-radius: 10px;
    height: 100%;
    border: 0px;
}

.PartnersList .list-card .card-img-block {
    overflow: hidden;
    text-align: center;
    padding: 15px 10px 0px;
}

.PartnersList .card-img-block .img-fluid {
    height: 100px;
    object-fit: contain;
    width: 100%;
    max-width: 220px;
}

.PartnersList .card-body {
    padding: 16px;
    text-align: left;
}

.PartnersList .card-body .action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.PartnersList .card-body .action .cardIcon1 {
    width: 19.5px;
    height: 20px;
    cursor: pointer;
}

.PartnersList .card-body .action .cardIcon2 {
    height: 20px;
    cursor: pointer;
}

.brandLogo .previewImage {
    height: 100px;
}

.brandLogo input[type='file'] {
    color: transparent;
    width: 140px;
    margin-bottom: 15px;
}

.brandLogo {
    display: flex;
    flex-wrap: wrap;
}

.brandLogo label {
    width: 100%;
}

.popupHeading {
    font-weight: 500;
    font-size: 20px;
    color: rgb(68, 68, 68)
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.custom-control-label::before,
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #ddd !important;
}

.brand-logo h5 {
    font-size: 16px;
}

.brand-logo h4 {
    font-size: 16px;
}

.brand-logo .img-thumbnail {
    margin-top: 5px;
    width: 200px !important;
    height: 200px !important;
}

.brand-logo .logo-label {
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--themebold);
}

.cross-icon{
    cursor: pointer;
}

.dateInput .react-datetime-picker{
    width: 100%;
}

.partner-pop{
    margin: 0px -16px -14px;
}

@media screen and (max-width:700px)  {
    .PartnersList .card-section{
        margin-left: 0px !important;
    }  
}
