#aboutscreen .cardWrapper{
    padding: 35px 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgb(216, 216, 216);
    margin: 20px 0px;
    min-height: 280px;
    transition: .5s;
    /* margin-bottom: 40px; */
}
#aboutscreen .cardwrappercolumn{
    margin-top: 20px;
}
#aboutscreen .cardWrapper:hover{
    transform: scale(101%);
    box-shadow: 0px 4px 10px rgb(216, 216, 216);
}
