#notifications{
    min-height: calc(100vh - 105px);
}

#notifications .notification-section{
    padding: 20px;
}

#notifications .notification-section .notification_heading .heading {
    font-size: 20px;
    font-weight: 600;
}

#notifications .notification-section .notification_heading .see_all{
    cursor: pointer;
    color: rgb(79, 128, 225);;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

#notifications .notification-section .notification_list{
    cursor: pointer;
    border-radius: 10px;
    background: #edeef0;
    padding:  10px 20px;
    display: flex;
    align-items: center;
}

#notifications .notification-section .notification_list .icon-section{
    margin-right: 12px;
}

#notifications .notification-section .notification_list .icon-section .notify_icon{
    height: 20px;
    width: 20px;
}

#notifications .icon-section svg{
    stroke-width: 0px;
}

#notifications .notification-section .notification_list .details-section{
   width: calc(100% - 32px);
}

#notifications .notification-section .notification_list .notification_title{
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

#notifications .notification-section .notification_list .notification_content{
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#notifications .notification-section .notification_list .notification_time {
    font-size: 12px;
    line-height: 18px;
}

#notifications .react-datepicker-wrapper input{
    height: 42px;
    border-radius: 10px;
    padding: 10px;
    width: 120px;
    margin-right: 20px;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.5);  
}

#notifications .react-datepicker__close-icon {
    right: 20px
}

#notifications .date-range-input{
    width: 205px;
    height: 45px;
    border-radius: 10px;
    margin-right: 20px;
}

#notifications .date-range-input::placeholder {
    text-align: center;
}

#notifications .filter-section{
    display: flex;
    justify-content: end;
}

.daterangepicker{
    margin-right: 30px;
}

.daterangepicker .applyBtn{
    width: unset;
    height: unset;
    border-radius: 10px;
}

@media screen and (max-width: 563px) {
    .daterangepicker{
        margin-right: unset;
    }
}

@media  screen and (max-width: 450px) {
    #notifications .filter-section{
        flex-wrap: wrap;
        justify-content: center;
    }

    #notifications .date-range-input{
        width: 100%;
        margin-bottom: 12px;
    }
}