 
  
  .bookiescontainer {
     
    height: 140px;
   
    top: 60px;
    left: calc(50% - 300px);
    display: flex;
  }
  .showonhover{
      display: none;
  }
  .bookiescard {
    display: flex;
    height: 170px;
    width: 250px;
    background-color: #eeebf5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid #b74848;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }
  
  .bookiescard:not(:first-child) {
      margin-left: -50px;
  }
  
  .bookiescard:hover {
    transform: translateY(-20px);
    transition: 0.4s ease-out;
    
  }
  .bookiescard:hover .showonhover{
   display: block;
   top: 50px;
   position: absolute;
   left: calc(50% - 60px);
    
  }
  
  .bookiescard:hover ~ .bookiescard {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
  }
  
  .bookiestitle {
    color: #0f0f0f;
    font-weight: 500;
    position: absolute;
    left: 20px;
    top: 15px;
  }
  
  .bar {
    position: absolute;
    top: 135px;
    left: 20px;
    height: 5px;
    width: 150px;
  }
  
  .emptybar {
    background-color: #2e3033;
    width: 100%;
    height: 100%;
  }
  
  .filledbar {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 0px;
    height: 100%;
    background: rgb(0,154,217);
    background: linear-gradient(90deg, rgba(0,154,217,1) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
    transition: 0.6s ease-out;
  }
  
  .bookiescard:hover .filledbar {
    width: 120px;
    transition: 0.4s ease-out;
  }
  
  .circle {
    position: absolute;
    top: 150px;
    left: calc(50% - 60px);
  }
  
  .stroke {
    stroke: #f67171;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
    transition: 0.6s ease-out;
  }
  
  svg {
    fill: #000000;
    stroke-width: 2px;
  }
  
  .bookiescard:hover .stroke {
    stroke-dashoffset: 100;
    transition: 0.6s ease-out;
  }