.fundRaiseText {
      font-weight: 500;
      font-size: 17px;
}

.num {
      flex-shrink: 0;
      margin-right: 10px;
      width: 38px;
      /* width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: aqua;
      display: flex;
      justify-content: center;
      align-items: center;
      color : #ffffff;
      background: linear-gradient(184.42deg, #F81000 -8.19%, rgba(255, 0, 0, 0.51) 108.19%); */
}

.num img {
      width: 30px;
      height: 30px;
}

.fundTextWrap {
      display: flex;
      align-items: center;
      margin-top: 10px;
}

.fundHead {
      width: 75%;
      font-weight: 600;
      color: #444;
      font-size: 2rem;
      line-height: 1.5;
}

.hrline {
      width: 30px;
      height: 0.5px;
      background-color: #4f80e1;
      margin-right: 20px;
      flex-shrink: 0;
}

.fundBox {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 20px 10px;
      height: 190px;
      margin-bottom: 40px;
}

.fundRaise .title {
      font-size: 32px;
      font-weight: 600;
      text-align: left;
}

.fundRaise .autobetInvite-img{
      width: 100%;
      max-height: 450px;
}

.fundRaise .fundDesc{
      font-size: 18px;
      line-height: 28px;
}

@media screen and (max-width: 991px) {
      .fundRaise .autobetInvite-img{
            max-height: 400px;
            margin-top: 30px;
      }
}

@media only screen and (max-width : 768px) {
      .fundHead {
            width: 100%;
            font-size: 1.5rem;
      }

      .fundRaise .autobetInvite-img{
            max-height: 350px;
      }

      .fundRaise{
            padding-bottom: 10px;
      }
}