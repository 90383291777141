.notification {
    width: 440px;
    padding: 12px 20px;
}

.notification .notification_heading {
    display: flex;
    justify-content: space-between;
}

.notification .notification_heading .heading {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
}

.notification .notification_heading .see_all {
    cursor: pointer;
    color: rgb(79, 128, 225);
    ;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

.notification .notification_list{
    border-radius: 10px;
    background: #edeef0;
    padding:  10px 20px;
    display: flex;
    align-items: center;
}

.notification .notification_list .icon-section{
    margin-right: 12px;
}

.notification .notification_list .icon-section .notify_icon{
    height: 20px;
    width: 20px;
}

.notification .icon-section svg{
    stroke-width: 0px;
    fill: unset;
}

.notification .notification_list .details-section{
    width: calc(100% - 32px);
}

.notification .notification_list .notification_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.notification .notification_list .notification_content {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification .notification_list .notification_time {
    font-size: 12px;
    line-height: 18px;
}