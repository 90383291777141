.partner-carousel .fundBox{
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 0px;
    margin-top: 10px;
    height: 100%;
}

.partner-carousel .fundBox .cardlogo {
    height: 90px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    padding-bottom: 10px;
}

.partner-carousel .react-multi-carousel-item{
    padding: 0px 16px;
}

.partner-carousel .fundBox .partner-details a{
    word-break: break-word;
}

/* .partner-carousel .react-multi-carousel-list{
    justify-content: center;
} */

