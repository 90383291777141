.wheelarea .details {
  position: absolute;
  text-align: center;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
}
.wheelarea h3 {
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 600;
}
.wheelarea h4 {
  font-size: 16px;
  margin-bottom: 0px;
  white-space: nowrap;
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
}
p.bottomtextSpinner {
  position: absolute;
  bottom: 10%;
  font-weight: bold;
  font-size: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.wheelarea {
  position: relative;
  text-align: center;
}

.wheelarea .sc-gsTCUz.bhdLno {
  z-index: -1;
}
.wheelarea.rollspin .mybtn1 {
  display: none;
}

.wheelarea.rollspin {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  opacity: 0.99;
  top: 0;
  left: 0;
  z-index: 98;
}
.wheelarea.rollspin .sc-gsTCUz.bhdLno {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  width: 900px;
  height: 900px;
}

.wheelarea.rollspin .tbx,
.wheelarea.rollspin .mybtn1.topspace {
  display: none;
}

.wheelarea.rollspin .details {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wheelarea .sc-gsTCUz.bhdLno {
  margin: 0 auto;
}
.wheelarea {
  width: 100%;
  height: 100%;
}
.wheelarea .bhdLno {
  width: 300px;
  height: 300px;
}
.wheelarea.txtbox.form-control {
  text-align: center;
}
.wheelarea .topspace {
  margin-top: 10px;
}
.wheelarea .form-control {
  width: 150px;
  text-align: center;
  margin: 0 auto;
}

.wheelarea .partnerlogo {
  width: auto;
  height: 50px;
  object-fit: contain;
  max-width: 120px;
  margin-bottom: 8px;
}

.wheelarea .amount-text {
  width: 100%;
  margin-bottom: 5px;
  color: #333745cc;
  font-size: 18px;
}

.wheelarea .lotteryId {
  color: #002a33;
  font-size: 20px;
  margin-bottom: 8px;
}

.wheelarea .lottery-tag{
  width: 70px;
  height: 25px;
  background: #ee4545;
  border-radius: 0.25rem;
  margin: 10px auto 0px;
}

.wheelarea .lottery-tag span{
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
}

.RevolverPopup{
  background: rgba(255,255,255);
}

@media only screen and (max-width: 1200px) {
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 700px;
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .wheelarea .details {
    /* position: absolute;
    top: 150px; */
    left: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* .wheelarea .details {
    position: absolute;
    top: 150px;
    left: 345px;
  } */
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  /* .wheelarea .details {
    position: absolute;
    top: 150px;
    left: 255px;
  } */
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 500px;
    height: 500px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 576px) {
  /* .wheelarea .details {
    position: absolute;
    top: 150px;
    left: 50%;
  } */
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 340px;
    height: 340px;
  }
}
@media only screen and (max-width: 359px) {
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 240px;
    height: 240px;
  }
}
