.footer-bottom {
  position: absolute;
  width: 100%;
  border-top: 1px solid #edeef0;
  padding-top: 10px;
}
.copy-right-text p a {
  color: #ea2e49;
}
.copy-right-text p {
  color: #454545;
}
.footer-social-links li + li {
  margin-left: 15px;
}
.footer-social-links li a {
  width: 15px;
  height: 25px;
  /* background-color: rgba(51, 181, 247, 0.1); */
  /* border: 1px solid rgba(51, 181, 247, 0.1); */
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: block;
  color: #000000;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.footer-social-links li a:hover {
  /* color: #c2c4c8; */
  color: #ea2e49;
  background-color: transparent;
  /* border-color: rgb(79, 128, 225); */
}
.footer-top-second {
  margin-top: 50px;
}

.footer-widget .widget-title {
  margin-bottom: 20px;
  text-transform: capitalize;
}
.footer-list-menu li + li {
  margin-top: 5px;
}
.footer-list-menu li a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  position: relative;
  padding-left: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.footer-list-menu li a::before {
  position: absolute;
  content: "\f105";
  font-family: "Roboto", sans-serif;
  top: 0;
  left: 0;
  color: #333745;
  opacity: 0.8;
}
.footer-list-menu li a:hover {
  color: #ea2e49;
  margin-left: 10px;
}
.footer-list-menu li a:hover::before {
  color: #ea2e49;
}
.subscribe-form {
  display: flex;
  margin-top: 30px;
}
.subscribe-form input {
  padding: 10px 25px;
}
.subscribe-form input[type="email"] {
  width: 70%;
  border: 1px solid #e5e5e5;
  border-radius: 999px 0 0 999px;
  -webkit-border-radius: 999px 0 0 999px;
  -moz-border-radius: 999px 0 0 999px;
  -ms-border-radius: 999px 0 0 999px;
  -o-border-radius: 999px 0 0 999px;
}
.subscribe-form input[type="submit"] {
  width: 30%;
  border: 1px solid #ea2e49;
  background-color: #ea2e49;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0 999px 999px 0;
  -webkit-border-radius: 0 999px 999px 0;
  -moz-border-radius: 0 999px 999px 0;
  -ms-border-radius: 0 999px 999px 0;
  -o-border-radius: 0 999px 999px 0;
}

dl,
ol,
ul {
margin-bottom: 0px;
}

.footer-bottom .contact-section svg{
  stroke-width: 0px;
  margin-right: 8px;
  margin-top: 2px;
  height: 17px;
  min-width: 17px;
}

.footer-bottom .contact-section .details{
  display: flex;
  margin-bottom: 8px;
}

.footer-bottom .contact-section .details span {
  font-size: 16px;
  line-height: 20px;
  color: #454545;
  word-break: break-all;
}

.footer-bottom .contact-section .details .address{
  word-break: break-word;
}

.footer-bottom .redirect-section p{
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

.footer-bottom .copyrights{
background: #F1F8FF;
text-align: center;
padding: 8px 0px;
}

.footer-bottom .copyright-section p{
  font-size: 14px;
}

.footer-bottom .footer-section{
  padding: 8px 10px;
}

.footer-bottom .footer-icon .tagLine{
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.footer-bottom .foot-head{
  font-weight: 700;
}

.footer-bottom .footer-icon{
  display: flex;
  align-items: center;
}

.footer-bottom .footer-icon .imagee{
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 5px;
}

.footer-bottom .footer-icon .autobetLg{
  color: rgba(0,0,0,0.5);
  font-size: 20px;
  font-weight: bold;
}

.footer-bottom .social-section{
  text-align: center;
}

.admin-footer, .creator-footer{
  margin-left: 230px;
  width: calc(100% - 230px);
}

@media screen and (max-width: 700px) {
  .admin-footer, .creator-footer{
    margin-left: 0px;
    width: 100%;
  }
}

@media  screen and (max-width: 991px) {
  .footer-bottom .footer-section .footer-div .footer-col{
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 768px) {
  .footer-bottom .social-section{
    text-align: left;
  }
  .footer-div .footer-social-links{
    justify-content: left !important;
  }
}


@media only screen and (max-width: 576px){
  .footerTextMedia{
    font-size: 13px;
  }

  .footer-bottom .footer-section{
    padding: 8px 0px;
  }
  /* .footer-social-links li{
    margin-left: 10px !important;
  } */
  /* .footer-div{
    flex-direction: column-reverse;
    text-align: center;
  } */
}
