#helpScreen .videotitle{
    /* text-align: center; */
    font-size: 20px ;
    /* margin-bottom: 20px; */
}
#helpScreen .videoimg{
    height: 200px;
    width: 400px;
    background-color: rgb(221, 221, 221);
}
#helpScreen .dropWrap{
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 8px;
}
#helpScreen .dropDownSvg{
    transform: rotateX(180deg);
    transition: 0.5s;
}
#helpScreen .rotateXTo0{
    transform: rotateX(0deg);
}
#helpScreen .detailStyle{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
#helpScreen .pointer{
    cursor: pointer;
}
#helpScreen .summaryStyle{
    /* margin-left: 5px; */
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 18px;
    white-space: pre-line;
}
#helpScreen .summaryWrap{
    max-height:0;
    overflow: hidden;
    transition:max-height 0.5s ease;
}
#helpScreen .showSummary{
    height:auto;
    max-height:100%;
}
@media only screen and (max-width : 432px){
    #helpScreen .videoimg{
        height: 170px;
        width: 300px;
    }
    #helpScreen .title{
        font-size: 30px;
        margin-bottom: 20px !important;
    }
}
@media only screen and (max-width : 992px){
    #helpScreen .videoimg{
        height: 180px;
        width: 350px;
    }
}