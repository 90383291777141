
.PopupStyle{
    padding-right: 0px !important;
}
.PopupStyle .modal-body {
        padding: 45px 27.5px 30px 27.5px;
    }

    .PopupStyle  .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
    }

    .PopupStyle .heading-text {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 25px;
    }

    .PopupStyle  .btn-div {
        display: flex;
        justify-content: center;
    }
    .PopupStyle  .message-text{
        margin-bottom: 16px;
        text-align: center;
    }

    .PopupStyle .btn-div .btn1{
        margin-right: 10px;
    }

    .PopupStyle .submit-btn {
        width: 117.86px;
        height: 37.31px;
    }

    .PopupStyle .btn-div .success-btn{
        width: max-content;
        min-width: 117.86px
    }

    .PopupStyle .link-textt{
       margin-bottom: 16px;
        text-align: center;

    }
