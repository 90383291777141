@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Ubuntu:300,400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&family=Signika:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', sans-serif;
}

._wallet-chat-widget__container_1jmwq_15{
  bottom: 1.5rem !important;
}
/*========	Table of Css Content ==========*/

/* Typography */

/* Normalize  */
html {
  font-family: "Roboto", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  color: #a1aed4;
  overflow-x: hidden;
}

body.modal-open {
  padding-right: 0px !important;
}

h1 {
  font-size: 60px;
  line-height: 1.0833333333333333;
}

h2 {
  font-size: 35px;
  line-height: 1.4444444444444444;
}

h3 {
  text-align: center;
  font-size: 24px;
  line-height: 1.0833333333333333;
}

h4 {
  font-size: 22px;
  line-height: 1.2380952380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: "center";
  color: black;
  font-family: "Roboto", sans-serif;
  cursor: text;
}

p {
  font-size: 18px;
  color: #a1aed4;
  line-height: 1.625;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.htimg {
  width: 100px;

  height: 100px;
  padding-bottom: 20px;
}

a {
  /* color: #fff; */
  text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

/*input and button type focus outline disable*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
input[type="file"]:focus,
select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

/**
 * 5.0 - Alignments
 */
.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.Toastify .Toastify__toast-body {
  width: 94%;
}

.Toastify .Toastify__toast-body div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Toastify .Toastify__toast-container svg {
  fill: currentColor;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes upDownAnimation {
  0% {
    margin-top: 0px;
  }

  50% {
    margin-top: 80px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes phoneAni {
  0% {
    margin: 0px;
  }

  33% {
    margin-left: 5px;
  }

  66% {
    margin-left: -5px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes manAni {
  0% {
    margin: 0px;
  }

  33% {
    margin-top: 5px;
  }

  66% {
    margin-top: -5px;
  }

  100% {
    margin: 0px;
  }
}

@keyframes shieldAni {
  0% {
    margin: 0px;
  }

  33% {
    margin-top: 5px;
  }

  66% {
    margin-top: -5px;
  }

  100% {
    margin: 0px;
  }
}

@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }

  33% {
    transform: translate(5px, -5px);
  }

  66% {
    transform: translate(-5px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }

  33% {
    transform: translate(-5px, -5px);
  }

  66% {
    transform: translate(5px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

/* Global style */
.mybtn1 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 7px 17px !important;
  display: inline-block;
  color: #fff !important;
  border: 0px;
  border-radius: 12px;
  cursor: pointer !important;
  /* -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; */
  background: linear-gradient(65deg, #dc3545, #0f8afd);

  /* background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#ff344d),
    to(#d2273c)
  );
  background-image: -webkit-linear-gradient(right, #ff344d, #d2273c);
  background-image: -o-linear-gradient(right, #ff344d, #d2273c);
  background-image: linear-gradient(to left, #ff344d, #d2273c); */
}

.mybtn1:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff344d),
    to(#d2273c)
  );
  background-image: -webkit-linear-gradient(left, #ff344d, #d2273c);
  background-image: -o-linear-gradient(left, #ff344d, #d2273c);
  background-image: linear-gradient(to right, #ff344d, #d2273c); */
  background: #0f8afd;
  color: #fff;
  /* -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px); */
}

.mybtn2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  width: 190px;
  display: inline-block;
  color: #fff;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  background: linear-gradient(to left, #1c294e, #1c294e);
}

.mybtn2:hover {
  background: linear-gradient(to right, #ff344d, #d2273c);
  color: #fff;
  transform: translateY(-2px);
}

/* Section Heading Css */
.section-heading {
  text-align: center;
}

.section-heading .subtitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
  background: linear-gradient(to left, #ff344d, #d2273c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-heading .subtitle.extra-padding {
  margin-bottom: 17px;
}

.section-heading .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
}

.section-heading .title.extra-padding {
  margin-bottom: 20px;
}

.section-heading .text {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

/* .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
} */

.navbar-toggler {
  border: none !important;
  /* margin: 0px;
  padding: 0px; */
}

.navbar-toggler:focus {
  outline: 0px;
}

/* Preloader Css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a3a3a3b9;
  z-index: 9999999;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preloader.hide {
  opacity: 0;
  display: none;
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #1a0508;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #1d0306;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@keyframes loader-1-outter {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* bottomtotop Css */
.bottomtotop i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transform: rotate(-90deg);
  bottom: 15px;
  right: 15px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  background: #f9314b;
}

/* Breadcrumb Area Start */
.breadcrumb-area {
  padding: 310px 0px 186px;
  background: #070b28;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.breadcrumb-area.about {
  padding: 260px 0px 135px;
}

.breadcrumb-area.about .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 40%;
}

.breadcrumb-area.bc-faq {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-faq .title {
  margin-bottom: 23px;
}

.breadcrumb-area.bc-faq .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 42%;
}

.breadcrumb-area.play {
  padding: 260px 0px 277px;
}

.breadcrumb-area.play .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 55%;
}

.breadcrumb-area.bc-lottery {
  padding: 260px 0px 193px;
}

.breadcrumb-area.bc-lottery .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 52%;
}

.breadcrumb-area.cart {
  padding: 260px 0px 135px;
}

.breadcrumb-area.cart .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 42%;
}

.breadcrumb-area.bc-tournaments {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-tournaments .bc-img {
  position: absolute;
  right: 150px;
  bottom: 0px;
  max-width: 25%;
}

.breadcrumb-area.bonus {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bonus .bc-img {
  position: absolute;
  right: 150px;
  top: 160px;
}

.breadcrumb-area.bonus {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bonus .bc-img {
  position: absolute;
  right: 150px;
  top: 160px;
  max-width: 36%;
}

.breadcrumb-area.bc-awards {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-awards .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 50%;
}

.breadcrumb-area.h-i-w {
  padding: 260px 0px 135px;
}

.breadcrumb-area.h-i-w .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 48%;
}

.breadcrumb-area.bc-contact {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-contact .bc-img {
  position: absolute;
  right: 0px;
  bottom: -35px;
  max-width: 27%;
}

.breadcrumb-area.bc-affiliate {
  padding: 260px 0px 212px;
}

.breadcrumb-area.bc-affiliate .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 55%;
}

.breadcrumb-area.bc-terms {
  padding: 260px 0px 190px;
}

.breadcrumb-area.bc-terms .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 46%;
}

.breadcrumb-area .title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  margin-bottom: 11px;
  text-transform: uppercase;
  color: #fff;
}

.breadcrumb-area .title.extra-padding {
  margin-bottom: 26px;
}

.breadcrumb-area .breadcrumb-list li {
  display: inline-block;
}

.breadcrumb-area .breadcrumb-list li span {
  margin: 0px 7px;
  color: #fff;
}

.breadcrumb-area .breadcrumb-list li a {
  font-size: 16px;
  transition: all 0.3s ease-in;
  color: #fff;
}

.breadcrumb-area .breadcrumb-list li a:hover {
  color: #f1f1f1;
}

.breadcrumb-area .breadcrumb-list li a.active {
  color: #f1f1f1;
}

/* Popup Video CSS */
.video-play-btn {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
  border-radius: 50%;
  position: relative;
  color: #fff;
  background: #fff;
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2),
    0px 0px 0px 30px rgba(255, 255, 255, 0.2),
    0px 0px 0px 45px rgba(255, 255, 255, 0.2);
  animation: shadowAni 3s linear infinite;
}

.video-play-btn i {
  color: #fff;
}

@keyframes shadowAni {
  0% {
    box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2),
      0px 0px 0px 30px rgba(255, 255, 255, 0.2),
      0px 0px 0px 45px rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.2),
      0px 0px 0px 40px rgba(255, 255, 255, 0.2),
      0px 0px 0px 60px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2),
      0px 0px 0px 30px rgba(255, 255, 255, 0.2),
      0px 0px 0px 45px rgba(255, 255, 255, 0.2);
  }
}

/* Footer Area Start */
.footer {
  padding-top: 120px;
  overflow: hidden;
  position: relative;
  background-color: #070b28;
  /* background-image: url(../images/footerbg.jpg); */
  background-repeat: no-repeat;
  background-position: center 140px;
  background-size: cover;
}

.footer .footer-logo {
  margin-top: -10px;
  margin-bottom: 25px;
}

.footer .footer-widget .title {
  text-transform: uppercase;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  text-transform: uppercase;
}

.footer .footer-widget ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.footer .footer-widget ul li {
  list-style: none;
}

.footer .footer-widget ul li a {
  transition: all 0.3s ease-in;
  line-height: 40px;
  font-weight: 400;
  color: #a1aed4;
}

.footer .footer-widget ul li a:hover {
  color: #f9314b;
  padding-left: 10px;
}

.footer .footer-widget ul li a i {
  font-size: 15px;
  padding-right: 4px;
}

.footer .copy-bg {
  margin-top: 0px;
  padding: 10px 15px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: #0b122e;
}

.footer .copy-bg .left-area p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
}

.footer .copy-bg .left-area a {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  color: #045cf0;
}

.footer .copy-bg .copright-area-links {
  text-align: right;
}

.footer .copy-bg .copright-area-links li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}

.footer .copy-bg .copright-area-links li::after {
  position: absolute;
  content: "|";
  right: -22px;
  top: 0px;
}

.footer .copy-bg .copright-area-links li:last-child {
  margin-right: 0px;
}

.footer .copy-bg .copright-area-links li:last-child::after {
  display: none;
}

.footer .copy-bg .copright-area-links li a {
  font-size: 16px;
  font-weight: 400;
  color: #a1aed4;
  transition: all 0.3s ease-in;
}

.footer .copy-bg .copright-area-links li a:hover {
  color: #f9314b;
}

.footer .subscribe-box {
  margin-bottom: 110px;
  z-index: 99;
  /* background: url(../images/newsletter.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 53px 60px 55px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.footer .subscribe-box .heading-area {
  text-align: center;
}

.footer .subscribe-box .heading-area .sub-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 10px;
  background: linear-gradient(to left, #ff344d, #d2273c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer .subscribe-box .heading-area .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.footer .subscribe-box .icon {
  width: 100%;
  text-align: right;
}

.footer .subscribe-box .icon img {
  display: inline-block;
}

.footer .subscribe-box .form-area {
  width: 100%;
}

.footer .subscribe-box .form-area input {
  width: 100%;
  height: 50px;
  background: none;
  border: 0px;
  border-bottom: 1px solid #b4b8d1;
  border-radius: 0px;
  color: #a1aed4;
}

.footer .subscribe-box .form-area input::-webkit-input-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .form-area input:-moz-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .form-area input::-moz-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .form-area input:-ms-input-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .button-area {
  width: 100%;
}

.footer .subscribe-box .button-area .mybtn1 {
  display: block;
  width: 100%;
  height: 50px;
  position: relative;
  background-image: linear-gradient(to right, #ff344d, #d2273c);
}

.footer .subscribe-box .button-area .mybtn1 span {
  position: absolute;
  top: 0;
  right: 0px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #db5263;
  border-radius: 50%;
}

.footer .subscribe-box .button-area .mybtn1:hover {
  background-image: linear-gradient(to left, #ff344d, #d2273c);
}

/* Hero Area Start */
.hero-area {
  position: relative;
  padding: 317px 0px 354px;
  position: relative;
  background: #070b28;
  overflow: hidden;
}

.hero-area .left-content {
  position: inherit;
  z-index: 9;
}

.hero-area .left-content .content .title {
  font-size: 70px;
  font-weight: 700;
  line-height: 70px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 13px;
  letter-spacing: -1px;
}

.hero-area .left-content .content .subtitle {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  background: linear-gradient(to left, #ff344d, #d2273c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-area .left-content .content .text {
  font-size: 28px;
  line-height: 38px;
  font-weight: 300;
  margin-bottom: 40px;
}

.hero-area .hero-img {
  position: relative;
  top: -50px;
}

.hero-area .hero-img .full-image {
  display: none;
}

.hero-area .hero-img .shape {
  position: absolute;
}

.hero-area .hero-img .shape.phone {
  top: -6px;
  left: 124px;
}

.hero-area .hero-img .shape.man {
  top: 157px;
  left: 213px;
  animation: manAni 3s linear infinite;
}

.hero-area .hero-img .shape.ripple {
  top: 132px;
  right: 70px;
  width: 85px;
  animation: rippleAni 5s linear infinite;
}

.hero-area .hero-img .shape.ripple2 {
  top: 142px;
  left: 10px;
  width: 100px;
  animation: ripple2Ani 5s linear infinite;
}

.hero-area .hero-img .shape.bitcoin1 {
  top: 220px;
  left: 25px;
  width: 100px;
  animation: ripple2Ani 4s linear infinite;
}

.hero-area .hero-img .shape.bitcoin2 {
  top: 46px;
  right: 55px;
  width: 92px;
  animation: rippleAni 4s linear infinite;
}

.hero-area .hero-img .shape.shape-icon {
  top: 112px;
  right: -3px;
  width: 55px;
}

.hero-area .hero-img .shape.award-bg {
  top: 178px;
  right: 26px;
}

.hero-area .hero-img .shape.award {
  top: 178px;
  right: 53px;
  width: 27px;
  animation: shieldAni 3s linear infinite;
}

.hero-area .hero-img .shape.gift-bg {
  top: 304px;
  left: 125px;
  width: 76px;
}

.hero-area .hero-img .shape.gift {
  top: 299px;
  left: 150px;
  width: 30px;
  animation: shieldAni 4s linear infinite;
}

.hero-area .hero-img .shape.shield-bg {
  top: 345px;
  left: 197px;
  width: 70px;
}

.hero-area .hero-img .shape.shield {
  top: 338px;
  left: 222px;
  width: 25px;
  animation: shieldAni 4.5s linear infinite;
}

/* Hero Area End */
/* Features Area Start */

.mt-24 {
  margin-top: 24px;
}

.features2-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: -32px;
  margin-left: -32px;
}

.features2-wrap:first-of-type {
  margin-top: -16px;
}

.features2-wrap:last-of-type {
  margin-bottom: -16px;
}

.feature2 {
  padding: 16px 32px;
  width: 380px;
  max-width: 380px;
  flex-grow: 1;
}

.feature2-inner {
  height: 100%;
}

.feature2-icon {
  display: flex;
  justify-content: center;
}

@media (min-width: 641px) {
  .features2-wrap:first-of-type {
    margin-top: -24px;
  }

  .features2-wrap:last-of-type {
    margin-bottom: -24px;
  }

  .feature2 {
    padding: 32px 32px;
  }
}

@media (min-width: 481px) {
  .container,
  .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.features {
  margin-top: -250px;
  padding-bottom: 112px;
  background: #0b122e;
  padding-top: 100px;
}

.features .single-feature {
  text-align: center;
  padding: 55px 30px 35px;
  transition: all 0.3s ease-in;
  background: #252f5a;
  border-radius: 10px;
}

.features .single-feature .icon {
  width: 125px;
  height: 125px;
  line-height: 120px;
  margin-bottom: 37px;
  position: relative;
}

.features .single-feature .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid #1c2748;
  border-radius: 50%;
}

.features .single-feature .icon::before {
  position: absolute;
  content: "";
  top: 3%;
  left: 3%;
  width: 94%;
  height: 94%;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 1;
}

.features .single-feature .icon.one {
  border: 7px solid #f9314b;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 15px rgba(249, 49, 75, 0.2);
  transition: all 0.3s ease-in;
}

.features .single-feature .icon.two {
  border: 7px solid #f67a1f;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 15px rgba(246, 122, 31, 0.2);
  transition: all 0.3s ease-in;
}

.features .single-feature .icon.three {
  border: 7px solid #bc2cdd;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 15px rgba(188, 44, 221, 0.2);
  transition: all 0.3s ease-in;
}

.features .single-feature .icon.four {
  border: 7px solid #6efcc3;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 15px rgba(110, 252, 195, 0.2);
  transition: all 0.3s ease-in;
}

.features .single-feature .content .title {
  font-size: 18px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
}

.features .single-feature .content .link {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #a1aed4;
  margin-bottom: 0px;
  transition: all 0.3s ease-in;
}

.features .single-feature .content .link i {
  font-size: 14px;
  position: relative;
  transition: all 0.3s ease-in;
}

.features .single-feature .content .link i::after {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  height: 3px;
  width: 80%;
  background: #a1aed4;
  border-radius: 50px;
}

.features .single-feature .content .link:hover {
  color: #f9314b;
}

.features .single-feature .content .link:hover i {
  color: #f9314b;
  padding-left: 10px;
}

.features .single-feature .content .link:hover i::after {
  background: #f9314b;
}

.features .single-feature:hover {
  transform: translateY(-10px);
}

.features .single-feature:hover .icon.one {
  box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 30px rgba(249, 49, 75, 0.2);
}

.features .single-feature:hover .icon.two {
  box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 30px rgba(246, 122, 31, 0.2);
}

.features .single-feature:hover .icon.three {
  box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 30px rgba(188, 44, 221, 0.2);
}

.features .single-feature:hover .icon.four {
  box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 30px rgba(110, 252, 195, 0.2);
}

.features.about-page {
  padding: 112px 0px 90px;
  background: #070b28;
}

.features.about-page .section-heading {
  margin-bottom: 59px;
}

.features.about-page .feature-box {
  position: unset;
}

.features.about-page .feature-box .feature-box-inner {
  position: unset;
  top: 0px;
}

/* Features Area End */
/* Featured Game Area Start */
.featured-game {
  padding: 0px 0px 120px;
  /* background: url(../images/featuredgame-bg.jpg); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.featured-game .section-heading {
  margin-bottom: 44px;
}

.featured-game .game-slider .item .single-game {
  margin: 15px 15px 40px;
}

.featured-game .game-slider .item .single-game img {
  width: auto;
  display: inline-block;
  margin-top: -25px;
}

.featured-game .game-slider .owl-controls {
  text-align: center;
  margin-top: 35px;
}

.featured-game .game-slider .owl-controls .owl-nav {
  display: inline-block;
}

.featured-game .game-slider .owl-controls .owl-nav .owl-prev,
.featured-game .game-slider .owl-controls .owl-nav .owl-next {
  width: 40px;
  height: 40px;
  border: 1px solid #273b71;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin: 0px 10px;
  transition: all 0.3s ease-in;
}

.featured-game .game-slider .owl-controls .owl-nav .owl-prev:hover,
.featured-game .game-slider .owl-controls .owl-nav .owl-next:hover {
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  border-color: #681f40;
}

.single-game {
  height: 350px;
  display: block;
  line-height: 300px;
  text-align: center;
  border: 1px solid #273b71;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  position: relative;
  transition: all 0.3s ease-in;
}

.single-game img {
  margin-top: -25px;
  animation: opacityAnimation 2s linear infinite;
}

.single-game .mybtn2 {
  position: absolute;
  display: block;
  height: 50px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.single-game:hover {
  border-color: #681f40;
  transform: translateY(-10px);
}

/* Featured Game Area End */
/* Whay Choose us Area Start */
.why-choose-us {
  padding: 300px 0px 70px;
}

.why-choose-us .section-heading {
  margin-bottom: 57px;
}

.why-choose-us .single-why {
  display: flex;
  margin-bottom: 47px;
}

.why-choose-us .single-why .left {
  margin-right: 30px;
}

.why-choose-us .single-why .left .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(249, 49, 75, 0.4);
  text-align: center;
  line-height: 100px;
}

.why-choose-us .single-why .right {
  flex: 1 1;
}

.why-choose-us .single-why .right .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  margin-bottom: 11px;
}

.why-choose-us .single-why .right .text {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
  font-weight: 300;
}

/* Whay Choose us Area End */
/* activities Area Start */
.activities {
  padding: 110px 0px 113px;
  position: relative;
}

.activities .shape {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.activities .shape.shape1 {
  z-index: -5;
  top: 320px;
  animation: upDownAnimation 7s linear infinite;
}

.activities .shape.shape2 {
  z-index: -6;
  top: 260px;
  animation: upDownAnimation 6s linear infinite;
}

.activities .shape.shape3 {
  z-index: -7;
  top: 220px;
  animation: upDownAnimation 5s linear infinite;
}

.activities:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #070b28;
  z-index: -99;
}

.activities .section-heading {
  margin-bottom: 58px;
}

.activities .tab-menu-area {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.activities .tab-menu-area .nav-lend {
  display: inline-block;
  border-radius: 50px;
  padding: 20px 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.activities .tab-menu-area .nav-lend .nav-item {
  display: inline-block;
}

.activities .tab-menu-area .nav-lend .nav-item .nav-link {
  line-height: 50px;
  padding: 0px;
  padding: 0px 50px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background: #252f5a;
  margin: 0px 5px;
}

.activities .tab-menu-area .nav-lend .nav-item .nav-link.active {
  background: #f9314b;
  color: #fff;
}

.activities .table {
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
  position: relative;
}

.activities .table tr {
  transition: all 0.3s ease-in;
}

.activities .table tr td,
.activities .table tr th {
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.activities .table tr th {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-top: 0px;
  border-bottom: 0px;
  padding: 20px 15px;
  background: #0b122e;
  color: #fff;
}

.activities .table tr td {
  vertical-align: middle;
  padding: 20px 15px;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: #a1aed4;
}

.activities .table tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
}

.activities .table tr td:nth-child(1) img,
.activities .table tr td:nth-child(3) img,
.activities .table tr td:nth-child(6) img {
  margin-right: 5px;
}

.activities .table tr td:nth-child(1) {
  color: #fff;
}

.activities .table tr td:nth-child(3) {
  color: #045cf0;
}

.activities .table tr td:nth-child(5),
.activities .table tr td:nth-child(6) {
  color: #f0ad4e;
}

.activities .table tr td:last-child {
  color: #f0ad4e;
}

.activities .table tr:hover {
  background: #192246;
}

/* activities Area Start */
/* Fun fact Area Start */
.funfact {
  padding: 103px 0px 4px;
}

.funfact .single-fun {
  text-align: center;
}

.funfact .single-fun img {
  display: inline-block;
  margin-bottom: 13px;
}

.funfact .single-fun .count-area {
  font-size: 70px;
  line-height: 80px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.funfact .single-fun p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 0px;
  display: inline-block;
  margin-top: 9px;
}

/* Fun fact Area End */
/* Get Start Area Start */
.get-start {
  padding: 120px 0px 120px;
  background: #0b122e;
}

.get-start .left-area .section-heading {
  text-align: left;
  margin-bottom: 0px;
}

.get-start .left-area .section-heading .mybtn1 {
  margin-top: 36px;
}

.get-start .right-image {
  text-align: center;
}

.get-start .right-image img {
  display: inline-block;
}

/* Get Start Area End */
/*  Recent Winners Area Start */
.recent-winners {
  background: #070b28;
  padding: 112px 0px 0px;
}

.recent-winners .section-heading {
  margin-bottom: 58px;
}

.recent-winners .mybtn2 {
  text-align: center;
  padding: 0px;
  margin-top: 60px;
}

.single-winer {
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 10px 15px 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
}

.single-winer .top-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.single-winer .top-area .left .name {
  font-size: 20px;
  line-height: 34px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 3px;
}

.single-winer .top-area .left .date {
  font-size: 18px;
  line-height: 28px;
}

.single-winer .top-area .right .id {
  background: #0d1535;
  border: 1px solid #1a1e39;
  font-size: 14px;
  line-height: 24px;
  border-radius: 50px;
  padding: 4px 15px;
  margin-bottom: 0px;
}

.single-winer .bottom-area {
  display: flex;
  justify-content: space-between;
}

.single-winer .bottom-area .left {
  font-size: 28px;
  color: #f0ad4e;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  height: 28px;
}

.single-winer .bottom-area .right {
  text-align: right;
}

.single-winer:hover {
  border-color: #681f40;
  transform: translateY(-10px);
}

/*  Recent Winners Area End */
/*	Index 2 Css Start */
.index2 .header .mainmenu-area {
  background: none;
}

.index2 .header .mainmenu-area .navbar::before,
.index2 .header .mainmenu-area .navbar::after {
  display: none;
}

.index2 .header .mainmenu-area .navbar .navbar-brand {
  padding-left: 0px;
}

.index2
  .header
  .mainmenu-area
  .navbar
  #main_menu
  .navbar-nav
  .nav-item
  .nav-link
  .mr-hover-effect {
  display: none;
}

.index2 .hero-area {
  /* background: url(../images/herobg2.jpg); */
  padding: 372px 0px 424px;
}

.index2 .hero-area .left-content .content {
  text-align: center;
}

.index2 .features {
  background: #070b28;
  padding: 277px 0px 90px;
}

.index2 .features .section-heading {
  margin-bottom: 58px;
}

.index2 .features .feature-box .feature-box-inner {
  position: unset;
  width: 100%;
  top: auto;
}

.index2 .featured-game {
  padding: 110px 0px 120px;
}

.single-feature.landscape {
  display: flex;
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 45px 30px 45px 45px;
  background: none;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}

.single-feature.landscape .icon {
  margin-right: 45px;
  margin-bottom: 0px;
  background: #242e5a;
}

.single-feature.landscape .content {
  flex: 1 1;
  text-align: left;
  -ms-grid-row-align: center;
  align-self: center;
}

/*	Index 2 Css End */
/* About Area Start */
.about-section {
  background: #070b28;
  margin-top: -164px;
  overflow: hidden;
}

.about-section .about-box {
  position: relative;
}

.about-section .about-box .about-box-inner {
  padding: 50px 30px 60px;
  background: linear-gradient(to left, #3b1f75, #4f64de);
}

.about-section .about-box .about-box-inner .video-area {
  height: 100%;
  width: 100%;
  position: relative;
}

.about-section .about-box .about-box-inner .section-heading {
  text-align: left;
  position: relative;
  z-index: 99;
}

.about-section .about-box .about-box-inner .section-heading .mybtn1 {
  margin-top: 22px;
}

/*	About Area  End */
/* About page section Area Start */
.about-page-section {
  background: #0b122e;
  padding: 112px 0px 120px;
  position: relative;
}

.about-page-section .section-heading {
  text-align: left;
}

.about-page-section .section-heading .mybtn1 {
  margin-top: 23px;
}

.about-page-section .about-video {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.about-page-section .about-video img {
  width: 100%;
}

.about-page-section .about-video .play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #eedcde;
  text-align: center;
  line-height: 120px;
}

.about-page-section .about-video .play-video img {
  width: auto;
}

/* Our Vision Area Start */
.our-vision {
  padding: 112px 0px 86px;
  /* background: url(../images/our-vision.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.our-vision .section-heading {
  margin-bottom: 78px;
}

.our-vision .single-vision {
  text-align: center;
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}

.our-vision .single-vision .icon {
  width: 80px;
  height: 80px;
  margin-bottom: 43px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  position: relative;
}

.our-vision .single-vision .icon img {
  display: inline-block;
}

.our-vision .single-vision .icon.one {
  background: #f9314b;
  box-shadow: 0px 0px 0px 10px rgba(249, 49, 75, 0.2),
    0px 0px 0px 20px rgba(249, 49, 75, 0.2);
}

.our-vision .single-vision .icon.two {
  background: #f67a1f;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 10px rgba(246, 122, 31, 0.2),
    0px 0px 0px 20px rgba(246, 122, 31, 0.2);
}

.our-vision .single-vision .icon.three {
  background: #bc2cdd;
  box-shadow: 0px 0px 0px 10px rgba(188, 44, 221, 0.2),
    0px 0px 0px 20px rgba(188, 44, 221, 0.2);
}

.our-vision .single-vision .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
}

.our-vision .single-vision .content .link {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #a1aed4;
  margin-bottom: 0px;
  transition: all 0.3s ease-in;
}

.our-vision .single-vision .content .link i {
  font-size: 14px;
  position: relative;
  transition: all 0.3s ease-in;
}

.our-vision .single-vision .content .link i::after {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  height: 3px;
  width: 80%;
  background: #a1aed4;
  border-radius: 50px;
}

.our-vision .single-vision .content .link:hover {
  color: #f9314b;
}

.our-vision .single-vision .content .link:hover i {
  color: #f9314b;
  padding-left: 10px;
}

.our-vision .single-vision .content .link:hover i::after {
  background: #f9314b;
}

.our-vision .single-vision:hover {
  transform: translateY(-10px);
}

/* Our Vision Area End */
/* Team Area Start */
.team .top-area {
  /* background: url(../images/team/team-top-area.jpg); */
  padding: 112px 0px 223px;
}

.team .team-members {
  background: #070b28;
  padding-bottom: 120px;
}

.team .team-members .team-member-inner {
  margin-top: -180px;
}

.team .team-members .team-member-inner .single-team {
  border-radius: 11px;
  overflow: hidden;
  transition: all 0.3s ease-in;
  box-shadow: 0px 5px 30px 0px rgba(7, 11, 40, 0.4);
}

.team .team-members .team-member-inner .single-team .image {
  background: #1d2453;
  position: relative;
}

.team .team-members .team-member-inner .single-team .image img {
  width: 100%;
}

.team .team-members .team-member-inner .single-team .image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    #011438 0%,
    rgba(1, 20, 56, 0.8) 20%,
    rgba(1, 20, 56, 0.4) 40%,
    rgba(1, 20, 56, 0) 50%,
    rgba(1, 20, 56, 0) 80%,
    rgba(1, 20, 56, 0) 100%
  );
}

.team .team-members .team-member-inner .single-team .image .content {
  display: block;
  text-align: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  left: 0px;
  bottom: 22px;
}

.team .team-members .team-member-inner .single-team .image .content .name {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0px;
}

.team
  .team-members
  .team-member-inner
  .single-team
  .image
  .content
  .designation {
  color: #f9314b;
  font-size: 20px;
  line-height: 30px;
}

.team .team-members .team-member-inner .single-team .social-area {
  background: #1d2850;
  display: block;
  text-align: center;
  padding: 30px 30px 30px;
}

.team .team-members .team-member-inner .single-team .social-area .social-links {
  display: inline-block;
  background-color: #2c356a;
  border-radius: 5px;
  box-shadow: 0px 5px 30px 0px rgba(7, 11, 40, 0.2);
}

.team
  .team-members
  .team-member-inner
  .single-team
  .social-area
  .social-links
  li {
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.team
  .team-members
  .team-member-inner
  .single-team
  .social-area
  .social-links
  li:last-child {
  border-right: 0px;
}

.team
  .team-members
  .team-member-inner
  .single-team
  .social-area
  .social-links
  li
  a {
  height: 30px;
  width: 35px;
  display: block;
  font-size: 12px;
  line-height: 30px;
  transition: all 0.3s ease-in;
}

.team
  .team-members
  .team-member-inner
  .single-team
  .social-area
  .social-links
  li
  a:hover {
  color: #f9314b;
}

.team .team-members .team-member-inner .single-team:hover {
  transform: translateY(-10px);
}

.team .mybtn1 {
  display: inline-block;
  margin-top: 60px;
}

/* Team Area End */
/* community and Blog Area Start */
.community-blog {
  padding: 120px 09px 120px;
  background: #0b122e;
}

.community-blog .single-box {
  background: #1d2850;
  padding: 60px 30px 40px;
  display: flex;
  transition: all 0.3s ease-in;
}

.community-blog .single-box .img {
  width: 60px;
  margin-right: 30px;
}

.community-blog .single-box .content {
  flex: 1 1;
}

.community-blog .single-box .content .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.community-blog .single-box .content .title i {
  font-size: 20px;
  margin-left: 10px;
}

.community-blog .single-box .content .text {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

.community-blog .single-box:hover {
  transform: translateY(-10px);
}

/* community and Blog Area End */
/* Play Games Area Start */
.play-games {
  /* background: url(../images/play/playgame-bg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.play-games .gaming-activities {
  margin-top: -120px;
}

.play-games .gaming-activities .gaming-activities-inner .single-activities {
  background: #252f5a;
  padding: 0px 30px 20px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 21px;
  padding-bottom: 10px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .left
  .icon {
  position: relative;
  top: -20px;
  text-align: center;
  margin-right: 30px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .left
  .icon
  img {
  display: block;
  width: 70px;
  height: 70px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .left
  .icon
  span {
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 0px 10px;
  border-radius: 5px;
  top: -10px;
  position: relative;
  background: #252f5a;
  color: #fff;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .left
  .icon
  span
  i {
  font-size: 12px;
  color: #ffbf00;
  margin-left: 2px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .right {
  flex: 1 1;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .right
  .title {
  margin-top: 22px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .right
  .text {
  font-size: 14px;
  line-height: 24px;
  color: #f9314b;
  margin-bottom: 0px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .players-count {
  display: inline-block;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .players-count
  .players-list {
  display: inline-block;
  margin-left: 12px;
  padding-top: 5px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .players-count
  .players-list
  li {
  display: inline-block;
  border: 2px solid #252f5a;
  margin-left: -12px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .players-count
  .players-list
  li
  img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .players-count
  .count-num {
  display: inline-block;
  font-size: 10px;
  width: 25px;
  height: 25px;
  background: #252f5a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  margin-left: -12px;
  position: relative;
  top: -9px;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .pp {
  display: inline-block;
  text-align: right;
  margin-left: 15px;
  position: relative;
  top: 10px;
  float: right;
}

.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .bottom-area
  .pp
  p {
  margin-bottom: 0px;
  color: #cfcffd;
  font-size: 14px;
}

.play-games .all-games {
  padding: 119px 0px 120px;
}

.play-games .all-games .single-game {
  margin-bottom: 54px;
}

.play-games .all-games .mybtn1 {
  display: inline-block;
  margin-top: 30px;
}

.play-games .all-games .nav {
  text-align: center;
  display: inline-block;
  margin-bottom: 63px;
}

.play-games .all-games .nav li {
  display: inline-block;
}

.play-games .all-games .nav li a {
  width: 150px;
  height: 150px;
  border-style: solid;
  border-width: 1px;
  border-color: #273b71;
  border-radius: 50%;
  margin: 0px 10px;
  text-align: center;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.play-games .all-games .nav li a .icon {
  margin-top: 20px;
  display: inline-block;
}

.play-games .all-games .nav li a .icon img {
  height: 60px;
}

.play-games .all-games .nav li a .icon .one {
  display: block;
}

.play-games .all-games .nav li a .icon .two {
  display: none;
}

.play-games .all-games .nav li a span {
  display: block;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  text-shadow: 0px 0px 3px #2eb7eb, 0px 0px 7px #2eb7eb;
  color: #2eb7eb;
}

.play-games .all-games .nav li a.active {
  border-color: #f9314b !important;
}

.play-games .all-games .nav li a.active .icon .one {
  display: none;
}

.play-games .all-games .nav li a.active .icon .two {
  display: block;
}

.play-games .all-games .nav li a.active span {
  text-shadow: 0px 0px 3px #d9293e, 0px 0px 7px #d9293e;
  color: #d9293e;
}

/* Play Games Area End */
/* Lottery Staticstics Area Start */
.lottery-area {
  background: #0b122e;
  padding-bottom: 120px;
}

.lottery-area .lottery-staticstics {
  margin-top: -55px;
}

.lottery-area .lottery-staticstics .single-staticstics {
  padding: 30px 30px 30px;
  border-radius: 10px;
  background-color: rgba(29, 40, 80, 0.302);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  transition: all 0.3s ease-in;
}

.lottery-area .lottery-staticstics .single-staticstics .left {
  margin-right: 30px;
  width: 80px;
  height: 80px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #0b122e;
}

.lottery-area .lottery-staticstics .single-staticstics .right {
  flex: 1 1;
}

.lottery-area .lottery-staticstics .single-staticstics .right .title {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 26px;
}

.lottery-area .lottery-staticstics .single-staticstics .right .count img {
  display: inline-block;
  margin-right: 10px;
}

.lottery-area .lottery-staticstics .single-staticstics .right .count span {
  display: inline-block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #f6a623;
  position: relative;
  top: 3px;
}

.lottery-area .lottery-staticstics .single-staticstics:hover {
  transform: translateY(-10px);
}

.lottery-area .daily-lottery {
  padding: 113px 0px 120px;
}

.lottery-area .daily-lottery .section-heading {
  margin-bottom: 57px;
}

.lottery-area .daily-lottery .draw-time {
  /* background: url(../images/draw-time-bg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 53px 0px 53px;
  text-align: center;
}

.lottery-area .daily-lottery .draw-time .subtitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0px;
  background: linear-gradient(to left, #ff344d, #d2273c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lottery-area .daily-lottery .draw-time .text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 9px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.lottery-area .daily-lottery .draw-time .draw-counter {
  margin-top: 7px;
  border-radius: 3px;
  display: inline-block;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
}

.lottery-area .daily-lottery .draw-time .draw-counter span {
  display: inline-block;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  color: #fff;
}

.lottery-area .daily-lottery .draw-time .draw-counter span:last-child {
  margin-right: 0px;
}

.lottery-area .buy-tickets .buy-tickets-box {
  background: #070b28;
}

.lottery-area .buy-tickets .buy-tickets-box .heading {
  padding: 15px 40px 10px;
  display: flex;
  justify-content: space-between;
  background: #101b3a;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.lottery-area .buy-tickets .buy-tickets-box .content {
  padding: 40px 40px 40px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .info-box {
  padding: 21px 30px 20px;
  background: #0e1833;
  border: 1px solid #273b71;
  border-radius: 8px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.lottery-area .buy-tickets .buy-tickets-box .content .info-box .title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .info-box .number {
  font-size: 16px;
  line-height: 26px;
  color: #f6a623;
  font-weight: 600;
}

.Buy-button {
  padding: 15px;
  background-color: black;
  text-align: center;
  border-radius: 20px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .info-box .number i {
  margin-right: 5px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .info-box .number input {
  height: 25px;
  border-radius: 50px;
  border: 0px;
  color: #f6a623;
  background: #16203a;
  padding: 0px 20px;
  width: 100%;
}

.lottery-area .buy-tickets .buy-tickets-box .content .game-numbers {
  padding-top: 30px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .game-numbers .title {
  font-size: 18px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .game-numbers .number-box {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .auto-number {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 33px 30px 30px;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .auto-number
  .top-content {
  position: relative;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .auto-number
  .top-content
  input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .auto-number
  .top-content
  label {
  padding-left: 30px;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .auto-number
  .top-content
  label
  span {
  display: block;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .manual-number {
  padding: 33px 30px 37px;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .manual-number
  .top-content {
  position: relative;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .manual-number
  .top-content
  input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .manual-number
  .top-content
  label {
  padding-left: 30px;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .manual-number
  .top-content
  label
  span {
  display: block;
}

.lottery-area
  .buy-tickets
  .buy-tickets-box
  .content
  .game-numbers
  .number-box
  .manual-number
  .main-content {
  margin-top: 26px;
}

.lottery-area .buy-tickets .buy-tickets-box .content .mybtn1 {
  margin-top: 60px;
  display: inline-block;
}

/* Lottery Staticstics Area End */
/*  How it Work Area Start  */
.how-it-work {
  padding: 97px 0px 496px;
  position: relative;
}

.how-it-work .bg-shape {
  position: absolute;
  top: 350px;
  left: 50%;
  transform: translateX(-50%);
}

.how-it-work .section-heading {
  margin-bottom: 59px;
}

.how-it-work .single-work {
  text-align: center;
}

.how-it-work .single-work img {
  display: inline-block;
  margin-bottom: 20px;
}

.how-it-work .single-work .title {
  font-size: 28px;
  line-height: 38px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 5px;
}

.how-it-work .single-work p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 0px;
  display: inline-block;
  margin-top: 9px;
}

/* How it Work Area End*/
.sidebars {
  margin-left: 250px !important;
}

/* @media screen and (max-width: 800px) {
  .sidebars {
  margin-left: 0px !important;
  }
} */
/* lottery video Area Start */
.lottery-video {
  background: #0b122e;
  padding: 0px 0px 70px;
  margin-top: -245px;
}

.lottery-video .video-box {
  /* background: url(../images/lottery-video.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 495px;
  position: relative;
  margin-top: -245px;
}

.lottery-video .video-box .video-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* lottery video Area END */
/* Questions Area Start */
.question-area {
  background-color: #0b122e;
  padding: 50px 0px 120px;
}

.question-area .section-heading {
  text-align: left;
}

.question-area .section-heading .title {
  margin-bottom: 30px;
}

.question-area .section-heading .mybtn1 {
  margin-top: 22px;
}

/* Questions Area End */
/* Cart Area Start */
.cart-area {
  background: #0b122e;
  padding: 30px 0px 120px;
}

.cart-area .message-one {
  background: #242570;
  padding: 22px 100px 28px;
  text-align: center;
}

.cart-area .message-one p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0px;
}

.cart-area .message-two {
  display: flex;
  justify-content: space-between;
  background: #182455;
  margin-top: 40px;
  padding: 30px 30px 30px;
}

.cart-area .message-two .left {
  flex: 1 1;
}

.cart-area .message-two .left p {
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  margin-bottom: 0px;
  color: #f1f0f0;
}

.cart-area .message-two .left p i {
  margin-right: 4px;
}

.cart-area .message-two .right a {
  -ms-grid-row-align: center;
  align-self: center;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  border: 1px solid #242570;
  padding: 6px 20px;
  display: inline-block;
  border-radius: 50px;
  color: #fff;
  transition: all 0.3s ease-in;
}

.cart-area .message-two .right a i {
  margin-right: 4px;
}

.cart-area .message-two .right a:hover {
  background: #242570;
  color: #fff;
  border-color: #242570;
}

.cart-area .cart-table-area .table {
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
  position: relative;
  margin-top: 0px;
}

.cart-area .cart-table-area .table thead tr th {
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  background: #101938;
}

.cart-area .cart-table-area .table tr {
  transition: all 0.3s ease-in;
}

.cart-area .cart-table-area .table tr td,
.cart-area .cart-table-area .table tr th {
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.cart-area .cart-table-area .table tr th {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-top: 0px;
  border-bottom: 0px;
  padding: 20px 15px;
  background: #0b122e;
}

.cart-area .cart-table-area .table tr td {
  vertical-align: middle;
  padding: 15px 5px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #a1aed4;
}

.cart-area .cart-table-area .table tr td:nth-child(1) img,
.cart-area .cart-table-area .table tr td:nth-child(3) img,
.cart-area .cart-table-area .table tr td:nth-child(6) img {
  margin-right: 5px;
}

.cart-area .cart-table-area .table tr td:nth-child(1) {
  color: #fff;
}

.cart-area .cart-table-area .table tr td:nth-child(6) {
  color: #f0ad4e;
}

.cart-area .cart-table-area .table tr td:last-child {
  color: #f0ad4e;
}

.cart-area .cart-table-area .table tr td .time {
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 0px;
}

.cart-area .cart-table-area .table tr td .remove {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  background-color: #131b3c;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.cart-area .cart-table-area .table tr td .remove:hover {
  background: #f0ad4e;
  color: #fff;
}

.cart-area .cart-table-area .table tr td .qty {
  display: inline-block;
  float: left;
  padding: 4px 10px;
  border-radius: 50px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
}

.cart-area .cart-table-area .table tr td .qty ul li {
  display: inline-block;
}

.cart-area .cart-table-area .table tr td .qty ul li:first-child {
  margin-left: 0px;
}

.cart-area .cart-table-area .table tr td .qty ul li .qttotal,
.cart-area .cart-table-area .table tr td .qty ul li .qtminus,
.cart-area .cart-table-area .table tr td .qty ul li .qtplus {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.3s ease-in;
}

.cart-area .cart-table-area .table tr td .qty ul li .qtminus:hover,
.cart-area .cart-table-area .table tr td .qty ul li .qtplus:hover {
  color: #f9314b;
}

.cart-area .cart-table-area .table tr td .qty ul li .qttotal:focus,
.cart-area .cart-table-area .table tr td .qty ul li .qtminus:focus,
.cart-area .cart-table-area .table tr td .qty ul li .qtplus:focus {
  outline: 0px;
}

.cart-area .cart-table-area .table tr td .qty ul li .qttotal {
  width: 30px;
}

.cart-area .cart-table-area .table tr:hover {
  background: #192246;
}

.cart-area .total-pay {
  margin-top: 60px;
  text-align: center;
  padding: 28px 30px 22px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
}

.cart-area .total-pay .content {
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
}

.cart-area .total-pay .content span {
  -ms-grid-row-align: center;
  align-self: center;
  align-content: center;
  margin-right: 20px;
}

.cart-area .total-pay .content .num {
  color: #045cf0;
}

.cart-area .total-pay .content .num img {
  margin-right: 10px;
}

/* Cart Area End */
/* Payment-method Area Start */
.payment-method {
  background: #070b28;
  padding: 110px 0px 0px;
}

.payment-method .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.payment-method .text {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 0px;
}

.payment-method .method-slider {
  margin-top: 60px;
  padding: 0px 60px;
}

.payment-method .method-slider .item .single-method {
  align-items: center;
  border: 1px solid rgba(4, 92, 240, 0.3);
  text-align: center;
  display: block;
  height: 80px;
  width: 95px;
  padding-top: 14px;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.payment-method .method-slider .item .single-method.active {
  border-color: #f9314b;
}

.payment-method .method-slider .item .single-method .icon {
  max-height: 35px;
}

.payment-method .method-slider .item .single-method .icon img {
  width: auto;
  display: block;
  margin: 0 auto;
}

.payment-method .method-slider .item .single-method span {
  font-size: 12px;
  margin-bottom: 0px;
  line-height: 22px;
  color: #ceceff;
}

.payment-method .method-slider .item .single-method:hover,
.payment-method .method-slider .item .single-method.active {
  border-color: #f9314b;
}

.payment-method .method-slider .owl-controls .owl-nav .owl-prev,
.payment-method .method-slider .owl-controls .owl-nav .owl-next {
  font-size: 18px;
  position: absolute;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  background: #2b304e;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in;
  color: rgba(255, 255, 255, 0.62);
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.8);
}

.payment-method .method-slider .owl-controls .owl-nav .owl-prev:hover,
.payment-method .method-slider .owl-controls .owl-nav .owl-next:hover {
  background: #f9314b;
  color: #fff;
}

.payment-method .method-slider .owl-controls .owl-nav .owl-prev {
  left: 0px;
}

.payment-method .method-slider .owl-controls .owl-nav .owl-next {
  right: 0px;
}

.payment-method .mybtn1 {
  margin-top: 60px;
}

/* Payment-method Area End */
/* Tournaments Area Start */
.tournaments {
  background: #0b122e;
  padding: 112px 0px 118px;
}

.tournaments .section-heading {
  margin-bottom: 58px;
}

.tournaments .info-table {
  margin-top: 60px;
}

.tournaments .info-table .main-box {
  padding: 0px 0px 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tournaments .info-table .main-box .main-header-area {
  padding: 25px 30px 26px;
  display: block;
  text-align: center;
  background: #0c1330;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tournaments .info-table .main-box .main-header-area .nav {
  display: inline-block;
}

.tournaments .info-table .main-box .main-header-area .nav li {
  display: inline-block;
  position: relative;
}

.tournaments .info-table .main-box .main-header-area .nav li::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 4px;
  background: #fff;
  right: -2px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

.tournaments
  .info-table
  .main-box
  .main-header-area
  .nav
  li:last-child::before {
  display: none;
}

.tournaments .info-table .main-box .main-header-area .nav li a {
  margin-bottom: 0px;
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  margin: 0px 10px;
  text-transform: uppercase;
  color: #fff;
}

.tournaments .info-table .main-box .main-header-area .nav li a.active {
  color: #f9314b;
}

.tournaments .info-table .main-box .inner-table-content {
  padding: 0px 30px;
}

.tournaments .info-table .main-box .inner-table-content .header-area {
  display: block;
  text-align: center;
  padding: 40px 0px 40px;
}

.tournaments .info-table .main-box .inner-table-content .header-area ul {
  display: inline-block;
  border-radius: 50px;
  padding: 20px 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .header-area
  ul
  .nav-item {
  display: inline-block;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .header-area
  ul
  .nav-item
  .nav-link {
  line-height: 50px;
  padding: 0px;
  padding: 0px 50px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background: #252f5a;
  margin: 0px 5px;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .header-area
  ul
  .nav-item
  .nav-link.active {
  background: #f9314b;
  color: #fff;
}

.tournaments .info-table .main-box .inner-table-content .tab-content {
  margin-top: 0px;
}

.tournaments .info-table .main-box .inner-table-content .table {
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
  position: relative;
}

.tournaments .info-table .main-box .inner-table-content .table tr {
  transition: all 0.3s ease-in;
}

.tournaments .info-table .main-box .inner-table-content .table tr td,
.tournaments .info-table .main-box .inner-table-content .table tr th {
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.tournaments .info-table .main-box .inner-table-content .table tr th {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-top: 0px;
  border-bottom: 0px;
  padding: 20px 15px;
  background: #12193a;
  color: #fff;
}

.tournaments .info-table .main-box .inner-table-content .table tr td {
  vertical-align: middle;
  padding: 20px 15px;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: #a1aed4;
}

.tournaments .info-table .main-box .inner-table-content .table tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(1)
  img,
.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(3)
  img,
.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(6)
  img {
  margin-right: 5px;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(1) {
  color: #fff;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(3) {
  color: #045cf0;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(5),
.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:nth-child(6) {
  color: #f0ad4e;
}

.tournaments
  .info-table
  .main-box
  .inner-table-content
  .table
  tr
  td:last-child {
  color: #f0ad4e;
}

.tournaments .info-table .main-box .inner-table-content .table tr:hover {
  background: #192246;
}

.tournaments .info-table .main-box .info-content {
  padding-bottom: 15px;
}

.tournaments .info-table .main-box .info-content .info-box .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}

.tournaments .info-table .main-box .info-content .info-box .text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

.tournaments .info-table .main-box .info-content .info-box.two {
  margin-top: 53px;
}

.tournaments .info-table .main-box .info-content .info-box.three {
  margin-top: 53px;
}

.tournaments .info-table .main-box .info-content .mybtn1 {
  margin-top: 16px;
}

.tournament-time-box {
  background: #070b28;
}

.tournament-time-box .top-area {
  padding: 23px 30px 23px;
  background: #0b122e;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  box-shadow: 1.753px -3.595px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.tournament-time-box .top-area .status {
  position: absolute;
  background: #3c26db;
  color: #fff;
  font-size: 18px;
  left: -57px;
  top: 18px;
  line-height: 28px;
  height: 40px;
  line-height: 40px;
  width: 200px;
  text-align: center;
  transform: rotate(-41deg);
}

.tournament-time-box .top-area .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.tournament-time-box .top-area .sub-title {
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #f9314b;
  font-size: 18px;
  line-height: 28px;
}

.tournament-time-box .timer-area {
  padding: 50px 30px 76px;
  text-align: center;
  position: relative;
}

.tournament-time-box .timer-area .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.tournament-time-box .timer-area .clock {
  display: inline-block;
}

.tournament-time-box .timer-area .clock2 {
  display: inline-block;
}

.clock.flip-clock-wrapper {
  display: inline-block;
}

.flip-clock-wrapper {
  width: auto;
}

.flip-clock-wrapper ul {
  width: 50px;
  border: 0px;
  position: relative;
}

.flip-clock-wrapper ul::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 9;
  border-radius: 3px;
}

.flip-clock-wrapper ul li a div div.inn {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #0c132e;
}

.flip-clock-divider .flip-clock-label {
  bottom: -38px;
  right: -88px;
  top: auto;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300 !important;
  color: #a1aed4;
  letter-spacing: 2px;
}

.flip-clock-divider.minutes .flip-clock-label {
  right: -104px;
}

.flip-clock-divider.hours .flip-clock-label {
  right: -94px;
}

.flip-clock-dot {
  background: #959ba9;
}

/* Tournaments Area End */
/* Bonuses Area Start */
.bonus-area {
  padding: 112px 0px 90px;
  background: #0b122e;
}

.bonus-area .section-heading {
  margin-bottom: 58px;
}

.single-bonus {
  height: 350px;
  margin-bottom: 55px;
  display: block;
  text-align: center;
  border: 1px solid #273b71;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  position: relative;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-bonus .content img {
  animation: opacityAnimation 2s linear infinite;
  display: inline-block;
  margin-bottom: 22px;
}

.single-bonus .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
}

.single-bonus .mybtn2 {
  position: absolute;
  display: block;
  height: 50px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.single-bonus:hover {
  border-color: #681f40;
  transform: translateY(-10px);
}

/* Bonuses Area End */
/*Deposit Bonuses Area Start */
.deposit-bonuses {
  padding: 112px 0px 120px;
  background: #070b28;
}

.deposit-bonuses .section-heading {
  margin-bottom: 58px;
}

.single-deposit-bonus {
  display: block;
  text-align: center;
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 30px 40px 28px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
}

.single-deposit-bonus .content img {
  display: inline-block;
  margin-bottom: 22px;
}

.single-deposit-bonus .content .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.single-deposit-bonus .content p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
}

.single-deposit-bonus:hover {
  border-color: #681f40;
  transform: translateY(-10px);
}

.awards-area {
  padding: 112px 0px 90px;
  background: #070b28;
}

.awards-area .section-heading {
  margin-bottom: 58px;
}

.single-awards {
  margin-bottom: 55px;
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 60px 40px 84px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
}

.single-awards .content img {
  display: inline-block;
  margin-bottom: 22px;
}

.single-awards .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 21px;
}

.single-awards .content p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  border: 1px solid #273b71;
  border-radius: 50px;
  display: inline-block;
  padding: 2px 25px;
}

.single-awards .content p i {
  margin-right: 8px;
}

.single-awards .content .mybtn2 {
  position: absolute;
  display: block;
  height: 50px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.single-awards:hover {
  border-color: #681f40;
  transform: translateY(-10px);
}

/* Awards Area End */
/*  How Play Area Start */
.how-play {
  padding: 112px 0px 593px;
  /* background: url(../images/how-play.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.how-play .section-heading {
  margin-bottom: 59px;
}

.single-h-play {
  text-align: center;
}

.single-h-play img {
  margin-bottom: 25px;
  display: inline-block;
  height: 100px;
}

.single-h-play .steps {
  font-size: 14px;
  color: #f9314b;
  text-transform: uppercase;
}

.single-h-play .steps i {
  margin-left: 8px;
}

.single-h-play .title {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  text-transform: uppercase;
}

/*  How Play Area End */
/*  Affiliate Process area Start */
.affiliate-process {
  padding: 0px 0px 112px;
  background: #0b122e;
  margin-top: -80px;
}

.single-process {
  padding: 40px 40px 39px;
  background: #141e47;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in;
}

.single-process img {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 21px;
}

.single-process .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.single-process .text {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 0px;
}

.single-process .num {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 200px;
  line-height: 200px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.05);
}

.single-process:hover {
  transform: translateY(-10px);
}

/*  Affiliate Process area End */
/* Features Area Start */
.affiliate-program {
  background: #0b122e;
  padding: 0px 0px 90px;
}

.affiliate-program .section-heading {
  margin-bottom: 51px;
}

.affiliate-program .single-program {
  display: flex;
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 45px 30px 45px 45px;
  background: none;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}

.affiliate-program .single-program .icon {
  margin-right: 45px;
  margin-bottom: 0px;
  background: #242e5a;
  width: 125px;
  height: 125px;
  line-height: 120px;
  position: relative;
}

.affiliate-program .single-program .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid #1c2748;
  border-radius: 50%;
}

.affiliate-program .single-program .icon::before {
  position: absolute;
  content: "";
  top: 3%;
  left: 3%;
  width: 94%;
  height: 94%;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 1;
}

.affiliate-program .single-program .icon.one {
  border: 7px solid #f9314b;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 15px rgba(249, 49, 75, 0.2);
  transition: all 0.3s ease-in;
}

.affiliate-program .single-program .icon.two {
  border: 7px solid #f67a1f;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 15px rgba(246, 122, 31, 0.2);
  transition: all 0.3s ease-in;
}

.affiliate-program .single-program .icon.three {
  border: 7px solid #bc2cdd;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 15px rgba(188, 44, 221, 0.2);
  transition: all 0.3s ease-in;
}

.affiliate-program .single-program .icon.four {
  border: 7px solid #6efcc3;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 15px rgba(110, 252, 195, 0.2);
  transition: all 0.3s ease-in;
}

.affiliate-program .single-program .content {
  flex: 1 1;
  text-align: left;
  -ms-grid-row-align: center;
  align-self: center;
}

.affiliate-program .single-program .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
}

.affiliate-program .single-program .content .link {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #a1aed4;
  margin-bottom: 0px;
  transition: all 0.3s ease-in;
}

.affiliate-program .single-program .content .link i {
  font-size: 14px;
  position: relative;
  transition: all 0.3s ease-in;
}

.affiliate-program .single-program .content .link i::after {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  height: 3px;
  width: 80%;
  background: #a1aed4;
  border-radius: 50px;
}

.affiliate-program .single-program .content .link:hover {
  color: #f9314b;
}

.affiliate-program .single-program .content .link:hover i {
  color: #f9314b;
  padding-left: 10px;
}

.affiliate-program .single-program .content .link:hover i::after {
  background: #f9314b;
}

.affiliate-program .single-program:hover {
  transform: translateY(-10px);
}

.affiliate-program .single-program:hover .icon.one {
  box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 30px rgba(249, 49, 75, 0.2);
}

.affiliate-program .single-program:hover .icon.two {
  box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 30px rgba(246, 122, 31, 0.2);
}

.affiliate-program .single-program:hover .icon.three {
  box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 30px rgba(188, 44, 221, 0.2);
}

.affiliate-program .single-program:hover .icon.four {
  box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 30px rgba(110, 252, 195, 0.2);
}

/* Features Area End */
/* Ref Commission Area Start  */
.ref-commission {
  padding: 113px 0px 120px;
  background: #070b28;
  position: relative;
}

.ref-commission .ab-img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 40%;
}

.ref-commission .section-heading {
  text-align: left;
}

.ref-commission .mybtn1 {
  margin-top: 22px;
}

/* Ref Commission Area End  */
/* Marketing Tools Area Start */
.marketing-tools {
  padding: 112px 0px 86px;
  background: #0b122e;
}

.marketing-tools .section-heading {
  margin-bottom: 58px;
}

.marketing-tools .single-tools {
  text-align: center;
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}

.marketing-tools .single-tools .icon img {
  display: inline-block;
}

.marketing-tools .single-tools .content .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
  margin-top: 21px;
}

.marketing-tools .single-tools .content .text {
  font-size: 18px;
  line-height: 28px;
}

.marketing-tools .single-tools:hover {
  transform: translateY(-10px);
}

/* Marketing Tools Area End */
/*Testimonial Area CSS Start*/
.testimonial {
  padding: 113px 0px 120px;
  position: relative;
}

.testimonial::after {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -99;
  background: #070b28;
}

.testimonial .section-heading {
  margin-bottom: 58px;
}

.testimonial .testimonial-slider {
  position: relative;
}

.testimonial .testimonial-slider::after {
  position: absolute;
  content: "";
  bottom: -15px;
  left: 3%;
  width: 94%;
  height: 100%;
  border-radius: 6px;
  z-index: -10;
  background: rgba(255, 255, 255, 0.08);
}

.testimonial .testimonial-slider::before {
  position: absolute;
  content: "";
  bottom: -30px;
  left: 5%;
  width: 90%;
  height: 100%;
  border-radius: 6px;
  z-index: -10;
  background: rgba(255, 255, 255, 0.08);
}

.testimonial .single-testimonial {
  text-align: center;
  background: #0b122e;
  padding: 40px 30px 39px;
  border-radius: 6px;
}

.testimonial .single-testimonial .people {
  margin-bottom: 14px;
}

.testimonial .single-testimonial .people .img {
  display: inline-block;
}

.testimonial .single-testimonial .people .img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #f9314b;
}

.testimonial .single-testimonial .people .title {
  margin-top: 23px;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.testimonial .single-testimonial .people .designation {
  font-size: 14px;
  line-height: 24px;
  color: #f9314b;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.testimonial .single-testimonial .review-text p {
  margin-bottom: 0px;
  color: #fff;
  font-size: 28px;
  line-height: 38px;
  font-weight: 300;
}

.testimonial .owl-controls {
  position: relative;
}

.testimonial .owl-controls .owl-nav {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}

.testimonial .owl-controls .owl-nav .owl-prev,
.testimonial .owl-controls .owl-nav .owl-next {
  width: 40px;
  height: 40px;
  border: 1px solid #273b71;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin: 0px 10px;
  transition: all 0.3s ease-in;
}

.testimonial .owl-controls .owl-nav .owl-prev:hover,
.testimonial .owl-controls .owl-nav .owl-next:hover {
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  border-color: #681f40;
}

/*Testimonial Area CSS End*/
/* Serch Area Start */
.serch-area {
  background: #0b122e;
}

.serch-area .serch-box {
  padding: 40px 50px 40px;
  position: relative;
  top: -56px;
  border-radius: 70px;
  background-color: #1c294e;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);
}

.serch-area .serch-box .list {
  display: flex;
  flex-wrap: wrap;
}

.serch-area .serch-box .list li.input-field {
  flex: 1 1;
}

.serch-area .serch-box .list li.input-field input {
  width: 100%;
  border: 0px;
  height: 50px;
  color: #a1aed4;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.serch-area .serch-box .list li.input-field input::-webkit-input-placeholder {
  color: #a1aed4;
}

.serch-area .serch-box .list li.input-field input:-moz-placeholder {
  color: #a1aed4;
}

.serch-area .serch-box .list li.input-field input::-moz-placeholder {
  color: #a1aed4;
}

.serch-area .serch-box .list li.input-field input:-ms-input-placeholder {
  color: #a1aed4;
}

.serch-area .serch-box .list li.button {
  width: 190px;
  margin-left: 30px;
}

.serch-area .serch-box .list li.button .mybtn1 {
  display: block;
  width: 100%;
}

/* Serch Area End */
/* Help Section Area Start */
.help-section {
  padding: 65px 0px 120px;
  background: #0b122e;
}

.help-section .help-box {
  padding: 45px 60px 60px;
  border-radius: 7px;
  background: #070b28;
}

.help-section .help-box .help-box-inner .title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 29px;
  text-transform: uppercase;
}

.help-section .help-box .help-box-inner .content {
  flex: 1 1;
}

.help-section .help-box .help-box-inner .content .writer-profile {
  display: flex;
}

.help-section .help-box .help-box-inner .content .writer-profile img {
  margin-right: 30px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.help-section
  .help-box
  .help-box-inner
  .content
  .writer-profile
  .writer-content {
  flex: 1 1;
  -ms-grid-row-align: center;
  align-self: center;
}

.help-section
  .help-box
  .help-box-inner
  .content
  .writer-profile
  .writer-content
  .w-name {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.help-section
  .help-box
  .help-box-inner
  .content
  .writer-profile
  .writer-content
  .w-name
  a {
  color: #31b3f6;
}

.help-section
  .help-box
  .help-box-inner
  .content
  .writer-profile
  .writer-content
  p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
}

.help-section .help-box .help-box-inner:hover {
  box-shadow: none;
}

.help-section .help-box .help-box-inner2-hearer {
  margin-top: 72px;
}

.help-section .help-box .help-box-inner2-hearer .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px;
}

.help-section .help-box .help-box-inner2 {
  margin-top: 36px;
  background: #0b122e;
}

.help-section .help-box .help-box-inner2 .single-artical {
  padding: 22px 30px 30px;
  transition: all 0.3s ease-in;
  border-bottom: 1px solid #18234c;
}

.help-section .help-box .help-box-inner2 .single-artical:first-child {
  border-top: 1px solid #18234c;
}

.help-section .help-box .help-box-inner2 .single-artical .content .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 21px;
}

.help-section .help-box .help-box-inner2 .single-artical .writer-profile {
  margin-top: 15px;
  display: flex;
}

.help-section .help-box .help-box-inner2 .single-artical .writer-profile img {
  margin-right: 30px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.help-section
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content {
  flex: 1 1;
  -ms-grid-row-align: center;
  align-self: center;
}

.help-section
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content
  .w-name {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.help-section
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content
  .w-name
  a {
  color: #31b3f6;
}

.help-section
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content
  p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
}

.help-section .help-box .help-box-inner3-hearer {
  margin-top: 72px;
}

.help-section .help-box .help-box-inner3-hearer .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px;
}

.help-section.writter-artical-details .help-box {
  padding: 0px 60px 12px !important;
}

.help-section.writter-artical-details .help-box .help-box-inner2 {
  padding-top: 26px;
  background: none;
  border: none;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical {
  border: 0px;
  padding: 0px;
  margin-bottom: 24px;
  background: none;
  border: none;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .top-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 29px;
  text-transform: uppercase;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile {
  margin-top: 15px;
  display: flex;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  img {
  margin-right: 30px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content {
  flex: 1 1;
  -ms-grid-row-align: center;
  align-self: center;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content
  .w-name {
  margin-bottom: 0px;
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content
  .w-name
  a {
  color: #31b3f6;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .writer-profile
  .writer-content
  p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .inner-content {
  margin-top: 70px;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .inner-content
  .title {
  font-size: 24px;
  font-weight: 700;
  color: #31b3f6;
  margin-bottom: 12px;
  margin-top: 36px;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .footer-content {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 46px;
  padding-top: 32px;
  text-align: center;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .footer-content
  .heading {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #9da1b9;
  margin-bottom: 6px;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .footer-content
  .emojis {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 0px;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .footer-content
  .emojis
  li {
  display: inline-block;
  margin-bottom: 0px;
}

.help-section.writter-artical-details
  .help-box
  .help-box-inner2
  .single-artical
  .footer-content
  .emojis
  li
  a {
  font-size: 32px;
  line-height: 42px;
  color: #e7b401;
  margin: 0px 7px;
  margin-bottom: 0px;
}

/* Help Section Area End */
/* Contact Area Start */
.contact {
  padding: 170px 0px 50px;
  background: #070b28;
  position: relative;
}

.contact .left-img {
  position: absolute;
  top: 375px;
  left: 100px;
  max-width: 520px;
}

.contact .section-heading {
  margin-bottom: 0px;
}

.contact .contact-form-wrapper .contact-box {
  position: relative;
  z-index: 9;
  background: #19254d;
  padding: 32px 30px 40px;
  box-shadow: 0px 0px 0px 10px rgba(25, 37, 77, 0.2),
    0px 0px 0px 20px rgba(25, 37, 77, 0.2),
    0px 0px 0px 30px rgba(25, 37, 77, 0.2);
}

.contact .contact-form-wrapper .contact-box .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: #fff;
  margin-bottom: 16px;
}

.contact .contact-form-wrapper .contact-box .input-field {
  width: 100%;
  height: 42px;
  border-radius: 5px;
  color: #a1aed4;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 22px;
}

.contact
  .contact-form-wrapper
  .contact-box
  .input-field::-webkit-input-placeholder {
  color: #a1aed4;
}

.contact .contact-form-wrapper .contact-box .input-field:-moz-placeholder {
  color: #a1aed4;
}

.contact .contact-form-wrapper .contact-box .input-field::-moz-placeholder {
  color: #a1aed4;
}

.contact .contact-form-wrapper .contact-box .input-field:-ms-input-placeholder {
  color: #a1aed4;
}

.contact .contact-form-wrapper .contact-box .input-field.textarea {
  height: 120px;
  resize: none;
}

.contact .contact-form-wrapper .contact-box .mybtn1 {
  margin-top: 11px;
}

/* Contact Area End */
/* 404 Area Start */
.four-zero-four {
  height: 100vh;
  display: flex;
  align-self: center;
  align-items: center;
  background: #070b28;
  position: relative;
}

.four-zero-four .bg-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.four-zero-four .content {
  text-align: center;
}

.four-zero-four .content img {
  display: inline-block;
  margin-bottom: 50px;
}

.four-zero-four .content .inner-content .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #dfe3ff;
}

.four-zero-four .content .inner-content .mybtn1 {
  margin-top: 38px;
  display: inline-block;
}

/* 404 Area End */
/* faq-section css start */
.faq-section {
  background: #0b122e;
  padding: 111px 0px 120px;
}

.faq-section .card {
  background: #1c294e !important;
}

.faq-section .section-heading {
  margin-bottom: 53px;
}

.faq-section .section-heading .subtitle {
  margin-bottom: 15px;
}

.faq-wrapper .nav-tabs {
  border: none;
  justify-content: center;
}

.faq-wrapper .tab-content {
  padding: 0 150px;
}

.faq-wrapper .nav-item .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  background: #1c294e;
  text-transform: uppercase;
  padding: 8px 30px;
  margin: 5px 10px;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.faq-wrapper .nav-item .nav-link.active {
  background: linear-gradient(to right, #ff344d, #d2273c);
}

.faq-wrapper .nav-item + .nav-item {
  margin-left: 0px;
}

.faq-wrapper .tab-content {
  margin-top: 37px;
}

.sorteo-accordion .card-header {
  padding: 0;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.sorteo-accordion .card-header .btn-link {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #1c294e;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 50px;
}

.sorteo-accordion .card-header .btn-link.collapsed::before {
  position: absolute;
  content: "\f107";
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  top: 15px;
  right: 30px;
  font-size: 22px;
  color: #85878f;
}

.sorteo-accordion .card-header .btn-link::before {
  position: absolute;
  content: "\f106";
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  top: 15px;
  right: 30px;
  color: #85878f;
}

.sorteo-accordion .card .card-header {
  border: none;
}

.sorteo-accordion .card-body {
  margin-top: 10px;
  padding: 20px 50px;
}

.sorteo-accordion .card-header .btn-link:hover,
.sorteo-accordion .card-header .btn-link:focus {
  text-decoration: none;
}

.sorteo-accordion .card-header .btn-link i {
  width: 40px;
  height: 40px;
  font-size: 24px;
  background: #31b3f6;
  box-shadow: -0.707px 0.707px 9.5px 0.5px rgba(6, 97, 144, 0.3);
  border-color: 1px solid rgba(198, 198, 198, 0.5);
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-right: 20px;
  border-radius: 50%;
}

.sorteo-accordion .card {
  border: none;
  box-shadow: 0px 0px 10px 0px rgba(51, 55, 69, 0.2);
  border-radius: 30px;
}

.accordion .card:not(:first-of-type):not(:last-of-type),
.accordion .card:last-of-type {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.accordion .card:first-of-type {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.sorteo-accordion .card + .card {
  margin-top: 15px;
}

/* faq-section css end */
/* login modal Area Start */
.login-modal .modal-dialog .modal-content {
  background: #252f5a;
  border-radius: 10px;
}

.login-modal .modal-dialog .modal-content .modal-body {
  padding: 0px;
}

.login-modal .modal-dialog .modal-content .modal-body .logo-area {
  display: block;
  text-align: center;
  position: relative;
}

.login-modal .modal-dialog .modal-content .modal-body .logo-area::after {
  position: absolute;
  content: "";
  width: 135px;
  height: 135px;
  border-radius: 50%;
  background: #252f5a;
  top: -65px;
  left: 050%;
  transform: translateX(-50%);
}

.login-modal .modal-dialog .modal-content .modal-body .logo-area img {
  display: inline-block;
  max-width: 100px;
  position: relative;
  z-index: 9;
  top: -4px;
}

.login-modal .modal-dialog .modal-content .modal-body .header-area {
  padding: 47px 30px 10px;
  position: relative;
  z-index: 9;
  text-align: center;
}

.login-modal .modal-dialog .modal-content .modal-body .header-area .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.login-modal .modal-dialog .modal-content .modal-body .header-area .sub-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

.login-modal .modal-dialog .modal-content .modal-body .form-area {
  padding: 0px 30px 10px;
}

.login-modal .modal-dialog .modal-content .modal-body .form-area .form-group {
  margin-bottom: 10px;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .form-group
  .box {
  display: flex;
  justify-content: space-between;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .form-group
  .box
  label {
  font-size: 16px;
  font-weight: 400;
  color: #a1aed4;
  text-transform: capitalize;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .form-group
  .box
  a {
  font-size: 16px;
  font-weight: 400;
  color: #a1aed4;
  text-transform: capitalize;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .form-group
  .check-group
  label {
  font-size: 14px;
  font-weight: 400;
  color: #a1aed4;
  text-transform: capitalize;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .form-group
  .check-group
  label
  a {
  color: #f9314b;
}

.login-modal .modal-dialog .modal-content .modal-body .form-area label {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 4px;
}

.login-modal .modal-dialog .modal-content .modal-body .form-area .input-field {
  width: 100%;
  border: 0px;
  height: 50px;
  font-size: 14px;
  color: #a1aed4;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 12px;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .input-field::-webkit-input-placeholder {
  color: #a1aed4;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .input-field:-moz-placeholder {
  color: #a1aed4;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .input-field::-moz-placeholder {
  color: #a1aed4;
}

.login-modal
  .modal-dialog
  .modal-content
  .modal-body
  .form-area
  .input-field:-ms-input-placeholder {
  color: #a1aed4;
}

.login-modal .modal-dialog .modal-content .modal-body .form-area select {
  margin-top: 8px;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  background: #273261;
  padding: 0px 30px;
  color: #fff;
  text-transform: uppercase;
}

.login-modal .modal-dialog .modal-content .modal-body .form-area .mybtn1 {
  margin-top: 22px;
  display: block;
  width: 100%;
}

.login-modal .modal-dialog .modal-content .modal-body .form-footer {
  text-align: center;
  padding: 0px 30px 6px;
}

.login-modal .modal-dialog .modal-content .modal-body .form-footer a {
  transition: all 0.3s ease-in;
  color: #fff;
}

.login-modal .modal-dialog .modal-content .modal-body .form-footer a i {
  font-size: 14px;
}

.login-modal .modal-dialog .modal-content .modal-body .form-footer a:hover {
  color: #f9314b;
}

.login-modal.sign-in .modal-body .form-area {
  padding-bottom: 18px !important;
}

.modal-dialog .modal-content {
  position: relative;
}

.modal-dialog .modal-content .close {
  position: absolute;
  right: 9px;
  z-index: 9;
}

.modal-dialog .modal-content .close span {
  color: #fff;
  font-size: 20px;
}

.binary-wrapper,
.ternary-wrapper {
  overflow: hidden;
  text-align: center;
}

.binary,
.ternary {
  margin: 25px 15px 25px 0;
  width: 110px;
  text-align: center;
  vertical-align: top;
  color: #ebe1ff;
  display: inline-block;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.binary-root,
.ternary-root {
  position: relative;
  z-index: 2;
  width: 90%;
  margin: 0 auto;
  border-radius: 50px;
  padding: 7px;
  font-weight: 700;
  display: block;
  box-shadow: 0 2px 5px #4e3aa7;
}

.ternary {
}

.ternary-root {
}

.ternary-children {
  margin-top: 20px;
}

.ternary-children > div {
  position: relative;
  border-radius: 35%;
  display: inline-block;
  width: 20px;
  height: 20px;
  z-index: 2;
  border: 1px solid #252f5a;
}

.ternary-children > div a {
  padding: 5px;
  display: inline-block;
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
}

.ternary-children div:nth-child(2) {
  margin: auto 15px;
}

.ternary-branchs {
  margin: 0 auto;
  position: relative;
  width: 1px;
  height: 1px;
}

.ternary-branchs > div {
  border-left: 1px dashed rgba(255, 255, 255, 0.5);
  width: 1px;
  height: 20px;
  position: absolute;
  z-index: 1;
}

.ternary-branchs > div:nth-child(1) {
  top: -46px;
  left: -36px;
  transform: rotate(2deg);
}

.ternary-branchs > div:nth-child(2) {
  top: -47px;
  right: 1px;
}

.ternary-branchs > div:nth-child(3) {
  top: -47px;
  right: -34px;
  transform: rotate(-2deg);
}

.binary {
}

.binary-root {
}

.binary-children {
}

.binary-children > div {
  position: relative;
  border-radius: 35%;
  display: inline-block;
  width: 17px;
  height: 17px;
  z-index: 2;
  border: 1px solid #252f5a;
}

.binary-children > div a {
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  padding: 5px;
  width: inherit;
  height: inherit;
}

.binary-children_level__1 {
  margin-top: 11px;
  height: 28px;
}

.binary-children_level__1 div:nth-child(1) {
  margin-right: 17px;
}

.binary-children_level__1 div:nth-child(2) {
  margin-left: 17px;
}

.binary-children_level__2 div:nth-child(1) {
}

.binary-children_level__2 div:nth-child(2) {
  margin: 0 5px;
}

.binary-children_level__2 div:nth-child(3) {
  margin: 0 5px;
}

.binary-children_level__2 div:nth-child(4) {
}

.binary-children_level__2 {
  height: 32px;
}

.binary-children_level__2 > div {
}

.binary-branchs {
  margin: 0 auto;
  position: relative;
  width: 1px;
  height: 1px;
}

.binary-branchs > div {
  border-left: 1px dashed rgba(255, 255, 255, 0.6);
  width: 1px;
  height: 15px;
  position: absolute;
  top: -90px;
  z-index: 1;
}

.binary-branchs > div:nth-child(1) {
  top: -71px;
  left: -26px;
  transform: rotate(2deg);
}

.binary-branchs > div:nth-child(2) {
  top: -71px;
  right: -25px;
  transform: rotate(-2deg);
}

.binary-branchs > div:nth-child(3) {
  top: -43px;
  left: -35px;
  transform: rotate(29deg);
}

.binary-branchs > div:nth-child(4) {
  top: -43px;
  left: -18px;
  transform: rotate(-26deg);
}

.binary-branchs > div:nth-child(5) {
  top: -43px;
  left: 17px;
  transform: rotate(29deg);
}

.binary-branchs > div:nth-child(6) {
  top: -43px;
  right: -32px;
  transform: rotate(-31deg);
}

.matrix-root__active {
  background-color: var(--color-lightblue);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border: 1px solid #eb1436;
  background: #eb1436;
}

.matrix-root__active:hover {
  opacity: 0.9;
  background: #d9293f;
  border: 0;
  border: 1px solid #eb1436;
}

.matrix-root__nonactive {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid #252f5a;
  background: #182044;
}

.matrix-root__nonactive:hover {
  opacity: 1;
  background: #252f5a;
}

.matrix-children__nonactive {
  background-color: transparent;
  border: 1px solid var(--color-darkblue);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.red {
  color: #eb1436;
}

.green {
  color: #6efcc3;
}

.blue {
  color: #9de1fe;
}

.matrix-children__active {
  background-color: var(--color-lightblue);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.matrix-children__overflow {
  background-color: var(--color-whiteblue);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.matrix-children__overflow_partner {
  background-color: var(--color-blue);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.matrix-children__advance {
  background-color: var(--color-lilac);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.matrix-children__active:hover,
.matrix-children__overflow:hover,
.matrix-children__overflow_partner:hover,
.matrix-children__advance:hover {
  opacity: 0.9;
  z-index: 3;
}

.matrix-level {
  position: absolute;
  top: 3px;
  left: 12px;
  padding: 0;
  font-size: 13pt;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
}

.matrix-level__active {
  color: #005c99;
}

.matrix-level__nonactive {
  color: #777;
}

.matrix-price {
  font-size: 13pt;
  font-weight: 600;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  color: #f9f9f9;
  text-shadow: var(--text-shadow-black);
}

.matrix-info {
  line-height: 15px;
}

.matrix-info > div:first-child {
  margin-right: 3%;
}

.matrix-info > div {
  display: inline-block;
}

.matrix-break {
  margin: 10px auto;
}

.matrix_reinvest,
.matrix_partners__count {
  font-size: 12pt;
  font-weight: 600;
  color: #aebdd4;
}

.matrix-icon_cart {
}

.matrix-icon_cart__big {
  cursor: pointer;
  position: absolute;
  right: -17px;
  top: -17px;
  z-index: 60;
  display: block;
  width: 50px;
  height: 50px;
  /* background: url(../img/cartbig.svg) no-repeat */
}

.matrix-icon_cart__big:hover {
  transform: scale(1.4);
  transition: 0.35s;
}

.matrix-icon_cart__small:after {
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 35;
  content: "";
  display: block;
  width: 23px;
  height: 23px;
  /* background: url(../img/cartsmall.svg) no-repeat */
}

.matrix-locked .ternary-root,
.matrix-locked div[class*="matrix-children"] {
}

.matrix-locked .ternary-branchs {
}

.matrix-locked .binary-root {
}

.matrix-single {
  position: relative;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.matrix-single.fa-gift {
  color: var(--color-pink);
}

.matrix-single.fa-sync-alt {
  color: var(--color-green);
}

.matrix-single.fa-minus-circle {
  color: var(--color-red);
}

.matrix-icon_lost,
.matrix-icon_sync,
.matrix-icon_users {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  display: inline-block;
  background-size: contain;
}

.matrix-icon_upline-profit {
}

.matrix-icon_upline-profit img {
  max-width: 25px;
  position: absolute;
  right: -15px;
  bottom: 25px;
}

.level-locked {
  position: absolute;
  right: 4px;
  top: 3px;
  font-weight: 600;
  cursor: help;
  color: #eee;
}

.level-locked img {
  width: 18px;
}

.level-locked i {
  font-style: normal;
}

.matrix-partner-leading {
  position: absolute;
  left: 42px;
  top: -14px;
  font-weight: 600;
  cursor: help;
  color: #eee;
}

.matrix-partner-leading img {
  width: 21px;
}

.user-matrx_root__partner-leading {
  position: absolute;
  left: 92px;
  top: -16px;
  font-weight: 600;
  cursor: help;
  color: #eee;
}

.user-matrx_root__partner-leading img {
  width: 50px;
}

.user_id {
  text-align: right;
  font-size: 22pt;
  font-weight: 700;
  border-radius: 6px;
  box-shadow: inset var(--box-shadow-black);
  width: 220px;
  margin: 25px auto 0;
}

.user_id a {
  padding: 8px 12px;
  display: inline-block;
  width: inherit;
  height: inherit;
  color: #252f5a !important;
  text-align: left;
}

.user_id a:hover {
  opacity: 0.8;
  text-align: left;
}

.user_id i {
  margin-top: -5px;
}

.user-matrix_root {
  position: relative;
  box-shadow: var(--box-shadow-black);
  border-radius: 12px;
  width: 220px;
  height: 110px;
  margin: auto;
  z-index: 10;
  cursor: default;
}

.user-matrix_root__active {
  background-color: #fb334c;
}

.user-matrix_root__disabled {
  background-color: var(--color-darkblue);
}

.user-matrix_root__nonactive {
  background-color: #4e3aa7;
}

.user-matrix_root__active .user-matrix__nonactive {
  display: none;
}

.user-matrix_root__nonactive .user-matrix__active {
  display: none;
}

.user-matrix_root__disabled .user-matrix__active {
  display: none;
}

.user-matrix_cart {
  position: absolute;
  top: 15px;
  right: -70px;
  z-index: 10;
  cursor: pointer;
}

.user-matrix_cart img {
  width: 100px;
}

.user-matrix_cart img:hover {
  transform: scale(1.25) rotate(5deg);
  transition: 0.4s;
}

.user-matrix_root__disabled .user-matrix_cart {
  display: none;
}

.user-matrix_level {
  position: absolute;
  left: 15px;
  top: 0px;
  font-size: 38pt;
  font-weight: 700;
  color: #fff;
}

.user-matrix_partners i {
  margin-right: 10px;
}

.user-matrix_reinvest i {
  margin-left: 10px;
}

.section-title {
  text-transform: capitalize;
}

.user-matrix_id {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 23pt;
  font-weight: 600;
}

.user-matrix_id a {
  color: #fff !important;
  text-shadow: var(--text-shadow-black);
}

.user-matrix_id a:hover {
}

.user-matrix_price {
  white-space: nowrap;
  box-shadow: var(--box-shadow-black);
  position: absolute;
  right: -25px;
  bottom: 15px;
  border-radius: 3px;
  background: var(--color-darkblue);
  padding: 2px 25px;
  min-width: auto;
  text-align: left;
  line-height: 25px;
  color: #f9f9f9;
  font-weight: 600;
  text-shadow: var(--text-shadow-black);
  width: 100%;
}

.user-matrix_price__dollars {
  font-size: 12pt;
  float: left;
  margin-right: 25px;
}

.user-matrix_price__eth {
  font-size: 14pt;
}

.user-matrix_price__eth:after {
  content: "eth";
  margin-left: 3px;
  font-size: 11pt;
}

.user-matrix__nonactive .user-matrix_price {
  bottom: 15px;
  padding: 15px 20px;
  min-width: 150px;
  background: var(--color-darkblue1);
  text-align: left;
  font-size: 17pt;
}

.user-matrix__nonactive .user-matrix_price__eth:after {
  display: none;
}

.user-profit__lost,
.user-profit__gift {
  color: var(--color-white) !important;
}

.user-profit__lost {
}

.user-profit__lost i {
  width: 20px;
  height: 20px;
  color: var(--color-red);
}

.user-profit__gift {
  color: var(--color-pink);
  margin-bottom: 10px;
}

.user-profit__gift i {
  margin-top: -2px;
  font-size: inherit !important;
  color: var(--color-pink);
}

.user-matrix_reinvest,
.user-matrix_partners {
  position: absolute;
  bottom: -45px;
  font-size: 15pt;
  font-weight: 600;
  color: var(--color-white);
  white-space: nowrap;
}

.user-matrix_reinvest i,
.user-matrix_partners i {
  position: relative;
  width: 20px;
  height: 20px;
}

.user-matrix_reinvest {
  right: 200px;
  cursor: pointer;
}

.user-matrix_reinvest__nav {
  position: absolute;
  left: 0;
  right: 0;
}

.user-matrix_reinvest__nav a {
  margin-left: 20px;
}

.user-matrix_reinvest__nav a > i {
  font-size: 27pt !important;
}

.user-matrix_reinvest__nav-prev,
.user-matrix_reinvest__nav-next {
  position: absolute;
}

.user-matrix_reinvest__nav-prev {
  top: 20px;
}

.user-matrix_reinvest__nav-next {
  bottom: 10px;
}

.user-matrix_reinvest__tip {
  margin-right: 5px;
  font-size: 10pt;
  color: var(--color-pink);
  vertical-align: middle;
}

.user-matrix_reinvest__popup {
  position: absolute;
  cursor: default;
  display: none;
}

.user-matrix_reinvest__popup select {
  min-width: 80px;
}

.user-matrix_partners {
  left: 200px;
}

.user-matrix_partners a {
  margin-right: 10px;
}

.user-matrix_ternary {
  min-width: 250px;
}

.user-matrix__branchs {
  position: relative;
  margin: auto;
  width: 0;
}

.user-matrix__branchs div {
  position: absolute;
  z-index: 1;
  border-left: 2px dashed rgba(255, 255, 255, 0.3);
}

.user-matrix_children {
  text-align: center;
}

.user-matrix_children div {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}

.user-matrix_ternary .user-matrix__branchs {
  height: 73px;
}

.user-matrix_ternary .user-matrix__branchs div {
  height: 100px;
}

.user-matrix_ternary .user-matrix__branchs div:nth-child(1) {
  top: -10px;
  left: -45px;
  transform: rotate(40deg);
}

.user-matrix_ternary .user-matrix__branchs div:nth-child(2) {
  top: -27px;
}

.user-matrix_ternary .user-matrix__branchs div:nth-child(3) {
  top: -10px;
  right: -45px;
  transform: rotate(-40deg);
}

.user-matrix_ternary .user-matrix_children {
  padding-bottom: 25px;
}

.user-matrix_ternary .user-matrix_children > div:nth-child(2) {
  margin: auto 30px;
}

.user-matrix_binary .user-matrix__branchs {
  height: 60px;
}

.user-matrix_binary .user-matrix__branchs div {
  height: 80px;
}

.user-matrix_binary .user-matrix__branchs div:nth-child(1) {
  top: -13px;
  left: -65px;
  transform: rotate(25deg);
}

.user-matrix_binary .user-matrix__branchs div:nth-child(2) {
  top: -13px;
  right: -65px;
  transform: rotate(-25deg);
}

.user-matrix_binary .user-matrix__branchs div:nth-child(3) {
  top: 115px;
  left: -115px;
  height: 28px;
  transform: rotate(35deg);
}

.user-matrix_binary .user-matrix__branchs div:nth-child(4) {
  top: 115px;
  left: -75px;
  height: 28px;
  transform: rotate(-35deg);
}

.user-matrix_binary .user-matrix__branchs div:nth-child(5) {
  top: 116px;
  left: 72px;
  height: 27px;
  transform: rotate(30deg);
}

.user-matrix_binary .user-matrix__branchs div:nth-child(6) {
  top: 115px;
  left: 115px;
  height: 28px;
  transform: rotate(-35deg);
}

.user-matrix_binary .user-matrix_children > div:nth-child(2) {
}

.user-matrix_children__level-1 > div:nth-child(1) {
  margin-right: 60px;
}

.user-matrix_children__level-1 > div:nth-child(2) {
  margin-left: 60px;
}

.user-matrix_children__level-2 > div:nth-child(1) {
}

.user-matrix_children__level-2 > div:nth-child(2) {
  margin-left: 15px;
  margin-right: 25px;
}

.user-matrix_children__level-2 > div:nth-child(3) {
  margin-right: 15px;
  margin-left: 25px;
}

.user-matrix_children__level-2 > div:nth-child(4) {
}

.user-matrix_children__level-2 {
  margin-top: 10px;
}

.user-matrix_children_count {
  height: 60px;
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 60px;
  text-align: center;
  font-weight: 600;
  font-size: 15pt;
  border: 1px solid #252f5a;
  border-radius: 15px;
  background: #0b122e;
  z-index: 9;
}

.user-matrix_children_count a {
  color: var(--color-darkblue1) !important;
}

.user-matrix_partners__count {
  position: absolute;
  bottom: -30px;
  left: -10px;
  display: none;
  width: 80px;
  display: inline-block;
  font-size: 12pt;
  color: #eee;
  font-weight: 600;
}

.user-matrix_wrapper_popup:hover .user-matrix_popup {
  display: block;
}

.user-matrix_popup {
  position: absolute;
  left: 45px;
  bottom: -25px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.85);
  padding: 4px 13px;
  border-radius: 3px;
  color: #fff;
  font-size: 11pt;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  display: none;
}

.user-matrix_popup > span {
  display: block;
  white-space: nowrap;
}

.btn-nav {
  padding-right: 50px;
  padding-left: 50px;
  position: relative;
}

.btn-nav a {
  color: var(--color-lightblue);
}

.btn-nav a > i {
  font-size: 20pt;
  vertical-align: middle;
}

.btn-nav_prev,
.btn-nav_next {
  position: absolute;
  top: 44%;
  z-index: 20;
}

.btn-nav_prev a:hover,
.btn-nav_next a:hover {
  opacity: 0.8;
}

.btn-nav_prev {
  left: 10px;
}

.btn-nav_next {
  right: 10px;
}

.btn-nav_page {
  padding: 5px 11px;
  margin-left: 5px;
  margin-right: 5px;
  color: var(--color-darkblue1);
  min-width: 50px;
  text-align: center;
  font-size: 16pt;
  border-radius: 4px;
  display: inline-block;
  cursor: default;
  vertical-align: middle;
  font-weight: 600;
}

.btn-nav_page {
  background: var(--color-darkblue);
}

.user-transactions {
  margin-top: 25px;
  font-size: 85%;
  color: var(--color-white);
}

.user-transactions__head {
  color: #fff;
  font-weight: 600;
}

.user-transactions__body {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-transactions__body > div > div {
  padding: 3px 5px;
  white-space: nowrap;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.user-transactions a {
}

.user-transactions__head div.icon {
  font-size: 90%;
}

.user-transactions__head div.date {
}

.user-transactions__head div.user-id {
  text-align: center;
}

.user-transactions__head div.user-address {
}

.user-transactions__head div.profit {
  text-align: center;
}

.user-transactions__body div.icon {
  text-align: left;
  padding-left: 15px;
  position: relative;
}

.user-transactions__body span.icon-reinvest {
  position: absolute;
  top: -10px;
  left: 19px;
  z-index: 1;
  font-size: 65%;
  color: #fff;
  background: #8bc24a;
  padding: 3px 5px;
  border-radius: 2px;
  line-height: 10px;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  cursor: default;
}

.user-transactions__body div.date {
}

.user-transactions__body div.user-id {
  text-align: center;
}

.user-transactions__body div.user-address {
}

.user-transactions__body div.profit {
  text-align: center;
}

.user-transactions__body span.coin {
}

.user-transactions__body span.fiat {
  font-size: 90%;
}

.user-transactions__body i.icon {
  color: #acb3fb;
  margin: 0 2px;
}

.user-transactions__body span.fiat {
  font-size: 90%;
}

.user-transactions__body i.icon {
  color: #acb3fb;
  margin: 0 2px;
}

@media (max-width: 770px) {
  .user-transactions__body div.date {
    white-space: normal;
  }
}

.user-transactions_load {
  margin-top: 5px;
}

.user-transactions_load a {
  background: rgba(0, 0, 0, 0.15);
  padding: 2px;
  text-align: center;
  display: block;
}

.user-transactions_load a:hover {
  background: rgba(0, 0, 0, 0.2);
}

.user-transactions_load a > i {
  vertical-align: middle;
  color: #eee;
}

.transactions {
  min-width: 600px;
  white-space: nowrap;
  color: var(--color-white);
  font-size: 12pt;
  text-align: center;
  font-weight: 500;
  margin-right: auto;
  margin-left: auto;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.transactions .row {
  margin-bottom: 10px;
  margin-right: 5px;
}

.transactions-thead {
  min-width: 590px;
  color: var(--color-white);
  margin: 7% auto 15px;
}

.transactions-thead > div {
  border-bottom: 1px solid #acb3fb;
  padding-bottom: 10px;
}

.transactions-thead > div:first-child {
  border-bottom: 0;
}

.transactions a {
  color: var(--color-white);
}

.transactions a:hover {
  color: #fff;
}

.transactions .active-reinvest {
  background: #277fae;
}

.user-matrix_children div {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}

.table td,
.table th {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 14px;
}

.help-section {
  padding-top: 170px;
}

.package_x3 {
  margin-top: 30px;
}

.package_x3 .ternary-wrapper {
  border: 1px solid #273b71;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.package_x4 {
  margin-top: 30px;
}

.package_x4 .binary-wrapper {
  border-radius: 10px;
  border: 1px solid #273b71;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.matrix_partners__count span {
  margin: 0 8px;
}

.single-winer p {
  margin-bottom: 0;
}

.ether_display {
  margin-top: 30px;
}

.ether_display .content {
  padding: 0 !important;
}

.ether_display .content .number {
  font-size: 14px !important;
}

.ether_display .content .info-box {
  padding: 15px 20px 15px !important;
  margin-top: 30px !important;
}

.ether_display .content .title {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.ether_display .buy-tickets-box {
  background: transparent !important;
}

.package_x3 h3 {
  text-align: left;
  margin-top: 20px;
  margin-left: 40px;
}

.package_x4 h3 {
  text-align: left;
  margin-top: 20px;
  margin-left: 40px;
}

.ether_display .mybtn1 {
  padding: 4px 5px;
  font-size: 11px;
  margin-top: 10px !important;
  width: 100%;
}

.user-matrix_partners i {
  font-size: 13pt !important;
}

.user-matrix_reinvest__tip .fa-sync-alt {
  margin-right: 5px;
  font-size: 12pt;
}

.user-matrix_popup i {
  margin-right: 10px;
}

.user-matrix_partners__count i {
  margin-right: 10px;
}

.user-matrix_reinvest i {
  margin-right: 10px;
}

.detail_page {
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 10px 15px 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
  margin-top: 30px;
}

.user-transactions {
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 10px 15px 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
}

.cart-table-area {
  background: #0e1833;
  border: 1px solid #273b71;
  border-radius: 8px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  padding: 20px;
}

.filter input,
.filter select {
  width: 100%;
  height: 36px;
  color: #a1aed4;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 22px;
  padding: 0 10px;
  border-radius: 4px;
}

.apply_filter .mybtn1 {
  padding: 5px 25px;
}

@media (max-width: 991px) {
  .ether_display {
    padding-bottom: 0;
  }

  .top-header .left-content {
    float: left;
    margin-bottom: 0 !important;
  }

  .top-header .right-content {
    float: right;
  }

  .help-section {
    padding-top: 130px;
  }

  .single-winer {
    padding: 10px 15px 10px !important;
  }

  .user-transactions__body {
    zoom: 0.5;
  }

  .user-transactions__head {
    zoom: 0.5;
  }
}

@media (max-width: 568px) {
  .breadcrumb-area.bc-lottery {
    padding: 120px 0px 70px !important;
  }

  .lottery-area .daily-lottery {
    padding: 25px 0px 0px !important;
  }

  .how-it-work {
    padding: 0px 0px 100px !important;
  }

  .features.about-page {
    padding: 112px 0px 40px !important;
  }

  .faq-section {
    padding: 50px 0px 50px !important;
  }

  .footer {
    padding-top: 50px !important;
  }

  .features.about-page {
    border-top: 0 !important;
  }

  .breadcrumb-area h3 {
    font-size: 20px;
  }

  .ether_display .content .info-box {
    margin-top: 15px !important;
  }

  .single-winer {
    margin-bottom: 15px !important;
  }

  .contact {
    padding: 90px 0px 20px !important;
  }

  .contact-form-wrapper table {
    zoom: 0.5;
  }
}

.btn-primary {
  background: linear-gradient(65deg, #dc3545, #0f8afd);
  width: 150px;
  height: 42px;
  color: #fff;
}

.btn.btn-primary {
  border-radius: 12px;
}

.signup-btn.btn {
  border-radius: 12px;
}

.btn-primary:hover {
  background: #0f8afd;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px 0px;
}

/* login modal Area End */

.circle1 {
  height: 50px;
  width: 54px;
  background-color: #5561cd;
  border-radius: 50%;
  display: block;
  box-shadow: 0px 4px 4px #5561cd;
  position: relative;
  margin-top: 25px;
  margin-right: 15px;
}

.circle2 {
  height: 40px;
  width: 44px;
  background-color: #ee4545;
  border-radius: 50%;
  display: block;
  box-shadow: 0px 4px 4px #ee4545;
  position: relative;
  margin-top: -75px;
  margin-left: 25px;
}

.outercircle {
  position: absolute;
  right: 5px;
  top: 5px;
}

.coinnumber {
  color: #ffffff;
  line-height: 45px;
}

.coinnumber1 {
  color: #ffffff;
  line-height: 56px;
}

.spacingleft {
  margin-left: 15px;
}

.bgcol {
  background: linear-gradient(65deg, #dc3545, #0f8afd);
}

/* datetimepicker css */

.react-datetime-picker {
  display: inline-flex;
  position: relative;
}

.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  box-sizing: border-box;
}

.react-datetime-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid #ced4da !important;
  border-radius: 12px;
  height: 40px !important;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 10px !important;
}

.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 2px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.94em);
}

.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}

.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__button:enabled:hover
  .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus
  .react-datetime-picker__button__icon {
  stroke: #0078d7;
}

.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}

.react-datetime-picker__button svg {
  display: inherit;
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
  display: none;
}

.react-datetime-picker__calendar {
  width: 350px;
  max-width: 100vw;
}

.react-datetime-picker__calendar .react-calendar {
  border-width: thin;
}

.react-datetime-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: 1px solid #ced4da;
}

#validationCustom01.form-control {
  border-radius: 12px;
  font-size: 13px;
}

.textarea-input #validationCustom01.form-control {
  padding-top: 8px;
  padding-bottom: 8px;
}

input.form-control {
  border-radius: 12px;
  height: 38px;
}

label {
  font-weight: 600;
}

/* sidebar fororganization */
.sidebar {
  margin: 0;
  padding: 0;
  width: 230px;
  background-color: #edeef0;
  position: fixed;
  height: 100%;
  overflow: auto;
  top: 0;
}

/* .sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
 
.sidebar a.active {
  background-color: #04AA6D;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}  */

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar a {
    float: left;
  }

  .mspace {
    margin-left: 0px !important;
  }

  .mspace1 {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.mspace {
  margin-left: 200px;
  padding: 1px 16px;
  /* height: 1000px; */
}

.mspace1 {
  margin-left: 200px;
  padding: 1px 16px;
  height: auto;
}

@media screen and (max-width: 992px) {
  .ccard {
    margin-bottom: 100px !important;
  }
}

.addr {
  margin-top: 8px;
  font-weight: 700;
}

.txttransform {
  text-transform: uppercase;
}

.card-header {
  background-color: #ffffff !important;
  box-shadow: 0 4px 6px -6px rgba(0, 0, 0, 0.155) !important;
}

.adspace {
  margin-left: 25px;
}

.lottery-details .sidebars {
  margin-right: 20px;
}

.lottery-details .address-text {
  word-break: break-word;
}

.lottery-details .winner-address {
  text-align: center;
  word-break: break-word;
}

.lottery-details .numbercirclediv {
  padding-bottom: 0px;
}

.lottery-details .numbercirclediv .numbercircle {
  margin-bottom: 12px;
}

.mylotteryList {
  margin: 16px;
}

@media (max-width: 700px) {
  .adspace {
    margin-left: 0px;
  }
  .lottery-details .sidebars {
    margin-left: 0px !important;
    margin-right: 0px;
  }
  .mylotteryList,
  .adminlotteryList {
    margin: 16px 0px !important;
  }
}

.react-datetime-picker__inputGroup {
  font-size: 15px;
}

.orgscreendiv {
  min-height: 85vh;
}

.warningbtn {
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.date-input .react-datetime-picker__wrapper {
  height: 38px !important;
}

.date-input .react-datetime-picker__wrapper .react-datetime-picker__inputGroup {
  font-size: 13px !important;
}

.register-date .date-input span {
  font-size: 13px;
}

.register-date .react-datetime-picker,
.create-date .react-datetime-picker {
  width: 100%;
}

.adminHomeBtn {
  text-align: right;
}

.CreateLotteryForm .form-control {
  height: 38px;
}

.Autobet-MRL .card-inner {
  position: relative;
}

.Autobet-MRL .card-inner .partnerlogo {
  height: 35px;
  max-width: 150px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.lottery-tabs {
  border-bottom: 0px !important;
  background-color: #edeef0;
  border-radius: 0.25rem;
}

.lottery-tabs .nav-link.active {
  border: 0px;
  color: #ffffff !important;
  background-color: #6c757d !important;
}

.lottery-tabs .nav-link {
  font-weight: 400;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #495057;
  border: 0px !important;
  cursor: pointer;
  margin: 0px !important;
}
.lottery-tabs .nav-link:hover {
  border: 0px;
}

.lotterylistcard {
  margin-bottom: 30px;
}

.lotterylistcard .overview-item {
  height: 100%;
}

.bookieslist {
  padding-top: 18px;
}

.bottom-section .btn-primary:disabled {
  cursor: not-allowed !important;
}

.warning-text {
  max-width: 720px;
  margin: auto;
}

.warning-logo {
  width: 150px;
}

.was-validated .form-control.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url(/static/media/error-icon.abdd189f.svg) !important;
}

.form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

@media screen and (max-width: 768px) {
  .draw-date .react-datetime-picker {
    width: 100%;
  }

  .warning-text {
    font-size: 24px;
    padding: 0px 10px;
  }

  .warning-logo {
    width: 120px;
  }
}

.missile-series {
  margin-bottom: 12px;
  margin-left: 10px;
}

@media screen and (max-width: 576px) {
  .adminHomeBtn {
    text-align: left;
    margin-top: 10px;
  }

  .Autobet-MRL .card-inner .partnerlogo {
    position: unset;
    margin-bottom: 8px;
  }

  .warning-text {
    padding: 0px;
  }
}

.paginationsection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paginationallignment {
  display: inline-block;
}

.pagination {
  margin-bottom: 0px;
}

.pagination > li {
  display: inline;
  margin-left: 8px;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  text-decoration: none;
  margin-left: -1px;
  font-size: 14px;
  font-weight: 700;
  background: var(--white);
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border: 1px solid #dfe3e8;
  box-sizing: border-box;
  border-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border: 1px solid #dfe3e8;
  box-sizing: border-box;
  border-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  background: var(--white);
  border: 1px solid #f33f48;
  box-sizing: border-box;
  color: #f33f48;
  border-radius: 4px;
  cursor: pointer;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  box-sizing: border-box;
  border-radius: 4px;
  cursor: not-allowed;
  border: 1px solid #919eab;
  background: #919eab;
  opacity: 0.5;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.paginationicon path {
  fill: #c4cdd5;
}

@media screen and (max-width: 576px) {
  .paginationsection {
    flex-direction: column;
  }

  .noofpagetxt {
    margin-bottom: 10px;
  }

  .container-p0{
    padding: 0px !important;
  }

  .howitworks .how-works-card{
    padding: 10px 0px !important;
  }
}

.notificationList .notify-row {
  padding-left: 24px !important;
}

.copy-icon {
  min-width: 20px;
  min-height: 20px;
}

.ref-link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.org-home-top {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .notificationList .notify-row {
    padding-left: 0px !important;
  }

  .org-home-top {
    flex-wrap: wrap;
  }

  .org-withdraw-btn {
    margin-top: 20px;
  }
}



.whyus .whyus-card{
  flex-direction: row;
    align-items: center;
    padding: 15px;
    border: 0px;
    background: #FAFAFA;
    border-radius: 10px;
    height: 100%;
}

.whyus .whyus-card .title{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 6px;
  line-height: 30px;
}

.whyus .whyus-card .desc{
  font-size: 18px;
  line-height: 26px;
}

.whyus .whyus-card .cardImg{
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.whyus .react-reveal{
  height: 100%;
}

.whyus .whyus-col{
  margin-bottom: 40px;
}

.howitworks .how-works-card{
  flex-direction: row;
    align-items: center;
    padding: 15px;
    border: 0px;
    height: 100%;
    background: transparent;
    margin-bottom: 15px;
}

.howitworks .how-works-card .title{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 6px;
  line-height: 30px;
}

.howitworks .how-works-card .desc{
  font-size: 18px;
  line-height: 26px;
}

.howitworks .how-works-card .cardImg{
  width: 80px;
  height: 80px;
  margin: 0px 20px 0px 10px;
}

.howitworks .howitworks-img{
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

@media screen and (max-width: 991px) {
  .howitworks .section-title{
    padding-bottom: 0px !important;
  }

  .whyus .whyus-row{
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .howitworks .howitworks-col{
    padding: 0px;
  }

  .howitworks .how-works-card .cardImg{
    margin-left: 0px;
  }

  .howitworks .howitworks-img{
    min-height: 350px;
    margin-bottom: 15px;
  }
}

#partner .section-title{
  font-size: 32px;
  font-weight: 600;
}

#partner .section-desc{
  font-size: 18px;
  line-height: 28px;
}


.red-text-color{
  color: #ea2e49;
  line-height: unset;
  font-size: unset;
}

.roadmap .roadmap-desc{
font-size: 18px;
line-height: 26px;
margin-top: 6px;
}

.roadmap .roadmap-title{
font-size: 22px;
line-height: 30px;
}
.footer-bottom {
  position: absolute;
  width: 100%;
  border-top: 1px solid #edeef0;
  padding-top: 10px;
}
.copy-right-text p a {
  color: #ea2e49;
}
.copy-right-text p {
  color: #454545;
}
.footer-social-links li + li {
  margin-left: 15px;
}
.footer-social-links li a {
  width: 15px;
  height: 25px;
  /* background-color: rgba(51, 181, 247, 0.1); */
  /* border: 1px solid rgba(51, 181, 247, 0.1); */
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: block;
  color: #000000;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.footer-social-links li a:hover {
  /* color: #c2c4c8; */
  color: #ea2e49;
  background-color: transparent;
  /* border-color: rgb(79, 128, 225); */
}
.footer-top-second {
  margin-top: 50px;
}

.footer-widget .widget-title {
  margin-bottom: 20px;
  text-transform: capitalize;
}
.footer-list-menu li + li {
  margin-top: 5px;
}
.footer-list-menu li a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  position: relative;
  padding-left: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.footer-list-menu li a::before {
  position: absolute;
  content: "\f105";
  font-family: "Roboto", sans-serif;
  top: 0;
  left: 0;
  color: #333745;
  opacity: 0.8;
}
.footer-list-menu li a:hover {
  color: #ea2e49;
  margin-left: 10px;
}
.footer-list-menu li a:hover::before {
  color: #ea2e49;
}
.subscribe-form {
  display: flex;
  margin-top: 30px;
}
.subscribe-form input {
  padding: 10px 25px;
}
.subscribe-form input[type="email"] {
  width: 70%;
  border: 1px solid #e5e5e5;
  border-radius: 999px 0 0 999px;
  -webkit-border-radius: 999px 0 0 999px;
  -moz-border-radius: 999px 0 0 999px;
  -ms-border-radius: 999px 0 0 999px;
  -o-border-radius: 999px 0 0 999px;
}
.subscribe-form input[type="submit"] {
  width: 30%;
  border: 1px solid #ea2e49;
  background-color: #ea2e49;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0 999px 999px 0;
  -webkit-border-radius: 0 999px 999px 0;
  -moz-border-radius: 0 999px 999px 0;
  -ms-border-radius: 0 999px 999px 0;
  -o-border-radius: 0 999px 999px 0;
}

dl,
ol,
ul {
margin-bottom: 0px;
}

.footer-bottom .contact-section svg{
  stroke-width: 0px;
  margin-right: 8px;
  margin-top: 2px;
  height: 17px;
  min-width: 17px;
}

.footer-bottom .contact-section .details{
  display: flex;
  margin-bottom: 8px;
}

.footer-bottom .contact-section .details span {
  font-size: 16px;
  line-height: 20px;
  color: #454545;
  word-break: break-all;
}

.footer-bottom .contact-section .details .address{
  word-break: break-word;
}

.footer-bottom .redirect-section p{
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

.footer-bottom .copyrights{
background: #F1F8FF;
text-align: center;
padding: 8px 0px;
}

.footer-bottom .copyright-section p{
  font-size: 14px;
}

.footer-bottom .footer-section{
  padding: 8px 10px;
}

.footer-bottom .footer-icon .tagLine{
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.footer-bottom .foot-head{
  font-weight: 700;
}

.footer-bottom .footer-icon{
  display: flex;
  align-items: center;
}

.footer-bottom .footer-icon .imagee{
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 5px;
}

.footer-bottom .footer-icon .autobetLg{
  color: rgba(0,0,0,0.5);
  font-size: 20px;
  font-weight: bold;
}

.footer-bottom .social-section{
  text-align: center;
}

.admin-footer, .creator-footer{
  margin-left: 230px;
  width: calc(100% - 230px);
}

@media screen and (max-width: 700px) {
  .admin-footer, .creator-footer{
    margin-left: 0px;
    width: 100%;
  }
}

@media  screen and (max-width: 991px) {
  .footer-bottom .footer-section .footer-div .footer-col{
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 768px) {
  .footer-bottom .social-section{
    text-align: left;
  }
  .footer-div .footer-social-links{
    justify-content: left !important;
  }
}


@media only screen and (max-width: 576px){
  .footerTextMedia{
    font-size: 13px;
  }

  .footer-bottom .footer-section{
    padding: 8px 0px;
  }
  /* .footer-social-links li{
    margin-left: 10px !important;
  } */
  /* .footer-div{
    flex-direction: column-reverse;
    text-align: center;
  } */
}

.result-item {
    box-shadow:0 0 5px 2px  rgba(241 0 38 / 36%);
    overflow: hidden;
    margin: 15px 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }
  .result-item:hover {
    box-shadow: 1.5px 2.598px 17.22px 3.78px rgba(241 0 38 / 36%)
    
  }
  .result-item .icon {
    height: 105px;
  }
  .result-item-header {
    padding: 20px 30px;
    border-bottom: 1px solid #f2f2f2;
  }
  .result-item-body {
    padding: 10px 30px 25px;
  }
  .textofresult{
    margin-left: -20px;
    font-weight: 700;
  }
  .number-list li.active {
    background-color: #ea2e49;
    border-color: #ea2e49;
    color: #ffffff;
    box-shadow:0 10px 10px 0  rgba(241 0 38 / 36%);
    /* overflow: hidden; */
  }
  .number-list {
    max-width: 440px;
    margin: 6px auto;
}
  .number-list li {
    width: 32px;
    height: 32px;
    color:#ea2e49;
    border: 1px solid #9fa1a7;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
    margin: 6px;
   cursor: pointer;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.8)
  }

  .winner-text{
    overflow: hidden;
    text-overflow: ellipsis;
  }
@media (max-width: 1199px){
 
    .header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link {
       
      padding: 20px 10px;
  }
  .header .mainmenu-area .navbar .mybtn1 {
    margin-left: 15px;
    padding: 10px 29px;
  }
  .header .mainmenu-area .navbar::after {
    border-bottom: 66px solid #070b28;
  }
  .header .mainmenu-area .navbar::before,
  .header .mainmenu-area .navbar::after
  {
    display: none;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities .top-area .left .icon {
    top: 30px;
    margin-right: 0px;
    display: inline-block;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities .top-area{
    display: flow-root;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities .top-area .right {
    margin-top: 30px;
    display: block;
    flex: unset;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities .top-area {
    margin-bottom: 21px;
    padding-bottom: 21px;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities .bottom-area .pp {
    text-align: unset;
    margin-left: 0px;
    top: 0px;
    margin-top: 0px;
    display: block;
    position: unset;
    width: 100%;
    float: unset;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities {
    padding: 0px 30px 25px;
  }
  .play-games .all-games .nav li a {
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
  .play-games .all-games .nav li a .icon img {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px
  }
  .play-games .all-games .nav li a .icon {
    margin-top: 16px;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities .bottom-area .players-count{
    display: block;
  }
  .breadcrumb-area.bc-lottery {
    padding: 204px 0px 150px;
  }
  .breadcrumb-area.bc-tournaments .bc-img {
    right: 0px;
  }
  .breadcrumb-area.bc-tournaments {
    padding: 210px 0px 100px;
  }
  .flip-clock-wrapper ul {
    width: 40px;
    height: 60px;
  }
  .flip-clock-wrapper ul li a div.up:after {
    top: 29px;
    background-color: rgba(255, 255, 255, 0.4);
  }
  .flip-clock-wrapper ul li a div div.inn {
    font-size: 40px;
    line-height: 62px;
  }
  .flip-clock-dot.top {
    top: 15px;
  }
  .flip-clock-divider {
    height: 84px;
  }
  
  .flip-clock-divider .flip-clock-label {
    right: -74px;
  }
  .breadcrumb-area.about {
    padding: 204px 0px 94px;
  }
  .team .team-members .team-member-inner .single-team .social-area {
    padding: 30px 20px 30px;
  }
  .breadcrumb-area.bc-affiliate .bc-img {
    bottom: auto;
    width: 65%;
    top: 50%;
    transform: translateY(-50%);
  }
  .breadcrumb-area.bc-affiliate {
    padding: 203px 0px 174px;
  }
  .ref-commission .ab-img {
    left: -228px;
  }
  .breadcrumb-area.bc-awards {
    padding: 203px 0px 94px;
  }
  .breadcrumb-area.bc-awards .bc-img {
    width: 60%;
  }
  .breadcrumb-area.bonus {
    padding: 203px 0px 96px;
  }
  .breadcrumb-area.cart {
    padding: 203px 0px 94px;
  }
  .breadcrumb-area.bc-faq {
    padding: 203px 0px 95px;
  }
  .breadcrumb-area.h-i-w {
    padding: 203px 0px 93px;
  }
  .breadcrumb-area.h-i-w .bc-img {
    width: 60%;
  }
  .breadcrumb-area.bc-terms {
    padding: 203px 0px 150px;
  }
  .breadcrumb-area.bc-terms .bc-img {
    width: 55%;
  }
  .breadcrumb-area.bc-contact {
    padding: 203px 0px 94px;
  }
  .contact .left-img {
    top: 390px;
    left: 6px;
    max-width: 480px;
  }
  .index2 .hero-area {
    padding: 358px 0px 424px;
  }
  .video-play-btn2 span:before{
    display: none;
  }
  .index2 .features {
    padding: 277px 0px 90px;
  }
  .contact .section-heading {
    margin-bottom: 80px;
  }
  
  
  
  
  
  
  }
  
  @media  (max-width: 991px) {
   .earning_apportunities img {
      margin-top: 0;
   }
   
    .header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link .mr-hover-effect{
      display: none;
    }
    .header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link {
      padding: 8px 10px;
    }
    .header .mainmenu-area .navbar .navbar-brand {
      padding-left: 0px;
    }
    .header .top-header .content .left-content .left-list li p,
    .header .top-header .content .right-content .right-list li .sign-in,
    .header .top-header .content .right-content .right-list li i
    {
      font-size: 14px;
    }
    .header .mainmenu-area .navbar {
      padding: 8px 0px;
  }
    .hero-area {
      padding: 189px 0px 730px;
    }
    .hero-area .hero-img {
      top: 40px;
    }
    .hero-area .hero-img .shape.man{
      animation: unset;
    }
  
    .features .single-feature {
      margin-bottom: 30px;
      display: block;
    }
    .features {
      padding-bottom: 80px;
  }
  .section-heading .title {
    font-size: 40px;
    line-height: 50px;
  }
  .section-heading .subtitle {
    font-size: 20px;
    line-height: 30px;
  }
  .section-heading .text,
  .how-it-work .single-work p,
  .funfact .single-fun p
  {
    font-size: 16px;
    line-height: 1.5;
  }
  .activities .table tr th ,
  .activities .table tr td
  {
    font-size: 16px;
    line-height: 26px;
  }
  .funfact .single-fun .count-area {
    font-size: 40px;
    line-height: 50px;
  }
  .funfact .single-fun {
    margin-bottom: 22px;
  }
  .activities {
    padding: 50px 0px 0px;
  }
  .get-start {
    padding: 111px 0px 120px;
  }
  .get-start .right-image {
    text-align: center;
    margin-top: 60px;
  }
  .single-winer .bottom-area .left {
    font-size: 24px;
  }
  .single-winer .top-area .left .date {
    margin-bottom: 8px;
  }
  .single-winer{
    margin-bottom: 30px;
  }
  .single-winer {
    padding: 30px 30px 23px;
  }
  .footer .subscribe-box .button-area .mybtn1{
    margin-top: 40px;
  }
  .footer .footer-widget .title {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 8px;
  }
  .footer-widget.info-link-widget {
    margin-top: 22px;
  }
  .footer .copy-bg .left-area {
    text-align: center;
  }
  .footer .copy-bg .copright-area-links {
    text-align: center;
  }
  .footer .subscribe-box .heading-area .sub-title{
    font-size: 20px;
    line-height: 30px;
  }
  .header .top-header .content .left-content .left-list li .language-selector .language {
    font-size: 14px;
  }
  .header .top-header .content .left-content .left-list li::before,
  .header .top-header .content .right-content .right-list li::before
  {
    font-size: 14px;
  }
  .footer .subscribe-box {
    margin-bottom: 90px;
  }
  .play-games .gaming-activities .gaming-activities-inner .single-activities {
    margin-bottom: 30px;
  }
  .play-games .all-games {
    padding: 89px 0px 120px;
  }
  .play-games .all-games .nav li a {
    margin: 5px;
  }
  .play-games .all-games {
    padding: 83px 0px 120px;
  }
  .breadcrumb-area .title {
    font-size: 40px;
    line-height: 50px;
  }
  .breadcrumb-area.play {
    padding: 188px 0px 218px;
  }
  .breadcrumb-area .bc-img {
    position: absolute!important;
    right: 0px!important;
    max-width: 100%!important;
    top: 75%!important;
    bottom: auto!important;
    transform: translateY(-50%)!important;
  }
  .breadcrumb-area.bc-lottery {
    padding: 187px 0px 152px;
  }
  .lottery-area .lottery-staticstics .single-staticstics{
    margin-bottom: 30px;
  }
  .lottery-area .daily-lottery {
    padding: 83px 0px 120px;
  }
  .lottery-area .daily-lottery .draw-time .draw-counter span {
    font-size: 30px;
    line-height: 40px;
  }
  .activities .tab-menu-area .nav-lend .nav-item .nav-link {
    padding: 0px 30px;
  }
  .how-it-work .bg-shape{
    display: none;
  }
  .how-it-work .section-heading {
    margin-bottom: 52px;
  }
  .how-it-work .single-work {
    text-align: center;
    margin-bottom: 36px;
  }
  .question-area {
    padding: 49px 0px 120px;
  }
  .question-area .section-heading {
    margin-top: 51px;
  }
  .breadcrumb-area.bc-tournaments {
    padding: 188px 0px 94px;
  }
  .tournament-time-box {
    margin-bottom: 30px;
  }
  .tournaments .info-table {
    margin-top: 30px;
  }
  .breadcrumb-area.about {
    padding: 189px 0px 94px;
  }
  
  .about-page-section {
    padding: 120px 0px 120px;
  }
  .features .single-feature.landscape .content .title {
    margin-top: 15px;
  }
  .features .single-feature.landscape {
    text-align: center;
  }
  .features .single-feature.landscape .icon{
    display: block;
    margin: 0 auto;
  }
  .single-feature.landscape .content{
    display: inline-block;
    text-align: center;
  }
  .single-feature.landscape {
    padding: 25px 10px 10px;
  }
  .team .team-members .team-member-inner .single-team {
    border-radius: 20px;
    margin-bottom: 30px;
  }
  .team .mybtn1 {
    display: inline-block;
    margin-top: 30px;
  }
  .community-blog .single-box{
    margin-bottom: 30px;
  }
  .community-blog {
    padding: 120px 0px 90px;
  }
  .breadcrumb-area.about {
    padding: 229px 0px 94px;
  }
  .community-blog .single-box .content .text {
    font-size: 20px;
    line-height: 30px;
  }
  .community-blog .single-box .content .title {
    font-size: 24px;
    line-height: 34px;
  }
  
  .breadcrumb-area.bc-affiliate {
    padding: 187px 0px 174px;
  }
  .single-process{
   margin-bottom: 30px;
  }
  .affiliate-program .single-program .content .title {
    margin-top: 37px;
  }
  .affiliate-program .single-program {
    text-align: center;
  }
  .affiliate-program .single-program .icon{
    display: block;
    margin: 0 auto;
  }
  .affiliate-program .single-program .content{
    display: block;
    text-align: center;
  }
  .affiliate-program .single-program {
    padding: 45px 30px 22px;
    display: block;
  }
  .ref-commission .ab-img{
    display: none;
  }
  .single-process .title {
    font-size: 24px;
    line-height: 34px;
  }
  .single-process .text {
    font-size: 20px;
    line-height: 30px;
  }
  .breadcrumb-area.bc-awards .bc-img{
    bottom: 0px !important;
    top: auto!important;
    transform: unset!important;
  }
  .breadcrumb-area.bonus {
    padding: 187px 0px 96px;
  }
  .single-deposit-bonus{
    margin-bottom: 30px;
  }
  .deposit-bonuses {
    padding: 112px 0px 90px;
  }
  .single-deposit-bonus .content .title {
    font-size: 24px;
    line-height: 34px;
  }
  .breadcrumb-area.cart {
    padding: 188px 0px 94px;
  }
  .cart-area .message-one p {
    font-size: 20px;
    line-height: 30px;
  }
  .cart-area .message-two .left p {
    font-size: 20px;
    line-height: 30px;
  }
  .payment-method .text {
    font-size: 20px;
    line-height: 30px;
  }
  .payment-method .title {
    font-size: 24px;
    line-height: 34px;
  }
  .breadcrumb-area.bc-faq {
    padding: 188px 0px 95px;
  }
  .faq-wrapper .tab-content {
    padding: 0 0px;
  }
  .breadcrumb-area.h-i-w {
    padding: 187px 0px 93px;
  }
  .single-h-play{
    margin-bottom: 30px;
  }
  .how-play {
    padding: 112px 0px 563px;
  }
  .breadcrumb-area.bc-terms {
    padding: 187px 0px 150px;
  }
  .help-section .help-box .help-box-inner .title {
    font-size: 40px;
    line-height: 50px;
  }
  .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .top-title {
    font-size: 40px;
    line-height: 50px;
  }
  .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .writer-profile .writer-content .w-name {
    font-size: 24px;
    line-height: 34px;
  }
  .breadcrumb-area.bc-contact {
    padding: 187px 0px 94px;
  }
  .contact .left-img{
    display: none;
  }
  .contact .contact-form-wrapper{
    padding-right: 0px;
  }
  .index2 .header .mainmenu-area {
    background: #252f5a;
  }
  .index2 .hero-area {
    padding: 238px 0px 341px;
  }
  .index2 .hero-area {
    padding: 238px 0px 314px;
  }
  .video-play-btn2:after {
    width: 115px;
    height: 115px;
  }
  .video-play-btn2:before {
    width: 130px;
    height: 130px;
  }
  .video-play-btn2 span {
    width: 145px;
    height: 145px;
  }
  .video-play-btn2 {
    margin: 82px 0px 0px;
  }
  .about-section .about-box .about-box-inner .section-heading {
    padding-top: 186px;
    text-align: center;
  }
  .recent-winners .mybtn2 {
    margin-top: 30px;
  }
  .our-vision .single-vision {
    margin-bottom: 55px;
  }
  .our-vision {
    padding: 112px 0px 60px;
  }
  .header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu {
    position: relative;
    top: 0;
    left: 0;
  }
  div#main_menu .navbar-nav{
    overflow-y: auto;
    background: #0e1833;
    padding-bottom: 15px;
  }
  .marketing-tools .section-heading {
    margin-bottom: 51px;
  }
  .marketing-tools .single-tools {
    margin-bottom: 36px;
  }
  .marketing-tools {
    padding: 112px 0px 80px;
  }
  .marketing-tools .single-tools .content .text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
  }
  .testimonial .single-testimonial .review-text p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
  }
  .how-it-work {
    padding: 97px 0px 484px;
  }
  
  .ether_display {
    margin-top: 0;
  }
  
  .ether_display .content .info-box {
    padding: 10px 10px 10px !important;
  }
  
  }
  
  @media  (max-width: 767px) {
    /* .section-title {
        font-size: 42px;
      } */
  .subscribe-box p img {
      width: 45px;
      margin: 20px 0;
  }
  
  .contact-form-wrapper {
    padding-top: 30px !important;
  }
  
  .how-it-work .single-work img {
    width: 50px;
    margin-bottom: 0;
  }
  
  #contract img {
      width: 100%;
  }
  
  .help-section {
    padding: 65px 0px 0px;
  }
  
  .cart-area {
    padding: 30px 0px 20px;
  }
  
  .value_display {
      margin-bottom: 15px;
  }
    .hero-area .left-content .content .title {
      font-size: 50px;
      line-height: 60px;
  }
  .hero-area .left-content .content .subtitle {
    font-size: 24px;
    line-height: 34px;
  }
  .hero-area .left-content .content .text {
    font-size: 20px;
    line-height: 30px;
  }
  .hero-img2{
    margin-top: 60px;
  }
  .hero-area {
    padding: 189px 0px 265px;
  }
  .header .top-header .content{
    display: flow-root;
  }
  .header .top-header .content .left-content {
    text-align: center;
    margin-bottom: 15px;
  }
  .header .top-header .content .right-content{
    text-align: center;
  }
  .activities .tab-menu-area .nav-lend .nav-item .nav-link {
    padding: 0px 30px;
    font-size: 16px;
  }
  .footer .subscribe-box {
    padding: 0px;
  }
  .hero-area .left-content {
    text-align: center;
  }
  .responsive-table {
    max-width: 100%;
    overflow-x: auto;
  }
  .activities .tab-menu-area .nav-lend .nav-item .nav-link {
    line-height: 40px;
    margin: 3px;
  }
  .activities .tab-menu-area .nav-lend {
    padding: 14px 15px;
  }
  .funfact .single-fun .count-area {
    font-size: 30px;
    line-height: 40px;
  }
  .breadcrumb-area.play {
    padding: 230px 0px 218px;
  }
  .breadcrumb-area.bc-lottery {
    padding: 230px 0px 152px;
  }
  .breadcrumb-area.bc-tournaments {
    padding: 230px 0px 94px;
  }
  .tournament-time-box .top-area .status {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }
  .tournament-time-box .top-area .title {
    font-size: 22px;
    line-height: 32px;
  }
  .tournaments .info-table .main-box .inner-table-content .header-area ul .nav-item .nav-link {
    padding: 0px 30px;
    margin: 0px 3px;
  }
  .tournaments .info-table .main-box .inner-table-content .table tr td {
    font-size: 16px;
    line-height: 26px;
  }
  .breadcrumb-area.bc-affiliate {
    padding: 229px 0px 174px;
  }
  .breadcrumb-area.bc-awards {
    padding: 228px 0px 94px;
  }
  .breadcrumb-area.bonus {
    padding: 229px 0px 96px;
  }
  .breadcrumb-area.cart {
    padding: 230px 0px 94px;
  }
  .cart-area .message-one {
    padding: 22px 30px 28px;
  }
  .cart-area .message-two{
    display: flow-root;
  }
  .cart-area .message-two .left p {
    margin-bottom: 30px;
  }
  .cart-area .cart-table-area .table tr td .qty {
    width: 120px;
  }
  .breadcrumb-area.bc-faq {
    padding: 229px 0px 95px;
  }
  .breadcrumb-area.h-i-w {
    padding: 230px 0px 93px;
  }
  .breadcrumb-area.bc-terms {
    padding: 229px 0px 150px;
  }
  
  .serch-area .serch-box .list {
    display: block;
  }
  .serch-area .serch-box .list li.button{
    width: 100%;
    margin-top: 30px;
    margin-left: 0px;
  }
  .serch-area .serch-box {
    padding: 20px 50px 40px;
    border-radius: 20px;;
  }
  .help-section .help-box .help-box-inner .title {
    font-size: 30px;
    line-height: 40px;
  }
  .help-section .help-box .help-box-inner2-hearer .title {
    font-size: 24px;
    line-height: 34px;
  }
  .help-section .help-box .help-box-inner2 .single-artical .content .title {
    font-size: 24px;
    line-height: 34px;
  }
  .help-section .help-box {
    padding: 29px 30px 40px!important;
  }
  
  .help-section.writter-artical-details .help-box {
    padding: 29px 30px 40px!important;
  }
  .breadcrumb-area.bc-contact {
    padding: 230px 0px 94px;
  }
  .index2 .hero-area {
    padding: 283px 0px 314px;
  }
  .get-start .left-area .section-heading {
    text-align: center;
  }
  .activities .shape.shape1,
  .activities .shape.shape2,
  .activities .shape.shape3
  {
    display: none;
  }
  
  .single-winer {
    padding: 10px 10px 0 !important;
  }
  
  .single-winer  .name {
    font-size: 10px !important;
    line-height: 1 !important;
  }
  
  .single-winer .top-area {
    margin-bottom: 0;
  }
  
  .single-winer .top-area .left .date {
    font-size: 10px !important;
    line-height: 1.5 !important;
  }
  
  .single-winer .top-area .right .id {
    font-size: 9px !important;
    padding: 4px !important;
    line-height: 1.5;
  }
  
  .single-winer .bottom-area .left {
    font-size: 10px !important;
    line-height: 1 !important;
  }
  
  .single-winer .bottom-area .right img {
    width: 20px !important;
    margin-top: -20px !important;
  }
  
  .matrix-price {
    font-size: 10pt;
  }
  
  .matrix-level {
    font-size: 9pt;
  }
  
  .matrix_partners__count span, .matrix_reinvest span {
    font-size: 8px;
  }
  .detail_page {
    zoom: 0.5;
  }
  }
  
  @media  (max-width: 575px) {
      .banner_bottom_content {
      margin-top: 0;
      }
      
      .banner_left p, .banner_right p {
          width: 100%;
          text-align: center;
      }
      
      .breadcrumb-area.bc-lottery {
          padding: 0px 0px 200px !important;
      }
      
  .banner_signup {
      margin-bottom: 20px;
  }
      
  canvas#spiders {
      height: auto !important;
  }
    .hero-area {
      padding: 232px 0px 265px;
    }
    .hero-area .left-content .content .subtitle {
      font-size: 20px;
      line-height: 30px;
    }
    .hero-area .left-content .content .title {
      font-size: 40px;
      line-height: 50px;
    }
    .section-heading .title {
      font-size: 30px;
      line-height: 40px;
    }
    .features .single-feature .content .title {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 30px;
    }
    .tournaments .info-table .main-box .main-header-area .nav li a
    {
      line-height: 30px;
      font-size: 20px;
      margin: 0px 1px;
    }
  
    .tournaments .info-table .main-box .main-header-area .nav li::before{
      height: 18px;
    }
    .tournaments .info-table .main-box .inner-table-content .header-area ul .nav-item .nav-link {
      margin: 3px 3px;
    }
    .tournaments .info-table .main-box .inner-table-content .header-area ul {
      border-radius: 10px;
      padding: 14px 15px;
    }
    .single-process .num {
      font-size: 140px;
    }
    .footer .subscribe-box .heading-area .sub-title {
      font-size: 18px;
      line-height: 28px;
    }
    .footer .subscribe-box .heading-area .title {
      font-size: 24px;
      line-height: 34px;
    }
    .four-zero-four .content .inner-content .title {
      font-size: 24px;
      line-height: 34px;
    }
  
    .breadcrumb-area {
      border-bottom:0;
    }
    .play-games .gaming-activities .gaming-activities-inner .single-activities {
      padding: 0px 30px 27px;
    }
    .play-games .all-games .nav {
      margin-bottom: 55px;
    }
    .flip-clock-divider .flip-clock-label {
      font-size: 16px;
    }
    .flip-clock-divider .flip-clock-label {
      bottom: -20px;
    }
    .flip-clock-divider .flip-clock-label {
      right: -72px;
    }
    .flip-clock-divider.hours .flip-clock-label {
      right: -80px;
    }
    .flip-clock-divider.minutes .flip-clock-label {
      right: -87px;
    }
    .tournament-time-box .timer-area {
      padding: 50px 30px 55px;
    }
    .testimonial .single-testimonial .people .title {
      font-size: 24px;
      line-height: 34px;
    }
    .marketing-tools .single-tools .content .title {
      font-size: 24px;
      line-height: 34px;
    }
    .how-play {
      padding: 112px 0px 571px;
    }
    .features.about-page {
      border-top: 1px solid rgba(255, 255, 255, 0.08);
    }
    .sorteo-accordion .card-header .btn-link.collapsed::before,
    .sorteo-accordion .card-header .btn-link::before
    {
      display: none;
    }
    .sorteo-accordion .card-body {
        margin-top: 10px;
        padding: 16px 30px 8px;
    }
    .sorteo-accordion .card-body p{
      font-size: 14px;
    }
    .sorteo-accordion .card-header .btn-link {
      display: block;
      position: relative;
    }
    .sorteo-accordion .card-header .btn-link i{
      display: inline-block;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .sorteo-accordion .card-header .btn-link {
      padding: 17px 10px 17px 70px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .logo-area::after{
      display: none;
    }
    .login-modal .modal-dialog .modal-content .modal-body .logo-area {
      padding-top: 30px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .header-area {
      padding: 16px 30px 10px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .header-area .sub-title {
      font-size: 20px;
      line-height: 30px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .form-footer p{
      font-size: 16px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .form-area .form-group .box{
      display: flow-root;
    }
    .login-modal .modal-dialog .modal-content .modal-body .form-area {
      padding: 0px 20px 10px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .header-area {
      padding: 16px 20px 10px;
    }
    .login-modal .modal-dialog .modal-content .modal-body .form-footer {
      padding: 0px 20px 6px;
    }
    .section-heading .subtitle {
      font-size: 18px;
      line-height: 28px;
    }
    .single-winer .top-area .left .name,
    .single-winer .bottom-area .left,
    .play-games .gaming-activities .gaming-activities-inner .single-activities .top-area .right .title,
    .tournaments .info-table .main-box .info-content .info-box .title,
    .tournament-time-box .top-area .title,
    .tournament-time-box .timer-area .title,
    .team .team-members .team-member-inner .single-team .image .content .name,
    .single-process .title,
    .affiliate-program .single-program .content .title,
    .marketing-tools .single-tools .content .title,
    .testimonial .single-testimonial .people .title,
    .single-awards .content .title,
    .community-blog .single-box .content .title,
    .single-bonus .content .title,
    .single-deposit-bonus .content .title,
    .single-h-play .title,
    .help-section .help-box .help-box-inner2-hearer .title,
    .help-section .help-box .help-box-inner2 .single-artical .content .title,
    .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .writer-profile .writer-content .w-name,
    .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .inner-content .title,
    .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .footer-content .heading,
    .contact .contact-form-wrapper .contact-box .title,
    .how-it-work .single-work .title,
    .lottery-area .lottery-staticstics .single-staticstics .right .count span,
    .lottery-area .daily-lottery .draw-time .subtitle
    {
      font-size: 20px;
      line-height: 30px;
    }
    .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .inner-content p{
      font-size: 20px;
      font-weight: 300;
    }
    .help-section .help-box .help-box-inner3-hearer .title ,
    .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .top-title 
    {
      font-size: 24px;
      line-height: 34px;
    }
    .breadcrumb-area .title {
      font-size: 30px;
      line-height: 40px;
    }
    .lottery-video .video-box {
      height: 400px;
    }
  
    .cart-area .cart-table-area .table {
      width: 900px;
      zoom: 0.5;
    }
  
    .team .team-members .team-member-inner .single-team .image .content .designation {
      font-size: 16px;
      line-height: 26px;
    }
  
    .activities .table {
      width: 800px;
    }
  
    .index2 .hero-area {
      padding: 232px 0px 264px;
    }
    .footer .footer-widget .title {
      font-size: 22px;
      line-height: 32px;
    }
    .play-games .gaming-activities .gaming-activities-inner .single-activities .top-area {
      text-align: center;
    }
    .play-games .gaming-activities .gaming-activities-inner .single-activities .bottom-area {
      text-align: center;
    }
    .lottery-area .lottery-staticstics .single-staticstics {
      padding: 10px;
      border-radius: 10px;
      background-color: rgba(29, 40, 80, 0.6);
    }
    .question-area .section-heading .title {
      margin-bottom: 18px;
    }
    .tournaments .info-table .main-box .inner-table-content .table {
      width: 670px;
    }
    .about-page-section .about-video .play-video {
      width: 100px;
      height: 100px;
      line-height: 100px;
    }
    .about-page-section .section-heading {
      margin-top: 50px;
    }
    .help-section .help-box .help-box-inner .title {
      font-size: 24px;
      line-height: 34px;
    }
    .single-game img,
    .single-bonus .content img
    {
      animation: unset;
    }
  
    .features .single-feature .content .link {
      font-size: 14px;
    }
  
    .single-feature .icon {
      zoom: 0.5;
    }
  
    .contact .section-heading {
      margin-bottom: 0;
    }
  
    .header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link {
      line-height: 1;
      font-size: 14px;
    }
  
    .header .mainmenu-area .navbar .mybtn1 {
      margin-left: 15px;
      padding: 3px 29px;
      margin-top: 10px;
    }
  
    .header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu .dropdown-item {
      line-height: 1;
      font-size: 14px;
    }
  
    label {
      font-size: 11px;
    }
  
    .filter input, .filter select {
      margin-bottom: 0px;
      font-size: 12px;
    }
  
    .cart-table-area h3 {
      font-size: 18px;
    }
  
    .apply_filter .mybtn1 {
      margin-top: 15px;    
      font-size: 12px;
    }
  }
  
  @media  (max-width: 500px) {
    .flip-clock-wrapper ul {
      width: 28px;
      height: 33px;
    }
    .flip-clock-wrapper ul li a div.up:after {
      top: 29px;
      background-color: rgba(255, 255, 255, 0.4);
    }
    .flip-clock-wrapper ul li a div div.inn {
      font-size: 20px;
      line-height: 35px;
    }
    .flip-clock-dot.top {
      top: 10px;
    }
    .flip-clock-divider {
      height: 57px;
  }
    
    .flip-clock-divider .flip-clock-label {
      right: -74px;
    }
    .flip-clock-divider {
      width: 10px;
  }
  .flip-clock-dot {
    width: 5px;
    height: 5px;
    left: 2px;
  }
    .flip-clock-wrapper ul {
      margin: 2px;
  }
  .flip-clock-divider .flip-clock-label {
    bottom: -18px;
    right: -46px;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .flip-clock-divider.hours .flip-clock-label {
    right: -51px;
  }
  .flip-clock-divider.minutes .flip-clock-label {
    right: -59px;
  }
  .tournament-time-box .timer-area {
    padding: 50px 30px 50px;
  }
  .tournament-time-box .top-area .title {
    font-size: 20px;
    line-height: 30px;
  }
  .tournament-time-box .top-area .sub-title {
    font-size: 16px;
    line-height: 26px;
  }
  .tournament-time-box .top-area .status {
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    left: -70px;
    top: 14px;
  }
  
  
  
  
  
  
    
  }
  
  
  @media  (max-width: 469px) {
  
  }
  
  
  @media  (max-width: 414px) {
    
    .header .top-header .content .left-content .left-list li {
      margin-right: 15px;
  }
  .header .top-header .content .left-content .left-list li::before {
    right: -14px;
  }
  .activities .tab-menu-area .nav-lend {
    border-radius: 10px;
  }
  .footer .subscribe-box .heading-area .title {
    font-size: 30px;
    line-height: 40px;
  }
  .single-game img {
    max-width: 150px;
  }
  .single-game {
    height: 280px;
    line-height: 280px;
  }
  .lottery-area .daily-lottery .draw-time .draw-counter,
  .lottery-area .daily-lottery .draw-time .draw-counter span 
  {
    font-size: 24px!important;
    line-height: 34px!important;
  }
  
  .help-section .help-box .help-box-inner2 .single-artical .writer-profile{
    display: flow-root;
    text-align: center;
  }
  .help-section .help-box .help-box-inner2 .single-artical .writer-profile img{
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .help-section .help-box .help-box-inner2 .single-artical {
    text-align: center;
  }
  .serch-area .serch-box {
    padding: 20px 30px 40px;
  }
  .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .writer-profile{
    display: flow-root;
  }
  .help-section.writter-artical-details .help-box .help-box-inner2 .single-artical .writer-profile img{
    margin-right: 0px;
  }
  .features .single-feature .icon,
  .affiliate-program .single-program .icon
  {
    width: 100px;
    height: 100px;
  }
  .features .single-feature .icon img,
  .affiliate-program .single-program .icon img
  {
    width: 40px;
  }
  .features .single-feature .icon.one,
  .features .single-feature .icon.two,
  .features .single-feature .icon.three,
  .features .single-feature .icon.four,
  .features .single-feature .icon.four,
  .features .single-feature .icon.five,
  .features .single-feature .icon.six,
  .affiliate-program .single-program .icon.one,
  .affiliate-program .single-program .icon.two,
  .affiliate-program .single-program .icon.three,
  .affiliate-program .single-program .icon.four,
  .affiliate-program .single-program .icon.four,
  .affiliate-program .single-program .icon.five,
  .affiliate-program .single-program .icon.six
   {
    line-height: 80px;
  }
  .section-heading .title {
    font-size: 20px;
    line-height: 1.5;
  }
  .footer .subscribe-box .heading-area .title {
    font-size: 24px;
    line-height: 34px;
  }
  .hero-area .left-content .content .title {
    font-size: 30px;
    line-height: 40px;
  }
  .hero-area .left-content .content .subtitle {
    font-size: 18px;
    line-height: 28px;
  }
  .hero-area .mybtn1 {
    font-size: 14px;
    padding: 9px 20px;
  }
  .breadcrumb-area .title {
    font-size: 24px;
    line-height: 34px;
  }
  .sorteo-accordion .card-header .btn-link {
    font-size: 16px;
  }
  .breadcrumb-area.play {
    padding: 232px 0px 218px;
  }
  .breadcrumb-area.bc-tournaments {
    padding: 232px 0px 94px;
  }
  .breadcrumb-area.bc-lottery {
    padding: 232px 0px 152px;
  }
  .breadcrumb-area.about {
    padding: 232px 0px 94px;
  }
  .breadcrumb-area.bc-affiliate {
    padding: 232px 0px 174px;
  }
  .breadcrumb-area.bc-awards {
    padding: 232px 0px 94px;
  }
  .breadcrumb-area.bonus {
    padding: 232px 0px 96px;
  }
  .breadcrumb-area.cart {
    padding: 232x 0px 94px;
  }
  .breadcrumb-area.bc-faq {
    padding: 232px 0px 95px;
  }
  .breadcrumb-area.h-i-w {
    padding: 232px 0px 93px;
  }
  .breadcrumb-area.bc-terms {
    padding: 232px 0px 150px;
  }
  .breadcrumb-area.bc-contact {
    padding: 232px 0px 94px;
  }
  }
  
  
  @media  (max-width: 390px) {
  
  
  
  .tournament-time-box .timer-area {
    padding: 50px 20px 50px;
  }
  .payment-method .method-slider {
    padding: 0px 40px;
  }
  .community-blog .single-box{
    display: flow-root;
  }
  .community-blog .single-box .img {
    margin-right: 0px;
    margin-bottom: 22px;
  }
  
  .header .top-header .content .right-content .right-list > li .cart-icon.tm-dropdown .tm-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-30%);
  }
  .footer .subscribe-box .form-area input{
    font-size: 14px;
  }
  .footer .subscribe-box .icon{
    display: none;
  }
  .footer .subscribe-box .col-8{
    max-width: 100%!important;
    flex: 100% 1!important;
  }
  .footer .subscribe-box .heading-area .title {
    margin-bottom: 0px;
  }
  .lottery-area .buy-tickets .buy-tickets-box .content {
    padding: 20px 15px 20px;
  }
  
  }
  
  
  @media  (max-width: 343px) {
    .index2 .hero-area {
      padding: 258px 0px 264px;
  }
  .hero-area {
    padding: 258px 0px 265px;
  }
  
  .breadcrumb-area.play
     {
      padding: 257px 0px 218px;
  }
  
  .breadcrumb-area.bc-lottery {
    padding: 257px 0px 218px;
  }
  .breadcrumb-area.bc-lottery {
    padding: 257px 0px 152px;
  }
  .breadcrumb-area.bc-tournaments {
    padding: 257px 0px 94px;
  }
  .breadcrumb-area.about {
    padding: 257px 0px 94px;
  }
  .breadcrumb-area.bc-affiliate {
    padding: 257px 0px 174px;
  }
  .breadcrumb-area.bc-awards {
    padding: 257px 0px 94px;
  }
  .breadcrumb-area.bonus {
    padding: 257px 0px 96px;
  }
  .breadcrumb-area.bc-faq {
    padding: 257px 0px 95px;
  }
  .breadcrumb-area.h-i-w {
    padding: 257px 0px 93px;
  }
  .breadcrumb-area.bc-terms {
    padding: 257px 0px 150px;
  }
  .breadcrumb-area.bc-contact {
    padding: 257px 0px 94px;
  }
  
  
  
  
  }
  
  
  
  
  
  
  
  
  
.profileImg {
  position: relative;

  transition: transform 0.2s;
  width: 100px !important;
  border: 1px solid;
  border-color: rgb(124, 121, 121);
}
.profileImg:hover {
  transform: scale(1.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.dats span {
  margin-left: 0;
  font-size: 13px;
}
.data-input span {
  font-size: 13px;
}
.customSpace {
  margin-left: 10px;
}
.disabledbtn {
  background: #878787;
  color: #fff;
}
.nav-text a:hover {
  background-color: #c9c9c9 !important;
  text-decoration: none !important;
}

.modal-footer {
  justify-content: center !important;
}

.Homepage,
.Login,
.Register .Mytickets .Winner .Help {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.App {
  color: black;
  text-align: center;
}

.wheelarea.rollspin .sc-gsTCUz.bhdLno .sc-dlfnbm.started-spinning {
  animation: 4s linear infinite forwards running continueSpin;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@media (max-width: 1199px) {
  .navbar-collapse {
    flex-basis: 100%;
    background: #f8f9fa !important;
    position: relative !important;
    z-index: 10000 !important;
    flex-grow: 1;
    align-items: center;
    padding: 20px !important;
  }
}
@media (min-width: 991px) {
  .navbar {
    padding: 0.5rem 3rem !important;
  }
  .hovernav:hover {
    /* transform: scale(1.1); */
  }
}
.App-link {
  color: #61dafb;
}
.nav-link {
  display: block;
  margin: 0px 5px !important;
  padding: 0.4rem 0.2rem !important;
}
.hovernav:hover {
  border-bottom: 2px solid #ff344d;
}
p.underlinelink {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media (max-width: 991px ) and (min-width: 768px) {
  .navbar-nav{
    align-items: center;
  }
} */

.banner-section {
  position: relative;
  /* overflow: hidden; */
  /* border-bottom: 2px solid #ededf5; */
}
.numbercircle {
  border: 1px solid #ff344d;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 10px;
}
.banner-content-area {
  padding-top: 60px;
  padding-bottom: 40px;
}
.banner-content{
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner-content .title {
  font-size: 32px;
}
.banner-content p {
  /* max-width: 430px; */
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.banner-content a {
  margin-top: 25px;
}
.banner-elements-part {
  position: absolute;
  right: 7%;
  bottom: 110px;
  width: 750px;
  height: 500px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;
  left: 45%;
}

.bg {
  position: absolute;
  right: 7%;
  bottom: 110px;
  width: 750px;
  height: 500px;
  /* background-position: center;
  background-size: contain;
  background-repeat: no-repeat; */
  top: 10%;
  left: 45%;
}

.banner-elements-part .element-one,
.banner-elements-part .element-two,
.banner-elements-part .element-three,
.banner-elements-part .element-four,
.banner-elements-part .element-five,
.banner-elements-part .element-six,
.banner-elements-part .element-seven,
.banner-elements-part .element-eight,
.banner-elements-part .element-nine {
  position: absolute;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  opacity: 1;
}
.banner-elements-part .element-one {
  transition-delay: 0.7s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-two {
  transition-delay: 1s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-three {
  transition-delay: 1s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-four {
  transition-delay: 1.3s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-five {
  transition-delay: 0.3s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-six {
  transition-delay: 1.2s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-seven {
  transition-delay: 1.7s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-eight {
  transition-delay: 2s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-nine {
  transition-delay: 1.2s;
  transition-duration: 0.5s;
}

.banner-elements-part .element-one {
  top: 50%;
  left: 49%;
  z-index: 2;
}
.banner-elements-part .element-two {
  top: 52%;
  left: 54%;
}
.banner-elements-part .element-three {
  top: 58%;
  left: 32%;
}
.banner-elements-part .element-four {
  top: 63%;
  left: 25%;
  z-index: 2;
}
.banner-elements-part .element-five {
  top: 40%;
  left: 35%;
  z-index: 1;
}
.banner-elements-part .element-six {
  top: 55%;
  left: 80%;
  z-index: 2;
}
.banner-elements-part .element-seven {
  top: 78%;
  left: 43%;
  z-index: 2;
}
.banner-elements-part .element-eight {
  top: 35%;
  left: 4%;
}
.banner-elements-part .element-nine {
  top: 10%;
  left: 31%;
  z-index: 2;
}

.banner-elements-part.active .element-one {
  top: 62%;
  left: 49%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-two {
  top: 68%;
  left: 54%;
  opacity: 1;
}
.banner-elements-part.active .element-three {
  top: 46%;
  left: 32%;
  opacity: 1;
}
.banner-elements-part.active .element-four {
  top: 63%;
  left: 37%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-five {
  top: 31%;
  left: 35%;
  z-index: 1;
  opacity: 1;
}
.banner-elements-part.active .element-six {
  top: 55%;
  left: 68%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-seven {
  top: 66%;
  left: 43%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-eight {
  top: 35%;
  left: 14%;
  opacity: 1;
}
.banner-elements-part.active .element-nine {
  top: 26%;
  left: 31%;
  z-index: 2;
  opacity: 1;
}

.bannerbg {
  background-image: url(/static/media/bann.57291c21.png);
}

.marq {
  background: linear-gradient(179.93deg, #A74AC7 -13.4%, rgba(167, 74, 199, 0.51) 113.4%);
  opacity: 0.6;
}

.marq .feature-msg {
  color: white;
  opacity: 1;
}

.marq .feature-msg a{
  color: white;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .banner-elements-part {
    right: 0;
  }
}
@media (max-width: 1550px) {
  .banner-elements-part {
    right: 7%;
  }
}
@media (max-width: 1400px) {
  .banner-elements-part {
    right: -6%;
  }
}

@media (max-width: 1199px) {
  .banner-content-area {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .banner-elements-part div[class*="element"] img {
    max-width: 70%;
  }
  .banner-elements-part.active .element-five {
    top: 35%;
    left: 45%;
  }
  .banner-elements-part.active .element-six {
    top: 52%;
    left: 69%;
  }
  .banner-elements-part.active .element-nine {
    top: 33%;
    left: 43%;
  }
  .banner-elements-part.active .element-three {
    top: 46%;
    left: 42%;
  }
  .banner-elements-part.active .element-one {
    top: 56%;
    left: 56%;
  }
  .banner-elements-part.active .element-four {
    top: 58%;
    left: 49%;
  }
  .banner-elements-part.active .element-seven {
    top: 61%;
    left: 53%;
  }
  .banner-elements-part.active .element-two {
    top: 62%;
    left: 59%;
  }
  .banner-elements-part.active .element-eight {
    top: 43%;
    left: 31%;
  }
}
@media (max-width: 600px) {
  .banner-content-area {
    padding-bottom: 40px;
  }
}

.cubeimage {
  z-index: -1;
  /* right: 120px; */
  height: 427px;
  width: 80%;
  top: 75px;
  /* position: absolute; */
}
@media screen and (max-width: 1200px ) and (min-width: 768px) {
  .banner-content{
    max-width: 440px;
  }
}
@media (max-width: 768px) {
  .cubeimage {
    z-index: -1;
    /* right: 120px; */
    height: 427px;
    width: 80%;
    top: 62px;
    /* position: absolute; */
    opacity: 0.3;
  }
  .banner-content{
    min-height: unset;
  }
  .marq .rfm-overlay::before, .rfm-overlay::after{
    width: 40px;
  }
}

.buy-card {
    padding: 20px 20px;
    box-shadow: 0 0 16px 1px rgba(139, 139, 139, 0.918);
    border: 1px ;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .card-inner {
    padding: 20px 20px;
    box-shadow: 0 0 16px 1px rgb(212, 128, 128);
    border: 1px solid #f1b9bc;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .outer-card {
    border: 1px solid #f7a9ad;
    padding: 20px 20px;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
.notification {
    width: 440px;
    padding: 12px 20px;
}

.notification .notification_heading {
    display: flex;
    justify-content: space-between;
}

.notification .notification_heading .heading {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
}

.notification .notification_heading .see_all {
    cursor: pointer;
    color: rgb(79, 128, 225);
    ;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

.notification .notification_list{
    border-radius: 10px;
    background: #edeef0;
    padding:  10px 20px;
    display: flex;
    align-items: center;
}

.notification .notification_list .icon-section{
    margin-right: 12px;
}

.notification .notification_list .icon-section .notify_icon{
    height: 20px;
    width: 20px;
}

.notification .icon-section svg{
    stroke-width: 0px;
    fill: unset;
}

.notification .notification_list .details-section{
    width: calc(100% - 32px);
}

.notification .notification_list .notification_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.notification .notification_list .notification_content {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification .notification_list .notification_time {
    font-size: 12px;
    line-height: 18px;
}
.lotterydropdown
  .Dropdown_dropdown__3J5R4
  .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl:hover,
.Dropdown_dropdown__3J5R4
  .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl.Dropdown_active__u0jjG {
  color: unset;
}

.lotterydropdown
  .Dropdown_dropdown__3J5R4
  .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl, .lotterydropdown .Dropdown_dropdown__3Oivs .Dropdown_button__3oxDE.Dropdown_button-tertiary__2z-R8 {
  color: unset;
  padding: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.lotterydropdown .Item_item__1h4jC, .lotterydropdown .Item_item__1ExBN{
    justify-content: space-between;
}

.lotterydropdown .Submenu_submenu__33Fxt .showMore, .lotterydropdown .Submenu_submenu__2BZif .showMore {
  color: #ea2e49;
  font-weight: 600;
  justify-content: center;
}

.lotterydropdown .Dropdown_dropdown__3J5R4 .Dropdown_button__2-R65.Dropdown_button-tertiary__1VHsl:focus, .lotterydropdown .Dropdown_dropdown__3Oivs .Dropdown_button__3oxDE.Dropdown_button-tertiary__2z-R8:focus {
    box-shadow: none;
}

.navbar .lotterydropdown svg {
    fill: rgba(0,0,0,0.8);
    height: 16px;
}

.lotterydropdown .Submenu_submenu__33Fxt > ul, .lotterydropdown .Submenu_submenu__2BZif > ul, .lotterydropdown .Dropdown_dropdown__3Oivs .Dropdown_menu__1UmPH ul{
  margin: 5px 0px;
}

.lotterydropdown a{
  color: unset;
}

.lotterydropdown a:hover{
  color: unset;
  text-decoration: none;
}
.counter-wrapper {
  padding-left: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
.counter-wrapper .counter-content .text {
  margin-top: 35px;
}
.counter-wrapper .single-counter {
  max-width: 155px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  z-index: 5;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 100px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 155px;
    height: 120px;
  }
}
.counter-wrapper .single-counter::before {
  position: absolute;
  content: "";
  width: 155px;
  height: 120px;
  border-radius: 60px;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
  z-index: -1;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 100px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 155px;
    height: 120px;
  }
}
.counter-wrapper .single-counter.counter-color-1::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #7419db -13.4%,
    rgba(116, 25, 219, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-2::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #5c3ca9 -13.4%,
    rgba(92, 60, 169, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-3::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #a74ac7 -13.4%,
    rgba(167, 74, 199, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-4::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #4f80e1 -13.4%,
    rgba(79, 128, 225, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-5::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    184.42deg,
    #f81000 -8.19%,
    rgba(255, 0, 0, 0.51) 108.19%
  );
}
.counter-wrapper .single-counter .count {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 22px;
  }
}
.counter-wrapper .single-counter .text {
  font-size: 16px;
  color: #fff;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .banner-section .counter-wrapper .single-counter .text {
    font-size: 12px;
    margin-left: 10px;
  }

  .banner-section .counter-wrapper .single-counter .count {
    font-size: 16px;
    margin-left: 10px;
  }
}

.lottery-card .partnerlogo{
    width: auto;
    height: 50px;
    object-fit: contain;
    max-width: 120px;
}

.lottery-card .lottery-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 25px;
    background: #ee4545;
    border-radius: 0.25rem 0px;
}

.lottery-card .lottery-tag span{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
}

@media screen and (max-width: 630px){

.lottery-card .lotterycardbtn{
width: unset;
}
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  h1{
    font-size: 2.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: #444;
    text-align: center;
    margin: 2rem 0;
  }
  
  .wrapper{
    width: 90%;
    margin: 0 auto;
    max-width: 80rem;
  }
  
  .cols{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .col{
    width: calc(25% - 2rem);
    margin: 1rem;
    cursor: pointer;
  }
  
  .flipcontainer{
    transform-style: preserve-3d;
      perspective: 1000px;
  }
  
  .front,
  .back{
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
    border-radius: 10px;
      background-position: center;
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      text-align: center;
      min-height: 280px;
      height: auto;
      border-radius: 12px;
      color: #fff;
      background-color: white;
      font-size: 1.5rem;
  }
  
  .back{
    /* background: #cedce7; */
    background:   linear-gradient(123deg, #98cdf3 0%,#ff6c6c 90%);
  }
  .back .inner p{
    display: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  .front:after{
    position: absolute;
    /* top: 0; */
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: 1;
    /* background-color: #e1dcdc; */
   -webkit-backface-visibility: hidden; 
    backface-visibility:  hidden;;
    border-radius: 12px;
  }
  .flipcontainer:hover .front,
  .flipcontainer:hover .back{
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
  .flipcontainer:hover .back .inner p{
    display: inline;
    display: initial;
  }
  
  .back{
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
  }
  
  .inner{
      transform: translateY(-50%) translateZ(60px) scale(0.94);
      top: 50%;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 2rem;
      box-sizing: border-box;
      outline: 1px solid transparent;
      perspective: inherit;
      z-index: 2;
  }

  .imgwhy{
 
top: 40%;
position: absolute;

left: 50px;
width: 70%;
height: 70%;

outline: 1px solid transparent;

  }
  
  .flipcontainer .back{
      transform: rotateY(180deg);
      transform-style: preserve-3d;
  }
  
  .flipcontainer .front{
      transform: rotateY(0deg);
      transform-style: preserve-3d;
  }
  
  .flipcontainer:hover .back{
    transform: rotateY(0deg);
    transform-style: preserve-3d;
  }
  
  .flipcontainer:hover .front{
    transform: rotateY(-180deg);
    transform-style: preserve-3d;
  }
  
  .front .inner p{
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .front .inner p:after{
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #C6D4DF;
    display: block;
    left: 0;
    right: 0;
    /* margin: 0 auto; */
    bottom: -.75rem;
  }
  
  .front .inner span{
    color: rgba(255,255,255,0.7);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  }
  .spacing{
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 64rem){
    .col{
      width: calc(33.333333% - 2rem);
    }
  }
  
  @media screen and (max-width: 48rem){
    .col{
      width: calc(50% - 2rem);
    }
  }
  
  @media screen and (max-width: 32rem){
    .col{
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }

 
  
  .bookiescontainer {
     
    height: 140px;
   
    top: 60px;
    left: calc(50% - 300px);
    display: flex;
  }
  .showonhover{
      display: none;
  }
  .bookiescard {
    display: flex;
    height: 170px;
    width: 250px;
    background-color: #eeebf5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid #b74848;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }
  
  .bookiescard:not(:first-child) {
      margin-left: -50px;
  }
  
  .bookiescard:hover {
    transform: translateY(-20px);
    transition: 0.4s ease-out;
    
  }
  .bookiescard:hover .showonhover{
   display: block;
   top: 50px;
   position: absolute;
   left: calc(50% - 60px);
    
  }
  
  .bookiescard:hover ~ .bookiescard {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
  }
  
  .bookiestitle {
    color: #0f0f0f;
    font-weight: 500;
    position: absolute;
    left: 20px;
    top: 15px;
  }
  
  .bar {
    position: absolute;
    top: 135px;
    left: 20px;
    height: 5px;
    width: 150px;
  }
  
  .emptybar {
    background-color: #2e3033;
    width: 100%;
    height: 100%;
  }
  
  .filledbar {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 0px;
    height: 100%;
    background: rgb(0,154,217);
    background: linear-gradient(90deg, rgba(0,154,217,1) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
    transition: 0.6s ease-out;
  }
  
  .bookiescard:hover .filledbar {
    width: 120px;
    transition: 0.4s ease-out;
  }
  
  .circle {
    position: absolute;
    top: 150px;
    left: calc(50% - 60px);
  }
  
  .stroke {
    stroke: #f67171;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
    transition: 0.6s ease-out;
  }
  
  svg {
    fill: #000000;
    stroke-width: 2px;
  }
  
  .bookiescard:hover .stroke {
    stroke-dashoffset: 100;
    transition: 0.6s ease-out;
  }
.wheelarea .details {
  position: absolute;
  text-align: center;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
}
.wheelarea h3 {
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 600;
}
.wheelarea h4 {
  font-size: 16px;
  margin-bottom: 0px;
  white-space: nowrap;
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
}
p.bottomtextSpinner {
  position: absolute;
  bottom: 10%;
  font-weight: bold;
  font-size: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.wheelarea {
  position: relative;
  text-align: center;
}

.wheelarea .sc-gsTCUz.bhdLno {
  z-index: -1;
}
.wheelarea.rollspin .mybtn1 {
  display: none;
}

.wheelarea.rollspin {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  opacity: 0.99;
  top: 0;
  left: 0;
  z-index: 98;
}
.wheelarea.rollspin .sc-gsTCUz.bhdLno {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  width: 900px;
  height: 900px;
}

.wheelarea.rollspin .tbx,
.wheelarea.rollspin .mybtn1.topspace {
  display: none;
}

.wheelarea.rollspin .details {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wheelarea .sc-gsTCUz.bhdLno {
  margin: 0 auto;
}
.wheelarea {
  width: 100%;
  height: 100%;
}
.wheelarea .bhdLno {
  width: 300px;
  height: 300px;
}
.wheelarea.txtbox.form-control {
  text-align: center;
}
.wheelarea .topspace {
  margin-top: 10px;
}
.wheelarea .form-control {
  width: 150px;
  text-align: center;
  margin: 0 auto;
}

.wheelarea .partnerlogo {
  width: auto;
  height: 50px;
  object-fit: contain;
  max-width: 120px;
  margin-bottom: 8px;
}

.wheelarea .amount-text {
  width: 100%;
  margin-bottom: 5px;
  color: #333745cc;
  font-size: 18px;
}

.wheelarea .lotteryId {
  color: #002a33;
  font-size: 20px;
  margin-bottom: 8px;
}

.wheelarea .lottery-tag{
  width: 70px;
  height: 25px;
  background: #ee4545;
  border-radius: 0.25rem;
  margin: 10px auto 0px;
}

.wheelarea .lottery-tag span{
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
}

.RevolverPopup{
  background: rgba(255,255,255);
}

@media only screen and (max-width: 1200px) {
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 700px;
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .wheelarea .details {
    /* position: absolute;
    top: 150px; */
    left: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* .wheelarea .details {
    position: absolute;
    top: 150px;
    left: 345px;
  } */
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  /* .wheelarea .details {
    position: absolute;
    top: 150px;
    left: 255px;
  } */
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 500px;
    height: 500px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 576px) {
  /* .wheelarea .details {
    position: absolute;
    top: 150px;
    left: 50%;
  } */
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 340px;
    height: 340px;
  }
}
@media only screen and (max-width: 359px) {
  .wheelarea.rollspin .sc-gsTCUz.bhdLno {
    width: 240px;
    height: 240px;
  }
}


.PopupStyle{
    padding-right: 0px !important;
}
.PopupStyle .modal-body {
        padding: 45px 27.5px 30px 27.5px;
    }

    .PopupStyle  .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
    }

    .PopupStyle .heading-text {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 25px;
    }

    .PopupStyle  .btn-div {
        display: flex;
        justify-content: center;
    }
    .PopupStyle  .message-text{
        margin-bottom: 16px;
        text-align: center;
    }

    .PopupStyle .btn-div .btn1{
        margin-right: 10px;
    }

    .PopupStyle .submit-btn {
        width: 117.86px;
        height: 37.31px;
    }

    .PopupStyle .btn-div .success-btn{
        width: -webkit-max-content;
        width: max-content;
        min-width: 117.86px
    }

    .PopupStyle .link-textt{
       margin-bottom: 16px;
        text-align: center;

    }

.gift-card .lottery-card{
    text-align: left;
    padding:  0px;
    cursor: pointer;
}

.gift-card .lottery-card .card-body{
    padding:  20px;
}

.gift-card .lottery-card .cardlogo{
    height: 100%;
    width: 100%;
    max-height: 175px;
    object-fit: contain;
    max-width: unset;
    padding-top: 20px;
}

/* .gift-card .lottery-card .autobetlogo{
    height: 100%;
    width: 100%;
    max-height: 175px;
    object-fit: contain;
    max-width: unset;
    padding-top: 20px;
} */

.gift-card .giftcard-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 25px;
    background: #ee4545;
    border-radius: 0.25rem 0px;
    text-align: center;
}

.gift-card .giftcard-tag span{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
}

.gift-card .gas-text{
    font-size: 16px;
}

.gift-card .buy-btn{
    margin-top: 12px;
    width: -webkit-max-content;
    width: max-content;
    min-width: 100px;
}

.gift-card .amount-text{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px ;
}

.gift-card .price {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px ;
}

.gift-card .expiry {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 6px;
}

.gift-card .desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    min-height: 44px;
}

.gift-card .address {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.fundRaiseText {
      font-weight: 500;
      font-size: 17px;
}

.num {
      flex-shrink: 0;
      margin-right: 10px;
      width: 38px;
      /* width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: aqua;
      display: flex;
      justify-content: center;
      align-items: center;
      color : #ffffff;
      background: linear-gradient(184.42deg, #F81000 -8.19%, rgba(255, 0, 0, 0.51) 108.19%); */
}

.num img {
      width: 30px;
      height: 30px;
}

.fundTextWrap {
      display: flex;
      align-items: center;
      margin-top: 10px;
}

.fundHead {
      width: 75%;
      font-weight: 600;
      color: #444;
      font-size: 2rem;
      line-height: 1.5;
}

.hrline {
      width: 30px;
      height: 0.5px;
      background-color: #4f80e1;
      margin-right: 20px;
      flex-shrink: 0;
}

.fundBox {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 20px 10px;
      height: 190px;
      margin-bottom: 40px;
}

.fundRaise .title {
      font-size: 32px;
      font-weight: 600;
      text-align: left;
}

.fundRaise .autobetInvite-img{
      width: 100%;
      max-height: 450px;
}

.fundRaise .fundDesc{
      font-size: 18px;
      line-height: 28px;
}

@media screen and (max-width: 991px) {
      .fundRaise .autobetInvite-img{
            max-height: 400px;
            margin-top: 30px;
      }
}

@media only screen and (max-width : 768px) {
      .fundHead {
            width: 100%;
            font-size: 1.5rem;
      }

      .fundRaise .autobetInvite-img{
            max-height: 350px;
      }

      .fundRaise{
            padding-bottom: 10px;
      }
}
.partner-carousel .fundBox{
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 0px;
    margin-top: 10px;
    height: 100%;
}

.partner-carousel .fundBox .cardlogo {
    height: 90px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    padding-bottom: 10px;
}

.partner-carousel .react-multi-carousel-item{
    padding: 0px 16px;
}

.partner-carousel .fundBox .partner-details a{
    word-break: break-word;
}

/* .partner-carousel .react-multi-carousel-list{
    justify-content: center;
} */


.navbar {
  color: #060b26;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
}

.activenav {
  border-bottom: 2px solid #ff344d;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.titles {
  /* color: #fff; */
  margin-left: 10px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #c4b5c0;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  /* background-color: #060b26; */
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

a.buttonwp {
  color: black;
}

.nav-link {
  display: flex !important;
  align-items: center;
}

.lotteryEngine {
  font-size: 17px;
  line-height: 1;
  display: inline;
  white-space: wrap !important;
  font-weight: 500;
}

.hovernav {
  white-space: nowrap !important;
}

.Logo {
  display: flex !important;
}

.accNAdd {
  white-space: nowrap !important;
}

.navbar .dropdown .btn-primary {
  background: transparent;
  width: unset;
  border: 0px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0px;
}

.navbar .dropdown .btn-primary:hover {
  background: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.7);
}

.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown-toggle:focus,
.navbar .btn-primary:focus {
  box-shadow: none !important;
}

.navbar svg {
  fill: none;
  stroke-width: 2px;
  width: 18px;
  height: 100%;
}

.navbar .dropdown-menu-right {
  border-radius: 10px;
  border-top-right-radius: 0px;
  box-shadow: 0 12px 32px rgba(26, 26, 26, 0.2);
  border: 0px;
}

.navbar .lotteryDp .dropdown-menu {
  padding: 0px;
}

.navbar .lotteryDp .dropdown-item.active {
  background-color: #eef3ff;
  color: #2c5de5;
}

.navbar .lotteryDp .dropdown-item:hover {
  background-color: #eef3ff;
  color: #2c5de5;
}

.navbar .lotteryDp .menu-option {
  position: relative;
}

.navbar .lotteryDp .menu-option .sub-menu-list {
  position: absolute;
  top: 0px;
  left: 173px;
}

.navbar .showMore {
  text-align: center;
  color: #ea2e49;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .navbar .lotteryDp {
    width: 100%;
  }

  .navbar .lotteryDp .dropdown-menu {
    margin-top: 6px !important;
    border: none;
    background-color: transparent;
  }

  .navbar .lotteryDp .btn-primary {
    height: 22px;
  }
}

@media screen and (max-width: 1048px) {
  .AccountAdress {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .nav-link {
    display: block !important;
    align-items: center;
  }

  .Logo {
    display: flex !important;
  }
}

@media screen and (max-width: 576px) {
  .nav-link {
    display: block !important;
    margin-bottom: 15px !important;
    align-items: center;
  }

  .navbar {
    margin-top: 0px;
  }

  .Logo {
    display: flex !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 1400px) {
  .lotteryEngine {
    display: block;
  }
}

@media screen and (max-width: 630px) {
  .lotteryEngine {
    display: block;
    font-size: 10px;
    white-space: wrap !important;
  }

  .mybtn1 {
    width: 90px;
    padding: 5px 12px !important;
  }
}

@media screen and (max-width: 454px) {
  .lotteryEngine {
    width: 170px;
    margin-top: -4px;
  }

  .autobetLg {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 400px) {
  .lotteryEngine {
    width: 130px;
  }
}

@media screen and (max-width: 1199px) {
  .accNAdd {
    display: none !important;
  }
}

span {
  margin-left: 16px;
}

.MineLottery .partnerlogo{
height: 35px;
max-width: 150px;
position: absolute;
top: 10px;
right: 10px;
}

.MineLottery .card-inner{
    position: relative;
    background-color: #d1d1d1;
    box-shadow: 0 0 16px 1px#777777;
    border: 0px;
}

.MineLottery .number-list li{
    border-radius: 50%;
    background-color: #a3a3a3;
    color: #383a37;
    border: 0px;
}

.MineLottery .number-list li.active {
    color: #FFFFFF;
    background-color: #d8020b;
    box-shadow: 0 2px 5px 0 #b0010f
}

.MineLottery .text-title{
    color: #383a37;
}

.MineLottery .number-list li.disabled{
    color: #FFFFFF;
    background-color: #f85f53;
    box-shadow: 0 2px 5px 0 #b0010f;
    cursor: not-allowed;
    opacity: 0.65;
}

.MineLottery  .btn-primary:disabled{
    cursor: not-allowed !important;
}

.MineLottery .mine-numbers{
    font-size: 14px;
    margin: 0px;
    color: #383a37;
    line-height: 32px;
}

.MineLottery .number-list li.active .mine-numbers, .MineLottery .number-list li.disabled .mine-numbers{
    color: #FFFFFF;
}

@media screen and (max-width:440px) {
    .MineLottery .partnerlogo{
        position: unset;
        margin-bottom: 8px;
    } 
}
.Missile .no-to-select {
    margin-bottom: 24.35px;
}

.Missile .button-row button {
    width: 47.14px;
    height: 47.14px;
    border-radius: 50%;
    border: 0px;
    background: #F0F0F0;
    margin: 13.65px;
    font-weight: 500;
    font-size: 20.7413px;
    line-height: 24px;
    color: #000000;
}

.Missile .button-row button:disabled{
    cursor: not-allowed;
}

.Missile .button-row {
    display: flex;
    justify-content: center;
}

.Missile .no-to-select .delete,
.Missile .no-to-select .enter {
    width: 72px;
    height: 41px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.Missile .no-to-select .delete {
    background: #C11902;
}

.Missile .no-to-select .enter {
    background: #34C102;
}

.Missile .partners-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.Missile .partnerlogoMissile {
    width: auto;
    height: 60px;
    object-fit: contain;
    max-width: 120px;
}

.Missile .card {
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
    padding: 26px 19px 40px;
    max-width: 530px;
    margin: auto;
    position: relative;
    z-index: 2;
}

.Missile .card-body {
    padding: 0px;
}

.Missile .card-body .amount-section {
    margin-bottom: 14px;
}

.Missile .card-body .card-header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #444444;
}

.Missile .date-section span {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #696969;
}

.Missile .btn.disabled,
.Missile .btn:disabled {
    cursor: not-allowed !important;
}


.Missile .rocket-type-name {
    text-align: left;
}

.Missile .rocket-type-name span {
    font-weight: 500;
    font-size: 20px;
    color: #dc3545;
    text-transform: uppercase;
}

.Missile .missile-icon {
    width: 200px;
    margin-bottom: 20px;
}

.Missile .outer-container {
    padding: 67px 0px;
    position: relative;
    min-height: 100%;
    overflow: hidden;
    margin-top: 8px;
}

.Missile .bgcolor {
    background: linear-gradient(90deg, #8D140E 0%, #56248D 78.29%);
}

.Missile .bgImage1 {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.Missile .logo-section {
    width: 120px;
}

.Missile .no-to-select .inputField {
    width: 196px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: 0px auto 11.36px;
}

.Missile .draw-date {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #433A3A;
    margin-bottom: 15px;
}

.Missile .dateformobile {
    margin-bottom: 15px;
}

.Missile .missile-img {
    height: 100%;
}

.Missile .missile-img .hypersonic {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    top: 85px;
    z-index: 2;
    height: 100%;
    max-height: 750px;
}

.Missile .missile-img .ballastic {
    position: absolute;
    width: calc(100% - 30%);
    right: 0px;
    bottom: 0px;
    z-index: 1;
    height: 100%;
    max-height: 680px;
}

.Missile .missile-img .cruis {
    position: absolute;
    width: calc(100% - 30%);
    right: 0px;
    bottom: 0px;
    z-index: 1;
    height: 100%;
    max-height: 550px;
}

.Missile .missile-img .initiation {
    position: absolute;
    width: calc(100% - 50%);
    right: 0px;
    bottom: 67px;
    z-index: 1;
    height: 100%;
    max-height: 467px;
}

.Missile .missile-row {
    position: relative;
}

.Missile .missile-type span {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #444444;
}

.Missile .missile-type1 span {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.Missile .missile-col {
    position: unset;
}

.Missile .dp-class {
    z-index: 3;
    text-align: right;
}

.Missile .error-textt {
    color: red;
    font-size: 14px;
    margin-top: 4px;
}

.Missile .error-texttt {
    color: #696969;
    font-size: 14px;
    margin-top: 4px;
}

@media screen and (max-width: 1200px) {
    .Missile .outer-container {
        margin-top: 32px;
    }
}

@media screen and (max-width:991px) {
    .Missile .missile-img .hypersonic {
        position: unset;
    }

    .Missile .missile-img .ballastic,
    .Missile .missile-img .initiation,
    .Missile .missile-img .cruis {
        width: 100%;
        position: unset;
    }

    .Missile .missile-type,
    .Missile .missile-type1 {
        max-width: 530px;
        margin: 0px auto;
    }

    .Missile .card {
        border-top: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

    }
}

@media screen and (max-width: 576px) {
    .Missile .outer-container {
        margin-top: 18px;
    }
}

@media screen and (max-width: 440px) {
    .Missile .card-body .card-header-text {
        font-size: 32px;
        line-height: 38px;
    }

    .Missile .button-row button {
        width: 40px;
        height: 40px;
        font-size: 18px;
        margin: 10px;
    }

    .Missile .no-to-select .delete,
    .Missile .no-to-select .enter {
        font-size: 18px;
    }

    .Missile .no-to-select {
        margin-bottom: 15px;
    }
}
#helpScreen .videotitle{
    /* text-align: center; */
    font-size: 20px ;
    /* margin-bottom: 20px; */
}
#helpScreen .videoimg{
    height: 200px;
    width: 400px;
    background-color: rgb(221, 221, 221);
}
#helpScreen .dropWrap{
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 8px;
}
#helpScreen .dropDownSvg{
    transform: rotateX(180deg);
    transition: 0.5s;
}
#helpScreen .rotateXTo0{
    transform: rotateX(0deg);
}
#helpScreen .detailStyle{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
#helpScreen .pointer{
    cursor: pointer;
}
#helpScreen .summaryStyle{
    /* margin-left: 5px; */
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 18px;
    white-space: pre-line;
}
#helpScreen .summaryWrap{
    max-height:0;
    overflow: hidden;
    transition:max-height 0.5s ease;
}
#helpScreen .showSummary{
    height:auto;
    max-height:100%;
}
@media only screen and (max-width : 432px){
    #helpScreen .videoimg{
        height: 170px;
        width: 300px;
    }
    #helpScreen .title{
        font-size: 30px;
        margin-bottom: 20px !important;
    }
}
@media only screen and (max-width : 992px){
    #helpScreen .videoimg{
        height: 180px;
        width: 350px;
    }
}
#aboutscreen .cardWrapper{
    padding: 35px 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgb(216, 216, 216);
    margin: 20px 0px;
    min-height: 280px;
    transition: .5s;
    /* margin-bottom: 40px; */
}
#aboutscreen .cardwrappercolumn{
    margin-top: 20px;
}
#aboutscreen .cardWrapper:hover{
    transform: scale(101%);
    box-shadow: 0px 4px 10px rgb(216, 216, 216);
}

.PartnersList .add-btn-section {
    text-align: right;
    margin-bottom: 30px;
}

.PartnersList .card-col {
    margin-bottom: 30px;
}

.PartnersList .list-card {
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    background-color: #ffffff;
    border-radius: 10px;
    height: 100%;
    border: 0px;
}

.PartnersList .list-card .card-img-block {
    overflow: hidden;
    text-align: center;
    padding: 15px 10px 0px;
}

.PartnersList .card-img-block .img-fluid {
    height: 100px;
    object-fit: contain;
    width: 100%;
    max-width: 220px;
}

.PartnersList .card-body {
    padding: 16px;
    text-align: left;
}

.PartnersList .card-body .action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.PartnersList .card-body .action .cardIcon1 {
    width: 19.5px;
    height: 20px;
    cursor: pointer;
}

.PartnersList .card-body .action .cardIcon2 {
    height: 20px;
    cursor: pointer;
}

.brandLogo .previewImage {
    height: 100px;
}

.brandLogo input[type='file'] {
    color: transparent;
    width: 140px;
    margin-bottom: 15px;
}

.brandLogo {
    display: flex;
    flex-wrap: wrap;
}

.brandLogo label {
    width: 100%;
}

.popupHeading {
    font-weight: 500;
    font-size: 20px;
    color: rgb(68, 68, 68)
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.custom-control-label::before,
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #ddd !important;
}

.brand-logo h5 {
    font-size: 16px;
}

.brand-logo h4 {
    font-size: 16px;
}

.brand-logo .img-thumbnail {
    margin-top: 5px;
    width: 200px !important;
    height: 200px !important;
}

.brand-logo .logo-label {
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--themebold);
}

.cross-icon{
    cursor: pointer;
}

.dateInput .react-datetime-picker{
    width: 100%;
}

.partner-pop{
    margin: 0px -16px -14px;
}

@media screen and (max-width:700px)  {
    .PartnersList .card-section{
        margin-left: 0px !important;
    }  
}

.BuyGiftCard .gitfcard-img {
    height: 220px;
    width: 100%;
    object-fit: contain;

}

.BuyGiftCard .button-section {
    text-align: center;
    margin-top: 8px;
}

.BuyGiftCard .card-details{
    height: 100%;
}

.BuyGiftCard .desc-title{
    font-weight: 600;
    margin-bottom: 4px;
}

.BuyGiftCard .description{
    font-size: 16px;
}

@media screen and (max-width: 991px) {
    .BuyGiftCard .image-section {
        margin-bottom: 0px !important;
        padding: 0px !important;
        margin-bottom: 4px;
    }

    .BuyGiftCard .card-details {
        margin-top: 0px !important;
    }
}


@media screen and (max-width:440px) {
    .BuyGiftCard .partnerlogo {
        position: unset;
        margin-bottom: 8px;
    }
}
.list-giftcard{
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    background-color: #ffffff;
    border-radius: 10px;
    height: 100%;
    border: 0px;
}

.list-giftcard .card-img-block {
    overflow: hidden;
    text-align: center;
    padding: 15px 10px 0px;
}

.list-giftcard .card-img-block .img-fluid {
    height: 100px;
    object-fit: contain;
    width: 100%;
    max-width: 220px;
}

.list-giftcard .card-body {
    padding: 16px;
    text-align: left;
}

.list-giftcard .shareicon{
    width: 19.5px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
}

.list-giftcard .card-desc{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.list-giftcard .giftcard-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 25px;
    background: #ee4545;
    border-radius: 0.25rem 0px;
    text-align: center;
}

.list-giftcard .giftcard-tag span{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
}

.list-giftcard .transfer-button {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}
.NewsList .add-btn-section {
    text-align: right;
    margin-bottom: 30px;
}

.NewsList .card-col {
    margin-bottom: 30px;
}

.NewsList .table thead tr {
    background-color: #c9c9c9;
}

.NewsList .table thead th:first-child {
    border-top-left-radius: 5px;
    overflow: hidden;
}

.NewsList .table thead th:last-child {
    border-top-right-radius: 5px;
    overflow: hidden;
}

.NewsList .table tbody tr:nth-child(even) {
    background-color: #edeef0;
}

.NewsList .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
    overflow: hidden;
}

.NewsList .table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.NewsList .table tbody tr .text-ellipse{
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.NewsList .table thead .table-id {
    width: 50px;
}

.NewsList .table thead .action-btn {
    width: 80px;
}

.NewsList .table .action-icon {
    cursor: pointer;
}

.NewsList .table th {
    padding: 12px;
}

.NewsList .table .custom-switch .custom-control-label{
    cursor: pointer;
}

.rtf-wrapper{
    border: 1px solid #ced4da;
    border-radius: 12px;
}

.rtf-wrapper .rdw-editor-toolbar{
    border: none;
    border-bottom: 1px solid #ced4da;
    padding: 0px;
    margin: 6px 5px;
}

.rtf-wrapper .rtf-editor{
    padding: 0px 0.75rem;
}

.brandLogo .previewImage {
    height: 100px;
}

.brandLogo {
    display: flex;
    flex-wrap: wrap;
}

.brandLogo label {
    width: 100%;
}

.popupHeading {
    font-weight: 500;
    font-size: 20px;
    color: rgb(68, 68, 68)
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.custom-control-label::before,
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #ddd !important;
}

.brand-logo h5 {
    font-size: 16px;
}

.brand-logo h4 {
    font-size: 16px;
}

.brand-logo .img-thumbnail {
    margin-top: 5px;
    width: 200px !important;
    height: 200px !important;
}

.brand-logo .logo-label {
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--themebold);
}

.cross-icon {
    cursor: pointer;
}

.dateInput .react-datetime-picker {
    width: 100%;
}

.partner-pop {
    margin: 0px -16px -14px;
}

#news .DraftEditor-editorContainer .rdw-link-decorator-wrapper a:hover{
    color: #007bff;
    text-decoration: none;
}

#news .DraftEditor-editorContainer .rdw-link-decorator-wrapper .rdw-link-decorator-icon {
    display: none;
}

#news .DraftEditor-editorContainer .rdw-link-decorator-wrapper a span{
    color: #007bff;
}

.newsPopup .modal-dialog{
 max-width: 650px;
}

@media screen and (max-width:700px) {
    .NewsList .card-section {
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 667px) {
    .newsPopup .modal-dialog{
        margin: 28px 8px;
       } 
}
#notifications{
    min-height: calc(100vh - 105px);
}

#notifications .notification-section{
    padding: 20px;
}

#notifications .notification-section .notification_heading .heading {
    font-size: 20px;
    font-weight: 600;
}

#notifications .notification-section .notification_heading .see_all{
    cursor: pointer;
    color: rgb(79, 128, 225);;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

#notifications .notification-section .notification_list{
    cursor: pointer;
    border-radius: 10px;
    background: #edeef0;
    padding:  10px 20px;
    display: flex;
    align-items: center;
}

#notifications .notification-section .notification_list .icon-section{
    margin-right: 12px;
}

#notifications .notification-section .notification_list .icon-section .notify_icon{
    height: 20px;
    width: 20px;
}

#notifications .icon-section svg{
    stroke-width: 0px;
}

#notifications .notification-section .notification_list .details-section{
   width: calc(100% - 32px);
}

#notifications .notification-section .notification_list .notification_title{
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

#notifications .notification-section .notification_list .notification_content{
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#notifications .notification-section .notification_list .notification_time {
    font-size: 12px;
    line-height: 18px;
}

#notifications .react-datepicker-wrapper input{
    height: 42px;
    border-radius: 10px;
    padding: 10px;
    width: 120px;
    margin-right: 20px;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.5);  
}

#notifications .react-datepicker__close-icon {
    right: 20px
}

#notifications .date-range-input{
    width: 205px;
    height: 45px;
    border-radius: 10px;
    margin-right: 20px;
}

#notifications .date-range-input:-ms-input-placeholder {
    text-align: center;
}

#notifications .date-range-input::placeholder {
    text-align: center;
}

#notifications .filter-section{
    display: flex;
    justify-content: end;
}

.daterangepicker{
    margin-right: 30px;
}

.daterangepicker .applyBtn{
    width: unset;
    height: unset;
    border-radius: 10px;
}

@media screen and (max-width: 563px) {
    .daterangepicker{
        margin-right: unset;
    }
}

@media  screen and (max-width: 450px) {
    #notifications .filter-section{
        flex-wrap: wrap;
        justify-content: center;
    }

    #notifications .date-range-input{
        width: 100%;
        margin-bottom: 12px;
    }
}
#news{
    min-height: calc(100vh - 105px);
}

#news .news-section{
    padding-top: 20px;
}

#news .news-section .news_list{
    border-radius: 10px;
    background-color: white;
    padding: 16px;
    /* border: 2px solid #b74848; */
    box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
}

#news .news-section .news_list .news_title{
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    word-break: break-word;
}

#news .news-section .news_list .title_width{
    width: calc(100% - 25px)
}

#news .news-section .news_list .news_content{
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#news .news_time {
    font-size: 14px;
    line-height: 18px;
}

#news .news-section .news_list .details-section{
    position: relative;
}

#news .news-section .news_list .share-icon{
    position: absolute;
    right: 0px;
    top: 0px;
    height: 20px;
    z-index: 2;
    cursor: pointer;
}

#news .news-section .news_list .newsImage .newsImg{
    max-height: 350px;
    width: 100%;
    object-fit: contain;
}

#terms-container{
    padding: 0 15px;
}

@media screen and (max-width: 576px) {
    #terms-container .terms{
        margin-left: 15px;
        margin-right: 15px;
    }
}
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;1,500;1,600;1,700&display=swap'); */

:root {
  /* --dark: #002a33; */
--themedark: #fc9d2e;
--themelight:#fdd72a;
--themebold:#002a33;
--themeboldlight:#4d4e4e;
--blue:blue;
--overlay:#e13e3e85;
--white:#fff;
--black:#000;
--textopacity:0.85;
--textfaintopacity:0.75;
--green:#4CAF50;
--fav-unclick: transparent;
--fav-click:red;
--overlay1:#fc9d2eb0;
--shadow:.07843;
--normaltext_title: rgb(64 59 59);
--bborder:#ced4da;

--bggraylight:#edf2f7;

  --dark: #002a33;
  --bg:#fc9d2e ;
  /* --light: #f2f2f2; */
  --light: #feffff;
  --lightGrey: rgb(130, 134, 138);
  --accent: #fc9d2e;
  --cursive: 'Dancing Script', cursive;
  /* --font: 'Kumbh Sans', sans-serif; */
  --font: 'Poppins', sans-serif;
  --circle:linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%)
}

.Logo{
  font-size: 20px;
  font-weight: bold;
  line-height: 34px;
 }

 .Logoorg{
   padding-left: 15px;
   padding-right: 15px;
   vertical-align: middle;
   /* color: #ffffff; */
  font-size: 20px;
  font-weight: bold;
  line-height: 34px;
 }


 /* .Logoorg:hover{
 font-weight: 500;
 text-decoration: none;
} */
.textstyle{
  text-decoration: none;
}

@media (max-width: 991px){
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: 10px;
}
.lottery-section2 .wheelarea{
  margin-bottom: 30px;
}
}


* {

  font-family: 'Roboto', sans-serif;
letter-spacing: 0.1px;
}

body{
  color:#002a33 !important ;
  color:var(--themebold) !important ;
  background-color: transparent !important;
}


.register{
  margin: auto;
  
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  /* display: inline-block; */
  align-self: center;
  display: block;
    align-items: center;
    justify-content: center;
  
}
.text-btn {
  font-weight: 600;
  text-transform: capitalize;
  color: rgba(51, 55, 69, 0.8);
  text-decoration: underline;
  margin: auto;
  margin-top: 30px;
  cursor: pointer;
}

#homePage .text-btn{
  font-weight: 500;
}
 
.lottery-section{
  margin-top: 42px;
  /* padding-bottom: 55px; */
}
.lottery-section2{
  
  padding-bottom: 30px;
}

#homePage .lottery-section2{
  padding-bottom: 8px;
}

#homePage .gift-card .lottery-card{
  margin-bottom: 20px;
}

.lottery-card{
   
    padding: 30px 30px 10px 30px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid #b74848;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    margin-bottom:40px;
}
.lottery-card a {
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
}
.amount {
  font-size: 28px;
  font-weight: 700;
  color: #ea2e49;
  margin-top: 15px;
}
.lottery-card:hover {
  box-shadow: 1.5px 2.598px 17.22px 3.78px rgba(241 0 38 / 36%), -1.678px 2.487px 17.22px 3.78px rgba(51, 181, 247, 0.21);
}
.navbar-nav .nav-link:hover {
  color: #ff1c40;
  
  /* font-weight: 500; */
}
   
.section-bg {
  background-color: #edf6fb;
}

.section-bgrd {
  background-color: #dedede;
}


.section-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}




.play {
  padding: 30px 35px;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  background: linear-gradient(to top left, #3366ff 0%, #ff3300 100%);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50px 0px 50px 0px;
  -webkit-border-radius: 50px 0px 50px 0px;
  -moz-border-radius: 50px 0px 50px 0px;
  -ms-border-radius: 50px 0px 50px 0px;
  -o-border-radius: 50px 0px 50px 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.play:hover {
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.2);
  
}
.overview-item {
  padding: 30px 35px;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.overview-item:hover {
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.2);
}
.overview-item .content .title {
  font-size: 28px;
  font-weight: 500;
  margin-top: 30px;
}
.overview-item .content p {
  margin-top: 20px;
}
.overview-item .content a {
  color: #333745;
  text-decoration: underline;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-top: 20px;
}
p, span, li {
  margin: 0;
   
  color: #333745cc;
  line-height: 1.7;
  font-size: 18px;
}
.customcardbody{
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cmn-btn {
  font-size: 20px;
  text-transform: capitalize;
  background-image: linear-gradient(to left, #ff344d, #d2273c); 
  text-align: center;
  color: #ffffff;
  padding: 10px 26px;
  font-weight: 400;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  font-size: 16px;
  cursor: pointer !important;
  text-transform: uppercase;
}
.cmn-btn:hover {
  cursor: pointer !important;
  background-image: linear-gradient(to right, #ff344d, #d2273c);
  color: #fff;
  box-shadow: 1.5px 2.598px 17.22px 3.78px rgba(241 0 38 / 36%), -1.678px 2.487px 17.22px 3.78px rgba(51, 181, 247, 0.21);

    }

.jackpot-item a {
    transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
}
.highlightb {
  padding: 10px 7px;
  background: rgb(9, 236, 130);
  text-align: center;
  border-radius: 10px;
  margin-bottom: 0px;
}
.box {
  border: 2px solid #000;
  border-radius: 20px;
}
.numbercircleoutline{
  margin: 10px;
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 45px;
  
  padding-top: 9px;
}
.numbercirclecolor {
  margin: 10px;
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 45px;
  background: #ff952f;
  padding-top: 9px;
}
.bgImage{
  /* background-image: url('../Images/ball.jpg'); */
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 93vh  ; 
  justify-content: center;
}
.bold-focused{
  font-weight: 600;
    text-transform: capitalize;
    color: rgba(51, 55, 69, 0.8);
    text-decoration: underline;
    margin: auto;
    margin-top: -85px;
    cursor: pointer;
    font-size: x-large;
}
.register-section{
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
}
.inactivebtn {
  background-color: rgb(113 12 0) !important;
  border-color: transparent !important; 
}
.activebtn {
  background-color: rgb(113 12 0) !important;
  border-color: transparent !important;
}
.activebtn:focus{
  background-color: rgb(113 12 0) !important;
  border-color: transparent !important;
  transform: scale(1.1);
  box-shadow:0 0 0 0 rgba(0,123,255,.25) !important;
}
.inactivebtn:hover {
  background-color: rgb(113 12 0) !important;
  border-color: transparent !important;
}
/* question-section css start */
.question-section .content p {
  margin-top: 15px;
  margin-bottom:30px;
}
.question-section .content .cmn-btn {
  margin-top: 20px;
}
/* question-section css end */
th{
  color:'black';
}

.allLottery{
  min-height: 100vh;
}

.createLottery_container{
  min-height: 70vh;
}

.lottery-container .text-btn {
margin-top: 0px;
}

.lottery-container .lottery-section2 .wheelarea {
  margin-bottom: 20px;
}

.revolver-col .wheelarea{
  margin-bottom: 30px;
}

.lottery-card-content{
  position: relative;
}

.lottery-card-content .sharaicon{
  position: absolute;
  right: 0px;
  height: 20px;
  z-index: 2;
  cursor: pointer;
}

.lotterymsgtext{
  font-size: 14px;
}

.gift-date{
  width: 100%;
}
