.counter-wrapper {
  padding-left: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
.counter-wrapper .counter-content .text {
  margin-top: 35px;
}
.counter-wrapper .single-counter {
  max-width: 155px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  z-index: 5;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 100px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 155px;
    height: 120px;
  }
}
.counter-wrapper .single-counter::before {
  position: absolute;
  content: "";
  width: 155px;
  height: 120px;
  border-radius: 60px;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: -1;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 100px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 155px;
    height: 120px;
  }
}
.counter-wrapper .single-counter.counter-color-1::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #7419db -13.4%,
    rgba(116, 25, 219, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-2::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #5c3ca9 -13.4%,
    rgba(92, 60, 169, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-3::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #a74ac7 -13.4%,
    rgba(167, 74, 199, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-4::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    179.93deg,
    #4f80e1 -13.4%,
    rgba(79, 128, 225, 0.51) 113.4%
  );
}
.counter-wrapper .single-counter.counter-color-5::before {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  background: linear-gradient(
    184.42deg,
    #f81000 -8.19%,
    rgba(255, 0, 0, 0.51) 108.19%
  );
}
.counter-wrapper .single-counter .count {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 22px;
  }
}
.counter-wrapper .single-counter .text {
  font-size: 16px;
  color: #fff;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .banner-section .counter-wrapper .single-counter .text {
    font-size: 12px;
    margin-left: 10px;
  }

  .banner-section .counter-wrapper .single-counter .count {
    font-size: 16px;
    margin-left: 10px;
  }
}
