.BuyGiftCard .gitfcard-img {
    height: 220px;
    width: 100%;
    object-fit: contain;

}

.BuyGiftCard .button-section {
    text-align: center;
    margin-top: 8px;
}

.BuyGiftCard .card-details{
    height: 100%;
}

.BuyGiftCard .desc-title{
    font-weight: 600;
    margin-bottom: 4px;
}

.BuyGiftCard .description{
    font-size: 16px;
}

@media screen and (max-width: 991px) {
    .BuyGiftCard .image-section {
        margin-bottom: 0px !important;
        padding: 0px !important;
        margin-bottom: 4px;
    }

    .BuyGiftCard .card-details {
        margin-top: 0px !important;
    }
}


@media screen and (max-width:440px) {
    .BuyGiftCard .partnerlogo {
        position: unset;
        margin-bottom: 8px;
    }
}